import React, { useState, useEffect, useMemo } from "react";
import { PlatformApi, topLoaderProgress } from "../../configs";
import useSWR from "swr";
import { ApiResponse, WithPagination } from "../../models";
import { AxiosAuth } from "../../core";
import { ApiSchemaCallCenterActivityData } from "../../models/api/CallCenterActivity";
import { ApiSchemaFilter } from "../../models/api/filter";

type Props = {
  callCenterId: string;
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
};

let pagedCallCenterActivityUrl: string;


export function useFunctionalityCallCenterActivity(props: Props) {
  const {
    callCenterId,
    pageSize,
    pageNo,
    searchText,
    setLoaderProgress,
    filterData,
    filterDataChange,
  } = props;

  const [activityTableData, setActivityTableData] =
    useState<ApiSchemaCallCenterActivityData[]>();

  const pagedCallCenterActivityUrl = useMemo(() => {
    return PlatformApi.CallCenter.Activity(
      callCenterId,
      pageSize,
      pageNo,
      searchText,
      filterDataChange
    );
  }, [callCenterId, pageSize, pageNo, searchText, filterDataChange]);

  const { data: apiCallCenterActivityList, error } = useSWR<{
    data: ApiSchemaCallCenterActivityData[];
    count: number;
  }>(
    callCenterId ? pagedCallCenterActivityUrl : null,
    async () => {
      setLoaderProgress(topLoaderProgress.start);
      try {
        const response = await AxiosAuth.post< 
          ApiResponse<WithPagination<ApiSchemaCallCenterActivityData[]>>
        >(pagedCallCenterActivityUrl, filterData);
        return {
          data: response.data.data.data,
          count: response.data.data.count,
        };
      } finally {
        setLoaderProgress(topLoaderProgress.complete);
      }
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 10000, // Automatically refresh every 10 seconds
    }
  );

  // Update activity table data whenever new data is fetched
  useEffect(() => {
    if (apiCallCenterActivityList?.data) {
      setActivityTableData(apiCallCenterActivityList.data);
    }
  }, [apiCallCenterActivityList]);

  // Log and notify in case of an error
  if (error) {
    console.error(error);
  }

  return {
    pagedActivityList: apiCallCenterActivityList?.data ?? activityTableData,
    pagedActivityListCount: apiCallCenterActivityList?.count ?? 0,
    pagedCallCenterActivityUrl,
  };
}


