import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { shallowEqual } from "react-redux";
import { Link } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { AccountType, AppRouteUi, InvoiceType, PaginationSettings, } from "../../configs";
import { useAppSelector, VRIAppStateType} from "../../core";
import { AdminPageTemplate, ColumnToolTip, Searchbar, } from "../../features";
import { InvoiceFilter } from "../../features/Common/Components/CommonAccountInvoice/invoiceFilter";
import { AccountResponseModel } from "../../models";
import { ApiSchemaFilter } from "../../models/api/filter";
import { handleNumberToTwoDecimalPoint } from "../../utils";
import { useFunctionalityPagedAccount } from "../hooks/functionalityAccount";

export function PageOperatorFinance(): JSX.Element {
  const [modalShow, setModalShow] = React.useState(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      companyId: state.auth.profile?.companyId,
    }),
    shallowEqual
  );

  const [searchText, setSearchText] = useState("");
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);

  let testuser: ApiSchemaFilter = {
    filterQueries: [],
  };
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [isPageFilterData, setIsPageFilterData] = useState(false);
  const [filterData, setFilterData] = useState<ApiSchemaFilter>(testuser);
  const [filterDataChange, setFilterDataChange] = useState<number>(0);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  if ((filterData && filterDataChange) || searchText) {
    if (!isPageFilterData) setIsPageFilterData(true);
  }
  const { pagedAccountList, pagedAccountListCount } =
    useFunctionalityPagedAccount({
      parentCompanyId: data.companyId ?? "",
      accountType: AccountType.Operator,
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
      filterData: filterData,
      filterDataChange: filterDataChange,
      isPageFilterData: isPageFilterData,
      isPageDataLoad: true,
    });
  useEffect(() => {
    if (pagedAccountList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedAccountList]);

  const TableColumns = [
    {
      name: <ColumnToolTip data="NAME" />,
      selector: (row: AccountResponseModel) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: <ColumnToolTip data="BALANCE" />,
      selector: (row: AccountResponseModel) => {
        return <ColumnToolTip data={handleNumberToTwoDecimalPoint(row.balance)} />;
      },
      conditionalCellStyles: [
        {
          when: (row: AccountResponseModel) => row.balance < 0,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      selector: (row: AccountResponseModel) => {
        return (
          <>
            <Link
              to={AppRouteUi.OperatorFinance.Profile.Load(row.userId ?? "")}
              className="btn svg-color"
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className="my-3 font-weight-light"></h4>
      <div className="row">
        <div className="col-12 d-flex">
          <div className="w-100">
            <Searchbar
              showFilter={true}
              filterPanel={"jobFilterPanel"}
              searchText={searchText}
              handleSearch={handleSearch}
              setIsShowCommonFilter={setIsShowCommonFilter}
            />
          </div>
        </div>
        {isShowCommonFilter && (
          <InvoiceFilter
            setFilterData={setFilterData}
            setLoaderProgress={setLoaderProgress}
            setFilterDataChange={setFilterDataChange}
            invoiceType={InvoiceType.Operator}
            pageName="operatorFinance"
            setPageIndex={setPageIndex}
          />
        )}
      </div>
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Operator"
        tableHeaders={TableColumns}
        tableData={pagedAccountList || []}
        setCreateModalShow={setModalShow}
        selectableRows={true}
        searchText={searchText}
        hideSearchBar={true}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedAccountListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
      />
    </>
  );
}
