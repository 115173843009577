import React, { useEffect, useState } from "react";
import { ChannelContext, useAppSelector, VRIAppStateType } from "../../../../core";
import { ApiResponseConversation, ApiResponseParticipant } from "../../../../models";
import { useFunctionalityChat } from "../../../../pages/hooks";
import { ConversationComponent } from "./CoversationComponent";
import { GroupChatDetail } from "./chatGroupDetail";
import { ProfileComponent } from "./profileComponent";
import {Participant as TwilioParticipant} from 'twilio-video';


type Props = {
  chatUiOpen?: boolean
  convoDetail?: ApiResponseConversation,
  arrowclickHandler?: boolean,
  participant: TwilioParticipant | undefined;
}
export function GuestChatUI(props: Props): JSX.Element {
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false)
  const [addParticipant, setAddParticipant] = useState<boolean>(false)
  const [selectGroupDetail, setSelectGroupDetail] = useState<boolean>(false)
  const [groupParticipantList, selectGroupParticipantList] = useState<ApiResponseParticipant[]>([])

  const { selectedChannel, isBusy } = React.useContext(ChannelContext);
  const { onFetchGroupDetailsGuest } = useFunctionalityChat();

//   const data = useAppSelector((state: VRIAppStateType) => ({

//     chatSid: state.ui.chatSid
//   }));


  useEffect(() => {
    console.log("selectedChannel: ", selectedChannel);
  }, [selectedChannel]);

  useEffect(() => {
    onFetchGroupDetailsGuest(selectedChannel?.sid, selectGroupParticipantList)
  }, [selectedChannel, addParticipant, deleteModalShow]);


  return (
    <>
    
        <ProfileComponent selectGroupDetail={selectGroupDetail} setSelectGroupDetail={setSelectGroupDetail} arrowclickHandler={props?.arrowclickHandler?? false} />
        {!selectGroupDetail ? <ConversationComponent groupParticipantList={groupParticipantList} participant={props.participant} /> : <GroupChatDetail deleteModalShow={deleteModalShow} setDeleteModalShow={setDeleteModalShow} addParticipant={addParticipant} setAddParticipant={setAddParticipant} groupParticipantList={groupParticipantList} selectGroupParticipantList={selectGroupParticipantList} />
        }
     

    </>
  );
}

