import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallType } from "../../../configs";
import { EnumAppModal } from "../../../models";

type TModalTypeDescriptor<T extends EnumAppModal, D> = { type: T; data?: D };

type TModalData = TModalTypeDescriptor<EnumAppModal.Register, void>;

export type TSliceUi = {
  modal?: TModalData;
  receiverName: string;
  callerId: string;
  roomId: string;
  audioDeviceConnection?: any;
  previousRoute?: string;
  receiverId: string;
  languageName:string;
  callerDeviceId:string;
  receiverDeviceId:string;
  callType:CallType;
  isForceFullylogedout:boolean,
  fileTimeStamp:any,
  chatSid?:string,
  cameraHeight?: number,
  cameraWidth?: number,
  guid?:string
};

function InitSliceUi(): TSliceUi {
  return {
    receiverName: "",
    callerId: "",
    roomId: "",
    receiverId: "",
    languageName:"",
    callerDeviceId:"",
    callType:0,
    receiverDeviceId:"",
    isForceFullylogedout:false,
    fileTimeStamp:Date.now(),
    chatSid:"",
    cameraHeight:720,
    cameraWidth:1280,
    guid: "",
  };
}

const sliceUi = createSlice({
  name: "sliceUi",
  initialState: InitSliceUi(),
  reducers: {
    ModalOpen: (
      state: TSliceUi,
      action: PayloadAction<TModalData>
    ): TSliceUi => ({
      ...state,
      modal: action.payload,
    }),
    ModalClose: (state: TSliceUi): TSliceUi => ({
      ...state,
      modal: undefined,
    }),
    SetReceiverName: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      receiverName: action.payload,
    }),
    SetCallerId: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      callerId: action.payload,
    }),
    SetCallerDeviceId: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      callerDeviceId: action.payload,
    }),
    SetReceiverDeviceId: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      receiverDeviceId: action.payload,
    }),
    SetCallType: (state, action: PayloadAction<CallType>): TSliceUi => ({
      ...state,
      callType: action.payload,
    }),
    SetLanguageName: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      languageName: action.payload,
    }),
    SetReceiverId: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      receiverId: action.payload,
    }),
    SetRoomId: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      roomId: action.payload,
    }),
    SetGuid: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      guid: action.payload,
    }),
    SetChatSid: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      chatSid: action.payload,
    }),
    SetFileTimeStamp: (state, action: PayloadAction<any>): TSliceUi => ({
      ...state,
      fileTimeStamp: action.payload,
    }),
    SetIsForcefullyLoggedout: (state, action: PayloadAction<boolean>): TSliceUi => ({
      ...state,
      isForceFullylogedout: action.payload,
    }),
    SetPreviousRoute: (state, action: PayloadAction<string>): TSliceUi => ({
      ...state,
      previousRoute: action.payload,
    }),
    SetCameraheight: (state, action: PayloadAction<number>): TSliceUi => ({
      ...state,
      cameraHeight: action.payload,
    }),
    setCameraWidth: (state, action: PayloadAction<number>): TSliceUi => ({
      ...state,
      cameraWidth: action.payload,
    }),
    SetAudioDeviceConnection: (
      state,
      action: PayloadAction<any>
    ): TSliceUi => ({
      ...state,
      audioDeviceConnection: action.payload,
    }),
    resetData: ()=> {
      return InitSliceUi();
    },
  },
});

export const ReducerUi = sliceUi.reducer;
export const ActionUi = sliceUi.actions;
