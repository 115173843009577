
import useSWR from 'swr';
import { VriApi } from '../../configs/appRoute';
import { useAppSelector, VRIAppStateType } from '../../core/Redux/ReduxStore';
import { AxiosAuth } from '../../core';
import React from 'react';
import { ApiResponse } from '../../models/api/common';
import { useFunctionalityCallLog } from './functionalityCallLog';
import { ApiSchemaConferenceData, ApiSchemaConsumerConference, ApiSchemaFutureConferenceData, ConferenceData, ConsumerConferenceData } from '../../models';


export function useFunctionalityConsumerConference(consumerId: string) {

  const isLoggedIn = useAppSelector((state: VRIAppStateType) => state.auth.isLoggedIn);

  const conferenceUrl = VriApi.Conference.GetConsumers.Root(consumerId);

  const { data: apiConferenceData } = useSWR<ConsumerConferenceData[]>(
    isLoggedIn ? conferenceUrl : null, () =>
    AxiosAuth.get<ApiResponse<ApiSchemaConsumerConference[]>>(conferenceUrl)
      .then(r => r.data.data)
      .catch(e => {
        console.error(e)
        return e;
      })
  )

  const consumerConferenceList = React.useMemo(() => {
    const data = Array.isArray(apiConferenceData) ? apiConferenceData : [];
    return data;
  }, [apiConferenceData]);


  return {
    consumerConferenceList
  }
}