import logo from "./b_logo.png";
import companyImg from "./companyImg.png";
import defaultCompanyImage from "./defaultCompanyImage.png";
import userGeneric from './user-silhouette.png'
import searchAnimation from "./search_operator_01.gif";
import defaulChatImage from "./chatDefault.png";

export const AssetsPng = {
  Logo: logo,
  CompanyImage: companyImg,
  GenericUser: userGeneric,
  SearchAnimation: searchAnimation,
  DefaultCompanyImage: defaultCompanyImage,
  DeFaultChatImage: defaulChatImage,
};
