import React, { useEffect, useState, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { IncomingText, OutgoingText } from '.';
import { ChannelContext, useAppSelector, VRIAppStateType } from '../../../../core';
import {
  ApiResponseConversation,
  ApiResponseParticipant,
} from '../../../../models';
import { logger } from '../../../../utils';
import { LoadingOverlay } from '../../../../components';
// import { VideoGuestContext } from '../../../../core';
import {Participant as TwilioParticipant} from 'twilio-video';


type Props = {
  chatUiOpen?: boolean;
  convoDetail?: ApiResponseConversation;
  groupParticipantList: ApiResponseParticipant[];
  participant?: TwilioParticipant | undefined;
};
export function ConversationComponent(props: Props): JSX.Element {
  const { selectedChannel, isBusy } = React.useContext(ChannelContext);

  const profile = useAppSelector((state: VRIAppStateType) => state.auth.profile);

  const [newMessage, setNewMessage] = useState<any>([]);
  const [messages, setMessages] = useState<any>([]);
  const [boundChannels, setBoundChannels] = useState<any>([]);

  // const {
  //   room,
  // } = useContext(VideoGuestContext);

  const currentUser = useAppSelector(
    (state: VRIAppStateType) => ({
      uid: state.auth.profile?.id,
      uname: state.auth.profile?.name,
    }),
    shallowEqual
  );

  const loadMessagesFor = (thisChannel: any) => {
    if (selectedChannel === thisChannel) {
      thisChannel
        .getMessages()
        .then((messagePaginator: any) => {
          if (selectedChannel === thisChannel) {
            setMessages(messagePaginator.items);
            if (messagePaginator?.items?.length > 0) {
              selectedChannel
                .updateLastReadMessageIndex(
                  messagePaginator.items[messagePaginator.items.length - 1]
                    ?.index
                )
                .then(function () {});
            }
          }
        })
        .catch((err: any) => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
        });
    }
  };
  useEffect(() => {
    if (selectedChannel) {
      selectedChannel?.on('messageAdded', function (message: any) {
        loadMessagesFor(selectedChannel);
      });
    }
  }, [selectedChannel]);

  useEffect(() => {
    selectedChannel &&
      logger({ message: 'Chat sid: ', data: selectedChannel?.sid });
  }, [selectedChannel]);

  useEffect(() => {
    const messageAdded = (message: any, targetChannel: any) => {
      if (targetChannel === selectedChannel) {
        setMessages((prevState: any, props: any) => ({
          messages: [...prevState.messages, message],
        }));
      }
    };
    if (selectedChannel) {
      loadMessagesFor(selectedChannel);

      if (boundChannels.length > 0 && !boundChannels.has(selectedChannel)) {
        let newChannel = selectedChannel;

        newChannel.on('messageAdded', (m: any) => messageAdded(m, newChannel));
        setBoundChannels(new Set([...boundChannels, newChannel]));
      }
    }
  }, [selectedChannel]);

  const onMessageChanged = (event: any) => {
    setNewMessage(event.target.value);
  };

  const checkWhiteSpace = (str: string) => {
    if (!str.replace(/\s/g, '').length) return false;
    else return true;
  };
  const sendMessage = (event: any) => {
    event.preventDefault();
    const message = newMessage;

    setNewMessage('');
    if (selectedChannel && checkWhiteSpace(message))
      selectedChannel.sendMessage(message, {
        gtaSenderName: currentUser.uname,
      });
  };

  return (
    <div className="col-12">
      <div className="row ">
        <div className="d-flex  flex-column-reverse flex-grow-1 chat-message-list-height">
          <LoadingOverlay active={isBusy} animation="border">
            <>
              {[...messages].reverse().map((m: any) => {
                if (m.body !== null) {
                  // console.log("m.author",m.author);
                  // console.log("profile?.id", profile?.id);
 
                  if (m.author === profile?.id || (m.author.includes("guest") && props.participant?.identity.includes("guest")))
                    return (
                      <OutgoingText
                        key={m.index}
                        message={m}
                        author={m.author}
                      />
                    );
                  
                  else
                    return (
                      <IncomingText
                        key={m.index}
                        message={m}
                        author={m.author}
                        authorName={m.attributes.gtaSenderName}
                      />
                    );
                }
              })}
            </>
          </LoadingOverlay>
        </div>
      </div>
      <div className="row py-2">
        <div className="chat-message-editor  w-100">
          <div className="container-fluid w-100">
            <form onSubmit={sendMessage} className="w-100">
              <div className="row align-items-center justify-content-center">
                <div className="col pr-0">
                  <input
                    type="text"
                    className="form-control  chat-form-control"
                    placeholder="Type your message here"
                    onChange={onMessageChanged}
                    value={newMessage}
                  />
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-link send-message-btn pl-2 pr-2 ml-1"
                  >
                    <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 2400 2400">
                      <polygon
                        fill="currentColor"
                        points="40,8.1 2360,1123.2 40,2391.9 225.5,1428.6 1200,1200 257.4,982.6 "
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
