import { useState } from "react";
import { faPhoneAlt, faChevronUp, faChevronDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { ApiSchemaCallLog } from "../../models/api/callLog";
import { CallSummaryCallDetail } from "../CallSummary/callSummaryCallDetail";
import { CallSummaryParticipantDetail } from "../CallSummary/callSummaryParticipantDetail";
import moment from "moment";
import { AppConstants, CallStatusType, DefaultImageType, FileBucketURL, GenderType, } from "../../configs";
import { CallSummaryResponseModel } from "../../models/api/Call";
import { useAppSelector, VRIAppStateType} from "../../core";
import { CallSummaryCustomField } from "../CallSummary/callSummaryCustomField";
import { CallSummarySchedule } from "..";
import Star from "../../components/common/rating/Star";
import { OperatorRatingRequest } from "../../models/api/Operator";
import { useFunctionalityOperator } from "../../pages/hooks";
import { useForm } from "react-hook-form";
import { useFunctionalityImageView } from "../../pages/hooks/functionalityImageView";
import { ModalReportProblem } from "./reportProblemModal";
import { handleNumberToTwoDecimalPoint } from "../../utils/handleNumberToTwoDecimalPoint";
import { AssetSvg } from "../../assets";

type Props = {
  log?: ApiSchemaCallLog;
  summary?: CallSummaryResponseModel;
  show: boolean;
  handleShow: (value: boolean) => void;
  operatorId?: string
};
export function ModalCallSummary(props: Props): JSX.Element {
  const { log, summary, show, handleShow } = props;
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [rating, setRating] = useState(0);
  const { defaultImageLoad, imageView } = useFunctionalityImageView();
  const data = useAppSelector((state: VRIAppStateType) => ({
    userRole: state.auth.profile?.userRole,
    userId: state.auth.profile?.id,
    scheduleJobEnabled:
      state.auth.menuSettings?.callSettings?.scheduleJobEnabled,
  }));

  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const { onCreateOperatorRating, onUpdateOperatorRating } = useFunctionalityOperator({ companyId: "" });
  const { register, handleSubmit } = useForm<OperatorRatingRequest>();

  const onSubmitHandler = (form: OperatorRatingRequest) => {
    setBtnDisabled(true);
    if (summary?.callFeedback === null) {
      form = { ...form, rating: rating, time: new Date(), callSummaryId: summary?.callSummaryId || "", bookingSummaryId: summary?.bookingSummaryId || "" }
      onCreateOperatorRating(form)
        .then(() => {
          setBtnDisabled(false);
          handleShow(false)
        })
        .catch((e: any) => {
          setBtnDisabled(false);
          handleShow(false)
          console.error(e);
        });
    }
    else {
      form = { ...form, rating: rating, time: new Date() }
      onUpdateOperatorRating(form, summary?.callFeedback?.id || "", props?.operatorId ?? "")
        .then(() => {
          handleShow(false)
          setBtnDisabled(false);
        })
        .catch((e: any) => {
          setBtnDisabled(false);
          handleShow(false)
          console.error(e);
        });
    }
  };

  const feedBackExpirationInDays = (date: any) => {
    const expiraDate = moment(date).add(1, 'months').format("MM-DD-YYYY")
    const remainingDays = moment(expiraDate).diff(moment(new Date()), "days");
    return remainingDays <= 0 ? 'Expired' : remainingDays.toString().concat(" days left");
  }
  const feedBackTextArea = () => {
    if (summary?.callFeedback?.comment && summary?.callerId !== data.userId) {
      return <p className="p-2 m-0">{summary.callFeedback.comment ?? ""}</p>
    }
    else if (summary?.callerId === data.userId && feedBackExpirationInDays(summary?.startTime) !== "Expired") {
      return (
        <textarea
          placeholder="write your review"
          cols={15}
          rows={5}
          className="form form-control userComment col-12  mt-1 mb-2 px-3 mx-1"
          {...register('comment', { required: false })}
          defaultValue={
            summary?.callFeedback?.comment ?? ""
          }></textarea>
      );
    }
    else if (summary?.callerId === data.userId && feedBackExpirationInDays(summary?.startTime) === "Expired") {
      return <p className="p-2 m-0">{summary?.callFeedback?.comment ?? ""}</p>
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleShow(false);
          window.history.replaceState({}, document.title);
        }}
        size="lg"
        id="callSummaryModal"
        scrollable
      >
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{summary?.callSummaryId ? "Call" : "Booking"} Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row pb-3">
              <div className="pl-2 ml-1 py-2">
                <div className="header-profile-image">
                  <img
                    src={`${FileBucketURL.Root}${summary?.callerId}?${imageView.fileTimeStamp}`}
                    alt=""
                    onError={(ev) => {
                      defaultImageLoad(
                        ev,
                        DefaultImageType.UserProfile,
                        "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                      );
                    }}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col py-2 ">
                <div className="row">
                  <div className="col-12 h5 m-0">{summary?.name ?? "-"}</div>
                  <div className="col-12 text-muted">
                    {summary?.companyName ?? "-"}
                  </div>
                  <div className="col-12  text-success">Caller</div>
                  <div className="col-12">
                    {summary
                      ? moment(new Date(summary?.startTime ?? "")).format(
                        "HH:mm:ss "
                      ) +
                      " - " +
                      moment(new Date(summary?.endTime ?? "")).format(
                        "HH:mm:ss "
                      )
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="col-4 py-2">
                <div className="row mr-0">
                  <div className="col-12">
                    <div className="row align-items-center flex-row-reverse">
                      <div className="text-muted ">
                        <strong className="d-none d-sm-none d-md-inline">
                          Total time:{" "}
                        </strong>
                        <span>{summary ? `${summary?.totalTime}` : "-"}</span>
                      </div>
                      <div className="">
                        <small>
                          {(summary?.callStatusType === CallStatusType.Missed || summary?.callStatusType === CallStatusType.Rejected || summary?.callStatusType === CallStatusType.Canceled) ?
                            <span className="text-success mr-1 ml-1">
                              <AssetSvg.MissedCall />
                            </span>
                            :
                            <span className="text-success mr-1 ml-1">
                              <AssetSvg.AcceptCallSuccess />
                            </span>
                          }
                        </small>
                      </div>
                    </div>
                  </div>
                  {data.userRole === AppConstants.UserRoles.Admin ||
                    data.userRole === AppConstants.UserRoles.ResellerAdmin ||
                    data.userRole === AppConstants.UserRoles.Finance ||
                    data.userRole === AppConstants.UserRoles.ResellerFinance ? (
                    <div className="col-12 ">
                      <div className="row flex-row-reverse align-items-center">
                        <div className="text-muted">
                          <strong className="d-none d-sm-none d-md-inline">
                            Total bill:{" "}
                          </strong>
                          <span>{(handleNumberToTwoDecimalPoint(summary?.totalBill ? summary?.totalBill : 0))}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {data.userRole === AppConstants.UserRoles.Admin ||
                    data.userRole === AppConstants.UserRoles.ResellerAdmin ||
                    data.userRole === AppConstants.UserRoles.Finance ||
                    data.userRole === AppConstants.UserRoles.ResellerFinance ||
                    data.userRole === AppConstants.UserRoles.Operator ? (
                    <div className="col-12">
                      <div className="row flex-row-reverse align-items-center">
                        <div className="text-muted">
                          <strong className="d-none d-sm-none d-md-inline">
                            Interpreter's earning:{" "}
                          </strong>
                          <span>{(handleNumberToTwoDecimalPoint(summary?.interpretersEarning ? summary?.interpretersEarning : 0))}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="accordion" id="call-summary-panels">
                  <CallSummaryParticipantDetail
                    participants={summary?.callParticipates}
                  />
                  <CallSummaryCallDetail
                    callId={summary?.id ?? ""}
                    language={summary?.language ?? ""}
                    skill={summary?.skill}
                    gender={
                      summary?.gender === GenderType.Male
                        ? "Male"
                        : summary?.gender === GenderType.Female
                          ? "Female"
                          : "Any"
                    }
                    callStartTime={summary?.startTime ?? ""}
                    callEndTime={summary?.endTime ?? ""}
                    bookingSerialNo={summary?.bookingSerialNo}
                    twilioCallId = {summary?.twilioCallId ?? ""}
                  />
                  <CallSummaryCustomField
                    custom1Name={summary?.custom1Name ?? "Custom Field 1"}
                    custom1Value={summary?.custom1Value ?? "(N/A)"}
                    custom2Name={summary?.custom2Name ?? "Custom Field 2"}
                    custom2Value={summary?.custom2Value ?? "(N/A)"}
                    custom3Name={summary?.custom3Name ?? "Custom Field 3"}
                    custom3Value={summary?.custom3Value ?? "(N/A)"}
                    custom4Name={summary?.custom4Name ?? "Custom Field 4"}
                    custom4Value={summary?.custom4Value ?? "(N/A)"}
                  />

                  {data.userRole === AppConstants.UserRoles.Operator
                    ? (
                      <></>
                    ) : (
                      <div className="card">
                        <div
                          className="card-header px-0 py-1"
                          id="headingCallFeedback"
                        >
                          <button
                            className="btn btn-link btn-block text-left text-decoration-none text-site siteAccordion collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#call-feedback"
                            aria-expanded="true"
                            aria-controls="call-feedback"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>Call Feedback</div>
                              <div>
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className="closed-panel-icon"
                                />
                                <FontAwesomeIcon
                                  icon={faChevronUp}
                                  className="open-panel-icon"
                                />
                              </div>
                            </div>
                          </button>
                        </div>

                        <div
                          className="collapse show"
                          id="call-feedback"
                          aria-labelledby="headingCallFeedback"
                          data-parent="#call-summary-panels"
                        >
                          <div className="card-body px-3 py-1">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="row align-items-center">
                                  <div className="pl-2 ml-1 py-2">
                                    <div className="header-profile-image d-block d-sm-block d-md-block">
                                      <img
                                        src={`${FileBucketURL.Root}${summary?.callerId}?${imageView.fileTimeStamp}`}
                                        alt=""
                                        onError={(ev) => {
                                          defaultImageLoad(
                                            ev,
                                            DefaultImageType.UserProfile,
                                            "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                                          );
                                        }}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                  <div className="col py-2">
                                    <form
                                      action=""
                                      onSubmit={handleSubmit(onSubmitHandler)}
                                    >
                                      <div className="row position-relative">
                                        <div
                                          id="star-rating-site"
                                          className="col-12 px-2"
                                        >
                                          <div className="site-rating-input-group">
                                            <Star
                                              setRating={setRating}
                                              view={(summary?.callerId === data.userId && feedBackExpirationInDays(summary?.startTime) !== "Expired") ? false : true}
                                              rating={
                                                summary?.callFeedback?.ratings ? summary?.callFeedback?.ratings : 5
                                              }

                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 pl-2 pr-3">
                                          {
                                            feedBackTextArea()
                                          }
                                          {
                                            (summary?.callerId === data.userId && feedBackExpirationInDays(summary?.startTime) !== "Expired") && <button
                                              type="submit"
                                              className=" btn btn-primary text-uppercase user-from-submit-btn mx-1"
                                              disabled={btnDisabled}
                                            >
                                              SUBMIT
                                            </button>
                                          }
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {data.userRole === AppConstants.UserRoles.Operator ||
                    !data.scheduleJobEnabled ? (
                    <></>
                  ) : (
                    <CallSummarySchedule
                      operatorName={summary?.receiverName ?? ""}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="pt-4 ">
              <p onClick={() => {
                handleShow(false)
                setModalShow(true)
              }}>
                <a className="btn btn-link p-0  footer-alert-btn">
                  <small>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mr-1"
                    />
                    Encountered a problem? Please report Here.
                  </small>
                </a>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {
        modalShow && <ModalReportProblem
          show={modalShow}
          title={`Issue faced with call ${summary?.id}`}
          handleShow={setModalShow}
        />
      }
    </>
  );
}
