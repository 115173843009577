import React from "react";
import { Link } from 'react-router-dom';
import { AppConstants, AppRouteUi } from "../../configs";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector, VRIAppStateType} from "../../core";

export default function OutgoingCallFailedForMicPermission(): JSX.Element {
    const data = useAppSelector((state: VRIAppStateType) => ({
        userRole: state.auth.profile?.userRole,
      }));
    return (
        <div className="">
            <div className="site-call-page">
                <div className="site-call-page-wrapper bg-purple">
                    <div className="container-fluid h-100 d-flex align-items-center justify-content-center ">
                        <div className="row ">
                            <div className="col-12 text-center">
                                <div className="rounded-warning-white">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <h5 className="text-white my-5"> To establish a OPI call, the app needs to use mic. Please enable permission for mic
                                </h5>
                                <Link
                                    to={data.userRole === AppConstants.UserRoles.Operator
                                        ? AppRouteUi.CallLog.Root
                                        : AppRouteUi.Home.Root}
                                    className="btn btn-primary mb-5"
                                    role="button"
                                >
                                    Try Again
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
