import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AssetsAudio } from '../../assets';
import { AppRouteUi, FileBucketURL } from '../../configs';
import {
  CallInitiateTabs,
  PlatformType,
  DefaultImageType,
  CallType,
  SocketConstants,
  CallStatusType,
  PlatformType2,
  AppConstants,
} from '../../configs/appConstants';
import { ActionUi, SocketContext, useAppSelector, VRIAppStateType } from '../../core';
import { ActionVideoCall } from '../../core/Redux/Slices/sliceVideoCall';
import {
  ApiSchemaFilterOperatorRequest,
  FilterOperatorResponseModel,
} from '../../models/api/Operator';
import { useFunctionalityOperator } from '../hooks/functionalityOpeartor';
import {
  useFunctionalityAvailableManagers,
  useFunctionalityUser2,
  useFunctionalityUserGetUserById,
} from '../hooks/functionalityUser';
import { AssetsPng } from '../../assets';
import { useFunctionalityImageView } from '../hooks/functionalityImageView';
import { useFunctionalityCalls } from '../hooks/functionalityCalls';
import { videoCallInitiateParameter } from '../../models/videoCall';
import { ApiResponse, ApiSchemaAvailableManager } from '../../models';
import { useFunctionalityCompanyStatus } from '../hooks';
import { unstable_batchedUpdates } from 'react-dom';
import { controlBrowserBackAndReloadButton } from '../../helpers';
import { UserMediaUtils, logger } from '../../utils';
import { MediaPreviewer } from '../../components';

export function PageVideoOutgoing(): JSX.Element {
  const audioRef = useRef<HTMLAudioElement>(null);
  const pendingTracksRef = useRef(true);
  const callDialTimeoutId = useRef<number | null>(null);
  const history = useHistory();
  const location = useLocation<{
    operatorFilterParam?: ApiSchemaFilterOperatorRequest;
    videoCallInitiateParam?: videoCallInitiateParameter;
    callInitiateTab?: string;
    directCallSelectedUserId?: string;
    callType: CallType;
    bookingId?: string;
  }>();
  //console.trace("Redirecting:");
  !location.state && history.push(AppRouteUi.Home.Root);
  
  const { controlBrowserBackButton } = controlBrowserBackAndReloadButton();
  const [selectedUserId, setSelectedUserId] = React.useState<string>(
    location?.state?.directCallSelectedUserId || ''
  );
  const { getUserStatus } = useFunctionalityUser2({});
  const currentUserCompanyId = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.companyId
  );

  const { onAvailableManager } = useFunctionalityAvailableManagers({
    companyId: currentUserCompanyId ?? '',
  });

  const [availableManager, setAvailableManager] =
    useState<ApiSchemaAvailableManager>();
  const managerResponse = useAppSelector(
    (state: VRIAppStateType) => state.auth.menuSettings?.callSettings?.managerResponse
  );

  const { oncreate, onUpdate } = useFunctionalityCalls();
  const [messageOperatorNotFound, setMessageOperatorNotFound] =
    useState<string>(
      'Operator is not available at this moment or busy with other call. Please try again later'
    );
  const { filteredOperatorList } = useFunctionalityOperator({});
  const [parentCallId, setParentCallId] = useState<string>('');
  const [callId, setCallId] = useState<string>('');
  const [callForwardCount, setCallForwardCount] = useState<number>(0);
  const dispatch = useDispatch();
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      // receiverName: state.ui.receiverName,
      callerId: state.auth.profile?.id,
      receiverId: state.ui.receiverId,
      companyId: state.auth.profile?.companyId,
      callStatus: state.videoCall.CallStatus,
      callType: location?.state?.callType,
      role: state.auth.profile?.userRole,
      tracks: state.videoCall.tracks,
    }),
    shallowEqual
  );
  const callStatusRef = React.useRef(data.callStatus);
  callStatusRef.current = data.callStatus;
  const { userData: dialUser } = useFunctionalityUserGetUserById({
    companyId: data?.companyId,
    userId: selectedUserId,
  });
  const isCalleeOperator = dialUser?.userRole === AppConstants.UserRoles.Operator;
  const { onCheckCompanyStatusIsActive } = useFunctionalityCompanyStatus();
  const [companyStatusIsActive, setCompanyStatusIsActive] = useState<boolean>();
  const [companyStatusMsg, setCompanyStatusMsg] = useState<string>();


  useEffect(()=> {
    if(data.tracks === null && pendingTracksRef.current === true) {
      const getTracks = async()=> {
        const tracks = await UserMediaUtils.startMedia();
        if(!tracks) {
          //console.trace("Redirecting:");
          history.push(AppRouteUi.OutgoingCallFailedForMedia.Root);
          return;
        }
        dispatch(ActionVideoCall.setTracks(tracks));
        pendingTracksRef.current = false;
      }

      getTracks();
    } else {
      pendingTracksRef.current = false;
    }
  },[data.tracks, dispatch, history]);

  useEffect(() => {
    const audio = audioRef.current;
    onCheckCompanyStatusIsActive(currentUserCompanyId ?? '')?.then((res) => {
      unstable_batchedUpdates(() => {
        setCompanyStatusIsActive(res?.data);
        setCompanyStatusMsg(res?.message);
      });
    });

    return ()=> {
      audio?.pause();
    }
  }, []);

  React.useEffect(() => {
    if (companyStatusIsActive !== undefined && companyStatusMsg !== undefined) {
      if (companyStatusIsActive) {
        if (location?.state.callInitiateTab === CallInitiateTabs.directCall) {
          if (
            (data.callStatus?.prev === CallStatusType.Rejected &&
              data.callStatus?.current === CallStatusType.Searching) ||
            (data.callStatus?.prev === CallStatusType.Missed &&
              data.callStatus?.current === CallStatusType.Searching)
          ) {
            //console.trace("Redirecting:");
            history.push({
              pathname: AppRouteUi.OutgoingCallFailed.Root,
              state: {
                operatorFilterParam: location?.state.operatorFilterParam,
                callInitiateTab: location?.state.callInitiateTab,
                videoCallInitiateParam: location?.state.videoCallInitiateParam,
                directCallSelectedUserId:
                  location?.state.directCallSelectedUserId,
                callType: CallType.Video,
                message:
                  'The user you have called is not available at the moment. Please try again later',
              },
            });
          }
        }
        if (
          location?.state.callInitiateTab === CallInitiateTabs.searchInterpreter
        ) {
          if (
            data.callStatus?.current === CallStatusType.Rejected &&
            data.callStatus?.prev === CallStatusType.Ringing
          ) {
            dispatch(
              ActionVideoCall.SetCallStatus({
                prev: CallStatusType.Rejected,
                current: CallStatusType.Searching,
              })
            );
          }

          if (
            (data.callStatus?.current === CallStatusType.Searching &&
              data.callStatus?.prev === CallStatusType.Rejected) ||
            (data.callStatus?.current === CallStatusType.Searching &&
              data.callStatus.prev === CallStatusType.Missed) ||
            (data.callStatus?.current === CallStatusType.Searching &&
              data.callStatus.prev === CallStatusType.Initiated) ||
            (data.callStatus?.current === CallStatusType.Searching &&
              data.callStatus.prev === CallStatusType.Zero)
          ) {
            // operatorSearchCount++;
            const model = {
              ...location?.state.operatorFilterParam,
              parentCallId: parentCallId,
              callForwardCount: callForwardCount,
            };
            logger({ message: 'Getting available operator' });
            filteredOperatorList(model || {}, PlatformType.VRI)
              .then((res?: ApiResponse<FilterOperatorResponseModel>) => {
                if (res?.data.operatorId) {
                  setParentCallId(res.data.callId);
                  setCallForwardCount(res.data.callForwardCount);
                  dispatch(
                    ActionVideoCall.SetCallStatus({
                      prev: CallStatusType.Searching,
                      current: CallStatusType.Initiated,
                    })
                  );

                  setSelectedUserId(res.data.operatorId);
                  videoCallInitiateHandler(
                    res.data.operatorId,
                    location?.state.videoCallInitiateParam?.LanguageName || '',
                    res.data.callId
                  );
                  callDialTimeoutId.current = window.setTimeout(
                    (function (x) {
                      return function () {
                        if (
                          callStatusRef?.current?.current !==
                            CallStatusType.Ringing &&
                          callStatusRef?.current?.current !==
                            CallStatusType.Completed &&
                          callStatusRef?.current?.current !==
                            CallStatusType.Established
                        ) {
                          dispatch(
                            ActionVideoCall.SetCallStatus({
                              prev: CallStatusType.Initiated,
                              current: CallStatusType.Searching,
                            })
                          );
                        }
                      };
                    })(data),
                    40000
                  );
                } else {
                  if (managerResponse) {
                    onAvailableManager()?.then((res2) => {
                      const managers = res2?.data ?? [];

                      if (managers?.length > 0) {
                        setAvailableManager(managers[0]);
                        oncreate({
                          callerUserId: data.callerId,
                          callStatus: CallStatusType.Initiated,
                          platform: PlatformType2.VRI,
                          calleeId: managers[0]?.id,
                          bookingId: location?.state?.bookingId ?? '',
                          supportType: 1,
                        }).then((d) => {
                          videoCallInitiateHandler(
                            managers[0]?.id || '',
                            '',
                            d ?? ''
                          ); /// Implement create call
                        });
                      } else {
                        if (res?.data.message)
                          setMessageOperatorNotFound(res.data.message);
                        dispatch(
                          ActionVideoCall.SetCallStatus({
                            prev: CallStatusType.Searching,
                            current: CallStatusType.OperatorNotFound,
                          })
                        );
                        //console.trace("Redirecting:");
                        history.push({
                          pathname: AppRouteUi.OutgoingCallFailed.Root,
                          state: {
                            operatorFilterParam:
                              location?.state.operatorFilterParam,
                            callInitiateTab: location?.state.callInitiateTab,
                            videoCallInitiateParam:
                              location?.state.videoCallInitiateParam,
                            directCallSelectedUserId:
                              location?.state.directCallSelectedUserId,
                            callType: CallType.Video,
                            message: res?.data.message,
                          },
                        });
                      }
                    });
                  } else {
                    if (res?.data.message)
                      setMessageOperatorNotFound(res.data.message);
                    dispatch(
                      ActionVideoCall.SetCallStatus({
                        prev: CallStatusType.Searching,
                        current: CallStatusType.OperatorNotFound,
                      })
                    );
                    //console.trace("Redirecting:");
                    history.push({
                      pathname: AppRouteUi.OutgoingCallFailed.Root,
                      state: {
                        operatorFilterParam:
                          location?.state.operatorFilterParam,
                        callInitiateTab: location?.state.callInitiateTab,
                        videoCallInitiateParam:
                          location?.state.videoCallInitiateParam,
                        directCallSelectedUserId:
                          location?.state.directCallSelectedUserId,
                        callType: CallType.Video,
                        message: res?.data.message,
                      },
                    });
                  }
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
      } else {
        //console.trace("Redirecting:");
        history.push({
          pathname: AppRouteUi.OutgoingCallFailed.Root,
          state: {
            operatorFilterParam: location?.state.operatorFilterParam,
            callInitiateTab: location?.state.callInitiateTab,
            videoCallInitiateParam: location?.state.videoCallInitiateParam,
            directCallSelectedUserId: location?.state.directCallSelectedUserId,
            callType: CallType.Video,
            message: companyStatusMsg,
          },
        });
      }
    }

    return () => {
      callDialTimeoutId.current && clearTimeout(callDialTimeoutId.current);
      callDialTimeoutId.current = null;
    };
  }, [data?.callStatus?.current, companyStatusIsActive]);

  React.useEffect(() => {
    if (location?.state?.callInitiateTab === CallInitiateTabs.directCall) {
      dispatch(
        ActionVideoCall.SetCallStatus({
          prev: CallStatusType.Zero,
          current: CallStatusType.Initiated,
        })
      );
      getUserStatus(location?.state.directCallSelectedUserId ?? '').then(
        (d) => {
          if (d === SocketConstants.online) {
            oncreate({
              callerUserId: data.callerId,
              callStatus: CallStatusType.Initiated,
              platform: PlatformType2.VRI,
              calleeId: location?.state.directCallSelectedUserId,
              bookingId: location?.state.bookingId,
              supportType: 1,
            }).then((d) => {
              videoCallInitiateHandler(
                location?.state.directCallSelectedUserId || '',
                '',
                d ?? ''
              ); /// Implement create call
            });
          } else {
            dispatch(
              ActionVideoCall.SetCallStatus({
                prev: CallStatusType.Searching,
                current: CallStatusType.UserNotFound,
              })
            );
            //console.trace("Redirecting:");
            history.push({
              pathname: AppRouteUi.OutgoingCallFailed.Root,
              state: {
                operatorFilterParam: location?.state.operatorFilterParam,
                callInitiateTab: location?.state.callInitiateTab,
                videoCallInitiateParam: location?.state.videoCallInitiateParam,
                directCallSelectedUserId:
                  location?.state.directCallSelectedUserId,
                callType: CallType.Video,
                message:
                  'The user you have called is not available at the moment. Please try again later',
              },
            });
          }
        }
      );
    }
  }, []);

  const videoCallInitiateHandler = (
    selectedUserId: string,
    selectedLanguage: string,
    callId: string
  ) => {
    dispatch(ActionUi.SetReceiverId(selectedUserId));
    // dispatch(ActionUi.SetLanguageName(selectedLanguage));
    setCallId(callId);
  };
  const videoCallCanceled = (socketConnection: any) => {
    if (callId) {
      onUpdate(callId, {
        callStatus: CallStatusType.Canceled,
        eventTime: new Date().toISOString(),
        receiverUserId: selectedUserId,
        isCallStarted: false,
      });
    } else {
      //console.trace("Redirecting:");
      history.push({
        pathname:
          data.role === AppConstants.UserRoles.Operator
            ? AppRouteUi.CallLog.Root
            : AppRouteUi.Home.Root,
        state: {
          CallStatus: CallStatusType.Canceled,
        },
      });
    }
  };
  const onEndedPlay = (ev: any) => {
    ev.target.currentTime = 0;
    ev.target.play();
  };
  const { defaultImageLoad, imageView } = useFunctionalityImageView();
  useEffect(() => {
    if (data.callStatus?.current === CallStatusType.Ringing) {
      controlBrowserBackButton();
    }
  }, [data.callStatus?.current]);
  useEffect(() => {
    if (data.callStatus?.current === CallStatusType.Ringing) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [data.callStatus?.current]);

  useEffect(() => {
    let timeoutId: number = 0;
    const stopSearchRetryOnBookingCall = () => {
      if (location?.state?.bookingId) {
        dispatch(
          ActionVideoCall.SetCallStatus({
            prev: data.callStatus?.current!,
            current: CallStatusType.Failed,
          })
        );
        //console.trace("Redirecting:");
        history.push(AppRouteUi.Jobs.Root);
      }
    };

    if (data.callStatus?.current === CallStatusType.Ringing) {
      timeoutId = window.setTimeout(() => {
        if (location?.state?.bookingId) {
          stopSearchRetryOnBookingCall();
          return;
        }
        if (data.callStatus?.current === CallStatusType.Ringing) {
          dispatch(
            ActionVideoCall.SetCallStatus({
              prev: CallStatusType.Rejected,
              current: CallStatusType.Searching,
            })
          );
        }
      }, 45000);
    }

    if (
      data.callStatus?.current === CallStatusType.UserNotFound ||
      data.callStatus?.current === CallStatusType.Missed ||
      data.callStatus?.current === CallStatusType.Rejected ||
      (data.callStatus?.current === CallStatusType.Searching &&
        data.callStatus?.prev !== CallStatusType.Zero)
    ) {
      timeoutId && clearTimeout(timeoutId);
      stopSearchRetryOnBookingCall();
    }

    return ()=> {
      timeoutId && clearTimeout(timeoutId);
    }
  }, [data.callStatus, location?.state?.bookingId, dispatch, history]);

  return (
    <>
      {data.callStatus?.current == CallStatusType.Ringing ? (
        <audio
          src={AssetsAudio.DialTone}
          autoPlay
          onEnded={onEndedPlay}
          ref={audioRef}
        />
      ) : (
        <></>
      )}
      <div className="site-call-page ">
        <div className="site-call-page-wrapper">
          <div className="container-fluid h-100">
            <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
              <div className="col-12 text-center d-flex align-items-center justify-content-center pt-3">
                <ul className="babelCallAnimation bg-white caller-image">
                  {data.callStatus?.current == CallStatusType.Searching ? (
                    <img
                      src={AssetsPng.SearchAnimation}
                      alt="searching"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={`${FileBucketURL.Root}${data.receiverId}?${imageView.fileTimeStamp}`}
                      alt=""
                      onError={(ev) => {
                        defaultImageLoad(
                          ev,
                          DefaultImageType.UserProfile,
                          '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                        );
                      }}
                      className="img-fluid"
                    />
                  )}
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <div className="col-12 text-center p-3 text-white">
                <p className="mb-3 pb-3">Outgoing VRI call</p>
                {data.callStatus?.current !== CallStatusType.Searching &&
                data.callStatus?.current !== CallStatusType.OperatorNotFound ? (
                  <>
                    <p className="pb-3 mb-3 mt-0 h4 font-400">
                      {dialUser.name}
                    </p>
                    {!isCalleeOperator && (
                      <p className="mb-3  mt-0 h5 font-500">
                        {dialUser.companyName}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {managerResponse && availableManager && (
                      <>
                        <p className="pb-3 mb-3 mt-0 h4 font-400">
                          {availableManager?.name}
                        </p>
                        <p className="mb-3 mt-0 h5 font-500">
                          {availableManager?.companyName}
                        </p>
                      </>
                    )}
                  </>
                )}

                <p className="mb-3 pb-4">
                  {data.callStatus?.current === CallStatusType.UserNotFound
                    ? 'The user you have called is not available at the moment. Please try again later'
                    : data.callStatus?.current ===
                      CallStatusType.OperatorNotFound
                    ? messageOperatorNotFound
                    : data.callStatus?.current
                    ? CallStatusType[data.callStatus?.current]
                    : ''}
                </p>
              </div>
              {data.callStatus?.current !== CallStatusType.Searching && (
                <div className="col-12 text-center pb-3">
                  <SocketContext.Consumer>
                    {(value) => (
                      <div className="d-flex align-items-center justify-content-center container">
                        <Link
                          to={AppRouteUi.Home.Root}
                          className="btn  mx-4  callPage-controlBtn btn-red"
                          role="button"
                          onClick={() => {
                            logger({ message: 'Call hangup button pressed' });
                            dispatch(
                              ActionVideoCall.SetCallStatus({
                                prev: CallStatusType.Ringing,
                                current: CallStatusType.Canceled,
                              })
                            );
                            videoCallCanceled(value.socketConnection);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                          </svg>
                        </Link>
                      </div>
                    )}
                  </SocketContext.Consumer>
                </div>
              )}
            </div>
          </div>
        </div>
        <MediaPreviewer/>
      </div>
    </>
  );
}
