import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import { AppConstants, ColumnSelectionTableType, ExportTableType, PaginationSettings } from "../../configs";
import { useAppSelector,  VRIAppStateType } from "../../core";
import {
  InfoBox,
  CommonFilter,
  JobTablePhone,
  JobTableWeb,
  ModalCreateJob,
  Searchbar,
} from "../../features";
import { ColumnSelectionsModel } from "../../models";
import { ApiSchemaColumn, ApiSchemaFilter } from "../../models/api/filter";
import { useFunctionalityColumnSelections, useFunctionalityJob } from "../hooks";

export function PageJobs(): JSX.Element {
  const [pageIndex, setPageIndex] = useState(
    PaginationSettings.initialPageIndex
  );
  const [pageSize, setPageSize] = useState(
    PaginationSettings.initialPageSizeForBooking
  );
  const [searchText, setSearchText] = useState<string>("");
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);

  // let testColumn: ApiSchemaColumn = { columnName: "Gender", value: "0" };
  let initialFilterData: ApiSchemaFilter = {
    filterQueries: [],
    sortQuery: {}
  };
  const [filterData, setFilterData] = useState<ApiSchemaFilter>(initialFilterData);
  const [filterDataChange, setFilterDataChange] = useState<number>(0);

  const { jobList, infoBoxData, jobListUrl, totalJobList } = useFunctionalityJob({
    pageSize: pageSize,
    pageIndex: pageIndex,
    searchText: searchText?.trimStart(),
    setLoaderProgress: setLoaderProgress,
    filterData: filterData,
    filterDataChange: filterDataChange,
  });

  useEffect(() => {
    if (totalJobList) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [totalJobList]);

  const [show, setShow] = React.useState(false);
  const [modalKey, setModalKey] = useState(Date.now() + "");

  const userRole = useAppSelector((state:  VRIAppStateType) => state.auth.profile?.userRole);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };

  const [columnSelection, setColumnSelection] = useState<ColumnSelectionsModel[]>([])
  const [columnSelectionModalShow, setColumnSelectionModalShow] = React.useState(false);
  const { LoadColumnSelections } = useFunctionalityColumnSelections({
    type: ColumnSelectionTableType.Booking
  });

  useEffect(() => {
    LoadColumnSelections().then((r) => {
      if (r?.data) setColumnSelection(r?.data);
    });
  }, [])

  return (
    <>
      <h4 className="my-3 font-weight-light">
        Booking
      </h4>
      <div className="row">
        <div className="col-12 d-flex">
          <div className="flex-shrink-0">
            {userRole !== AppConstants.UserRoles.ResellerFinance &&
              userRole !== AppConstants.UserRoles.Finance &&
              userRole !== AppConstants.UserRoles.Operator ? (
              <button
                className="btn btn-primary d-flex align-items-center mr-2"
                onClick={() => setShow(true)}
              >
                <span className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 448 512">
                    <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                  </svg>
                </span>
                <span>Create Job</span>
              </button>
            ) : null}
          </div>
          <div className="w-100">
            <Searchbar
              showFilter={true}
              filterPanel={"commonFilterPanel"}
              showExportOptions={true}
              searchText={searchText}
              handleSearch={handleSearch}
              mutateUrl={jobListUrl}
              showAutoRefresh={true}
              columnSelectionModalShow={columnSelectionModalShow}
              setColumnSelectionModalShow={setColumnSelectionModalShow}
              columnSelection={columnSelection}
              setColumnSelection={setColumnSelection}
              tableType={ExportTableType.Booking}
              filterData={filterData}
              downloadedFileName='Booking Report'
              setLoaderProgress={setLoaderProgress}
              setIsShowCommonFilter={setIsShowCommonFilter}
            />
          </div>
        </div>
        {isShowCommonFilter ?
          <CommonFilter
            setFilterData={setFilterData}
            setLoaderProgress={setLoaderProgress}
            setFilterDataChange={setFilterDataChange}
            pageName='jobsPage'
            setPageIndex={setPageIndex}
          />
          : null}
      </div>
      <div className="pt-3">
        <div className="summary-box-wrapper d-flex">
          <InfoBox data={infoBoxData} />
        </div>
      </div>

      <div className="job-table-container">
        <JobTableWeb
          jobListData={jobList}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setSearchText={setSearchText}
          loaderProgress={loaderProgress}
          setLoaderProgress={setLoaderProgress}
          hasPagingData={hasPagingData}
          setHasPagingData={setHasPagingData}
          setFilterData={setFilterData}
          setFilterDataChange={setFilterDataChange}
          columnSelection={columnSelection}
          totalJobList={totalJobList}
          mutateUrl={jobListUrl}
        />

        {/* <JobTablePhone jobListData={jobList} /> */}
      </div>
      {show &&
        <ModalCreateJob
          show={show}
          handleShow={(value) => {
            setShow(value);
            setModalKey(Date.now() + "");
          }}
          key={modalKey}
          mutateUrl={jobListUrl}
        />
      }
    </>
  );
}
