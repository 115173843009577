
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { AssetsPng, AssetSvg } from "../../assets";
import { FormErrorMessage } from "../../components";
import { AppRouteUi } from '../../configs/appRoute';
import { useFunctionalityAuth } from '../hooks';

export function PageForgotPassword(): JSX.Element {
  const { onForget } = useFunctionalityAuth();

  const history = useHistory();

  const { register, handleSubmit, formState: { errors } } = useForm<{ email: string }>();

  return <>
    <div className="position-fixed h-100 w-100 login-container-bg overflow-auto">
      <div className="container-md container-login-sm py-3 px-0 px-sm-0 px-md-3 px-lg-3">
        <div className="row m-auto">
          <div className="col-12 text-center my-5">
            <h1 className=" text-uppercase page-title">Forgot Your Password?</h1>
          </div>
        </div>
        <div className="row m-auto login-container-card">
          <div className="col-12">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="login-logo m-3">
                  <AssetSvg.VRILogo />

                </div>
              </div>
              <div className="col-12">
                <form className="login-form p-4 " onSubmit={handleSubmit(onForget)} noValidate>
                  <div className="form-group">

                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text text-muted">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        {...register('email', {
                          required: true
                        })}
                        placeholder="Email"
                        autoFocus />
                    </div>
                    {
                      errors.email && (
                        <FormErrorMessage message="required" />
                      )
                    }
                  </div>
                  <div className="form-group d-flex justify-content-sm-between align-items-sm-center flex-wrap mb-1 pt-3">

                    <a onClick={() => {
                      //console.trace("Redirecting:");
                      history.push(AppRouteUi.Login.Root)
                    }} className="btn btn-link font-sz-14 btn-site-sm mt-3 mt-sm-0 px-0 order-1 order-sm-0">
                      {`Back to login`}
                    </a>
                    <button type="submit" className="ladda-button font-sz-14 forget-form-submit-btn btn btn-purple btn-site-sm order-0 order-sm-1">
                      Submit
                    </button>

                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="mb-1  p-4 font-sz-12 ">
              Enter your email address and we will send you instructions on how to create a new password.
            </p>
          </div>
        </div>
      </div>
    </div>

  </>;
}