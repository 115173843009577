import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { BottomActionBar, Chat, Room, TopActionBar } from '../../components';
import { AppRouteUi, CallStatusType, CallType } from '../../configs';
import { ActionCommon, VideoCallContext, useAppSelector, VRIAppStateType, ActionVideoCall } from '../../core';
import { useFunctionalityRoomShareMap } from "../hooks/functionalityRoomShareMap";
import { ModalHangupCall, ModalVideoConferenceLink } from '../../features';
import { searchInCurrentURL } from '../../utils';
import { useVideoCall } from '../hooks';
import { logger, UserMediaUtils } from '../../utils';
import { VideoGuestCall } from "../../components/videoCall/VideoGuestCall";
import { useDispatch } from 'react-redux';
import { RoomShareMapModel } from "../../models/api/Call";
import { device } from '../../configs';
import { ActionAuth, ActionUi, TSliceUi, CallContext } from "../../core";
//instead of using the socketcontext for making an API to get a webtoken directly, we will obtain it from a different API (different table) - and so we have to set it ourselves in the store

export function VideoGuestPage() {

    logger({ message: "VideoGuestPage has been visited" });

    const [roomMapData, setRoomMapData] = useState<any>({});

    const [isCreatingRoom, setIsCreatingRoom] = useState<boolean>(true);

    const [isApiLoading, setIsApiLoading] = useState<boolean>(true);

    const [currentState, SetCurrentState] = useState<CallStatusType>(CallStatusType.Zero);

    const audioRef = useRef<HTMLAudioElement>(null);
    const interval = useRef<any>(null);

    const dispatch = useDispatch();

    const { guid } = useParams<{ guid: string }>();  // Extracting the guid from the route
    

    const { LoadRoomMap } = useFunctionalityRoomShareMap(); //brining in the function responsible for the API call

    // const {setIsAccepted } = useContext(CallContext);


    //setting up things needed for the call, like tracks
    //inspiration from src\pages\public\VideoIncomingCallPage.tsx
    const createVideoCall = async () => { 

        console.log("createVideoCall has been invoked");
        audioRef.current?.pause();
        clearTimeout(interval.current);
        //TESTING IMPLEMENTATION. MOVE TO A BETTER IMPLEMENTATION
        dispatch(ActionCommon.setTwilioDeviceStatus('online'));

        const tracks = await UserMediaUtils.startMedia();

        //tracks need to be dispatched to be received later on by the useGuestCall page.
        tracks && dispatch(ActionVideoCall.setTracks(tracks));

        console.log("tracks:");
        console.log(tracks);
        if (tracks) {
            SetCurrentState(CallStatusType.Received);
            dispatch(
                ActionVideoCall.SetCallStatus({
                    prev: CallStatusType.Zero,
                    current: CallStatusType.Received,
                })
            );
            setIsCreatingRoom(false);
        } else {
            SetCurrentState(CallStatusType.Rejected);
            dispatch(
                ActionVideoCall.SetCallStatus({
                    prev: CallStatusType.Zero,
                    current: CallStatusType.Rejected,
                })
            );
            setIsCreatingRoom(false);
        }
    };

    // useEffect(() => {
    //     console.log("VideoGuestPage TWILIODEVICESTATUS related useEffect has been triggered")
    //     //brought in from CallContext. Might be a good idea to go back to handling this logic there since the dispatches there (which are in the
    //     //dependency array of this useEffect) might be relevant to what should happen inside it; not the dispatches in this page. However, it is
    //     //also a guest call and most normal things don't happen inside of it, so as a shallow implementation:
    //     const handleDeviceOffline = () => {
    //       dispatch(ActionCommon.setTwilioDeviceStatus('offline'));
    //     };
    //     const handleDeviceReady = () => {
    //       dispatch(ActionCommon.setTwilioDeviceStatus('online'));
    //     };
    //     device.on('offline', handleDeviceOffline);
    //     device.on('ready', handleDeviceReady);
    
    //     return () => {
    //       device.off('offline', handleDeviceOffline);
    //       device.off('ready', handleDeviceReady);
    //     };
    //   }, [dispatch]);



    useEffect(() => {
        console.log('--------------createVideoCall useEffect inside VideoGuestPage triggered');
        createVideoCall();
    }, []);


    useEffect(() => {

        console.log("triggered useEffect for making the guid API call");

        //setting up guid globally to be received later by the share link button. Assuming that guest is allowed
        //to share the call => because if not, then no need to dispatch it here. The normal callers are getting
        //the guid through websocket; this dispatch here is irrelevant to them (and may be removed if we decide
        //that a guest has no business sharing the call link). 
        dispatch(ActionUi.SetGuid(guid));

        const fetchRoomParameters = async (guid: string) => {
            setIsApiLoading(true);
            try {
                // Call LoadRoomMap instead of making the API call directly
                const data = await LoadRoomMap(guid);

                console.log("data received by the API LoadRoomMap inside VideoGuestPage");
                console.log(data);

                //@ts-ignore
                if (data?.webToken) {
                    //@ts-ignore
                    dispatch(ActionAuth.SetVRItoken(data.webToken));
                } else {
                    console.error("webToken is undefined");
                }

                //@ts-ignore
                if (data?.chatSid) {
                    //@ts-ignore
                    dispatch(ActionUi.SetChatSid(data.chatSid));
                } else {
                    console.error("ChatSid is undefined");
                }


                setRoomMapData(data);

            } catch (error) {
                console.error("Error fetching session data:", error);
                setRoomMapData(null); // Set null in case of an error
            } finally {

                setIsApiLoading(false);
            }
        };

        if (guid) {
            console.log("we have guid, fetching room parameters:");
            fetchRoomParameters(guid);
        }
    }, [guid, LoadRoomMap]);


    console.log("Rerender of VideoGuestPage. isApiLoading: " + isApiLoading + " - isCreatingRoom: " + isCreatingRoom + " - guid: " + guid + " - roomMapData:");
    console.log(JSON.stringify(roomMapData, null, 2));


    return (

        <>
            {isApiLoading || isCreatingRoom || !(currentState === CallStatusType.Received) ? (

                <div className="site-call-page ">
                    <div className="site-call-page-wrapper">
                        <div className="container-fluid h-100">
                            <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
                                <div className="col-12 text-center d-flex flex-column align-items-center justify-content-center pt-3">
                                    <p className="text-white">Connecting...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {!roomMapData.webToken || !roomMapData.roomName ? (
                        <div className="site-call-page ">
                            <div className="site-call-page-wrapper">
                                <div className="container-fluid h-100">
                                    <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
                                        <div className="col-12 text-center d-flex flex-column align-items-center justify-content-center pt-3">
                                            <p className="text-white">Call Parameters are invalid. Please make sure you are accessing an ongoing call.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <VideoGuestCall roomId={roomMapData.roomName} />
                    )
                    }
                </>
            )}

        </>
    );
}
