import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import {
  createLocalVideoTrack,
  LocalParticipant,
  LocalVideoTrack,
  MediaStreamTrackPublishOptions,
  Participant,
  RemoteParticipant,
  RemoteVideoTrack,
  Room,
  VideoTrackPublication,
} from 'twilio-video';
import { PlatformApi } from '../configs';
import { AxiosAuth } from '../core';
import { ICallParticipantInfo } from '../models';

export function twilioParticipantHelper() {
  const getParticipantType = (
    localParticipant: LocalParticipant,
    participant: Participant
  ): 'Remote' | 'Local' => {
    if (localParticipant?.identity === participant?.identity) {
      return 'Local';
    } else {
      return 'Remote';
    }
  };

  const getCallParticipantInfo = async (
    participant: Participant,
    roomId: string
  ): Promise<ICallParticipantInfo | null> => {
    try {
      const response = await AxiosAuth.get(
        PlatformApi.Call.GetCallParticipantInfo(roomId, participant.identity)
      );
      return response.data.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getCallParticipantInfoGuest = async (
    participant: Participant,
    roomId: string
  ): Promise<ICallParticipantInfo | null> => {
    try {
      const response = await AxiosAuth.get(
        PlatformApi.Call.GetCallParticipantInfoGuest(roomId, participant.identity)
      );
      return response.data.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const convertParticipantsToArray = (
    participants: Map<string, RemoteParticipant>
  ): RemoteParticipant[] => {
    if (!participants) {
      return [];
    }
    const modifiedParticipants = [];
    for (const participant of participants) {
      modifiedParticipants.push(participant[1]);
    }
    return modifiedParticipants;
  };

  const stopAllTrack = (room: Room) => {
    if (!room) {
      return;
    }
    room.localParticipant.tracks.forEach((trackPublication: any) => {
      trackPublication.track.stop();
    });
  };

  const enableAllTrack = (
    participant: LocalParticipant,
    type: 'Audio' | 'Video',
    except?: 'screen'
  ) => {
    if (type === 'Audio') {
      participant?.audioTracks.forEach((audioTrack) =>
        audioTrack.track.enable()
      );
    } else {
      participant?.videoTracks.forEach((videoTrack) => {
        if (videoTrack.trackName !== except) {
          videoTrack.track.enable();
        }
      });
    }
  };

  const disableAllTrack = (
    participant: LocalParticipant,
    type: 'Audio' | 'Video',
    except?: 'screen'
  ) => {
    if (type === 'Audio') {
      participant?.audioTracks.forEach((audioTrack) =>
        audioTrack.track.disable()
      );
    } else {
      participant?.videoTracks.forEach((videoTrack) => {
        if (videoTrack.trackName !== except) {
          videoTrack.track.disable();
        }
      });
    }
  };

  const getScreenTrack = (
    participant: Participant
  ): LocalVideoTrack | RemoteVideoTrack | null => {
    const screenTrack: VideoTrackPublication | undefined = [
      ...participant.videoTracks.values(),
    ].find((track) => track.trackName === 'screen');
    return screenTrack ? screenTrack.track : null;
  };

  const getCameraTrack = (
    participant: Participant
  ): LocalVideoTrack | RemoteVideoTrack | null => {
    const cameraTrack: VideoTrackPublication | undefined = [
      ...participant.videoTracks.values(),
    ].find((track) => track.trackName !== 'screen');
    return cameraTrack ? cameraTrack.track : null;
  };

  const getParticipantVideoTrack = (
    participant: Participant
  ): LocalVideoTrack | RemoteVideoTrack | null => {
    const cameraTrack = getCameraTrack(participant);
    const screenTrack = getScreenTrack(participant);
    return screenTrack ? screenTrack : cameraTrack;
  };

  const getVideoTrack = async ({
    additionalSupport,
  }: {
    additionalSupport: 'iOSApp' | 'Default';
  }) => {
    let videoTrack: LocalVideoTrack;

    if (additionalSupport === 'iOSApp') {
      videoTrack = await createLocalVideoTrack({
        name: 'camera-' + Date.now().toString(),
      });
    } else {
      videoTrack = await createLocalVideoTrack();
    }

    return videoTrack;
  };

  const handleStream = (
    stream: MediaStream,
    room: Room,
    setIsSharing: Dispatch<SetStateAction<boolean>>,
    stopScreenShareRef: MutableRefObject<() => void>
  ) => {
    const track = stream.getTracks()[0];
    room!.localParticipant
      .publishTrack(track, {
        name: 'screen',
        priority: 'low',
      } as MediaStreamTrackPublishOptions)
      .then((trackPublication) => {
        stopScreenShareRef.current = () => {
          room!.localParticipant.unpublishTrack(track);
          room!.localParticipant.emit('trackUnpublished', trackPublication);
          track.stop();
          setIsSharing(false);
        };

        track.onended = stopScreenShareRef.current;
        setIsSharing(true);
      })
      .catch(() => {
        console.log('error');
      });
  };

  return {
    getParticipantType,
    getCallParticipantInfo,
    getCallParticipantInfoGuest,
    convertParticipantsToArray,
    stopAllTrack,
    enableAllTrack,
    disableAllTrack,
    getScreenTrack,
    getCameraTrack,
    getParticipantVideoTrack,
    getVideoTrack,
    handleStream,
  };
}
