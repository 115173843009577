import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { AssetsPng } from '../../../assets';
import {
  ChatType,
  ConversationType,
  DefaultImageType,
  FileBucketURL,
} from '../../../configs';
import { ChannelContext, useAppSelector, VRIAppStateType } from '../../../core';
import { ApiResponseConversation } from '../../../models';
import {
  useFunctionalityChat,
  useFunctionalityImageView,
} from '../../../pages/hooks';

import moment from 'moment';
import { OperatorStatusType } from '../../../models/api/Operator';
import { LoadingOverlay } from '../../../components';

interface contactType {
  searchValue: string;
  setType: any;
}

export function RecentConversationList(props: contactType): JSX.Element {
  const {
    setCurrentConvo,
    setSelectedChannel,
    conversationsClient,
    setConvoType,
    messageAdded,
    setNewChatMessageCount,
  } = React.useContext(ChannelContext);
  const [recentConvos, setRecentConvos] = useState<ApiResponseConversation[]>(
    []
  );
  const [filteredRecentConvos, setFilteredRecentConvos] = useState<
    ApiResponseConversation[]
  >([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const { onGetConversations } = useFunctionalityChat();
  const currentUser = useAppSelector(
    (state: VRIAppStateType) => ({
      uid: state.auth.profile?.id,
      companyId: state.auth.profile?.companyId,
    }),
    shallowEqual
  );

  const { searchValue } = props;
  const { defaultImageLoad, imageView } = useFunctionalityImageView();

  useEffect(() => {
    if (currentUser) {
      setIsBusy(true);
      onGetConversations(setRecentConvos).then(() => {
        setIsBusy(false);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    setNewChatMessageCount(0);
  }, []);

  useEffect(() => {
    if (currentUser) {
      onGetConversations(setRecentConvos).then(() => {});
    }
  }, [messageAdded]);

  useEffect(() => {
    if (recentConvos) filterList();
  }, [recentConvos, searchValue]);

  const filterList = () => {
    let substring = searchValue.toLowerCase();

    let returningList: ApiResponseConversation[] = [];

    if (recentConvos.length > 0) {
      recentConvos?.map((contact) => {
        let lowerLabel = contact.conversationName?.toLocaleLowerCase();
        if (lowerLabel?.includes(substring)) {
          returningList.push(contact);
        }
      });
      setFilteredRecentConvos(returningList);
    }
  };

  const getConvoBySid = (id: string, convo: ApiResponseConversation) => {
    setCurrentConvo(convo);
    setConvoType(convo.conversationType);
    setIsBusy(true);
    conversationsClient
      ?.getConversationBySid(id)
      .then((conversation: any) => {
        console.log('Conversation: ', conversation);
        setSelectedChannel(conversation);
        setIsBusy(false);
      })
      .catch((error: any) => {});
  };

  return (
    <div className="recent-chat-tab group-list-height position-relative">
      <LoadingOverlay active={isBusy} animation="border">
        <div className="bg-white position-sticky sticky-top">
          <div className="col-12 text-center py-3">
            <a
              href="JavaScript:void(0)"
              className="d-flex align-items-center create-new text-decoration-none  justify-content-center my-1 newChatBtn"
              onClick={() => props.setType(ChatType.AllContact)}
            >
              <span className="ml-2 font-600">Start New Conversation</span>
            </a>
          </div>
        </div>
        <div className="col-12 recent-chat-list px-2">
          {filteredRecentConvos &&
            filteredRecentConvos.map(
              (sweetRecentConvo: ApiResponseConversation) => {
                return (
                  <div
                    className="single-chat cursor-pointer"
                    onClick={() =>
                      getConvoBySid(
                        sweetRecentConvo.conversationSid,
                        sweetRecentConvo
                      )
                    }
                  >
                    <div className="row ">
                      <div className="header-profile-image incoming-chat-image col cursor-pointer mt-1 mb-1">
                        <img
                          src={
                            sweetRecentConvo.conversationType ==
                            ConversationType.OneOnOne
                              ? `${FileBucketURL?.Root}${sweetRecentConvo?.participantId}?${imageView?.fileTimeStamp}`
                              : AssetsPng.DeFaultChatImage
                          }
                          alt=""
                          onError={(ev) => {
                            defaultImageLoad(
                              ev,
                              DefaultImageType.UserProfile,
                              '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                            );
                          }}
                          className="img-fluid"
                        />
                        {sweetRecentConvo?.participantOnlineStatus ==
                        OperatorStatusType['1'] ? (
                          <div className="opr-status-lg status-online"></div>
                        ) : sweetRecentConvo?.participantOnlineStatus ==
                          OperatorStatusType['2'] ? (
                          <div className="opr-status-lg status-offline"></div>
                        ) : sweetRecentConvo?.participantOnlineStatus ==
                          OperatorStatusType['3'] ? (
                          <div className="opr-status-lg status-incall"></div>
                        ) : sweetRecentConvo?.participantOnlineStatus ==
                          OperatorStatusType['4'] ? (
                          <div className="opr-status-lg status-busy"></div>
                        ) : null}
                      </div>
                      <div className="col col-contact-name">
                        <div className="row">
                          <div className="col-8 pl-0 ">
                            <p className="text-muted mb-0 text-truncate w-100 ">
                              {sweetRecentConvo.conversationName}
                            </p>
                            <p className="text-truncate mb-0 lh-1">
                              <small>
                                <strong>{sweetRecentConvo?.lastMessage}</strong>
                              </small>
                            </p>
                          </div>
                          <div className="col-4 text-right">
                            <p className="mb-0 font-sz-10 text-muted ">
                              {moment(
                                sweetRecentConvo.lastConversationTime
                              ).format('ll')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row border-bottom recent-chat-border"></div>
                  </div>
                );
              }
            )}

          <div className="sweet-loading"></div>
        </div>
      </LoadingOverlay>
    </div>
  );
}
