import { useContext, useEffect, useRef } from 'react';
import { videoGridConfig } from '../../../configs';
import { videoGridHelper } from '../../../helpers';
import { GuestParticipant } from '../../../components';
import { VideoGuestContext } from '../../../core';
import { twilioParticipantHelper } from '../../../helpers';

const { getBestParticipantBounds } = videoGridHelper();
const { getParticipantType } = twilioParticipantHelper();

export function GuestSpeakerView() {
  const speakerContainerRef = useRef<HTMLDivElement>(null);
  const participantRef = useRef<HTMLDivElement>(null);

  const {
    room,
    remoteParticipants,
    selectedParticipantInfo,
    setSelectedParticipantInfo,
    handleParticipantClick,
  } = useContext(VideoGuestContext);

  useEffect(() => {
    if (!room) {
      return;
    }

    if (remoteParticipants?.length) {
      setSelectedParticipantInfo({
        participant: remoteParticipants[0],
        type: 'Remote',
      });
    } else {
      setSelectedParticipantInfo({
        participant: room?.localParticipant,
        type: 'Local',
      });
    }
  }, [remoteParticipants]);

  useEffect(() => {
    const handleResize = () => {
      const participant = participantRef.current;
      if (!participant) return;

      const speakerContainerRect = speakerContainer.getBoundingClientRect();
      const { participantWidth, participantHeight } = getBestParticipantBounds({
        width: speakerContainerRect.width,
        height: speakerContainerRect.height,
        totalParticipant: 1,
        aspectRatio: videoGridConfig.aspectRatio,
        maxRows: 1,
        gridGap: 10,
      });

      participant.style.width = participantWidth + 'px';
      participant.style.height = participantHeight + 'px';
    };

    const speakerContainer = speakerContainerRef.current!;
    const resizerObserver = new ResizeObserver(handleResize);

    resizerObserver.observe(speakerContainer);

    return () => resizerObserver.disconnect();
  }, []);

  return (
    <div id="speaker-view">
      <div id="speaker-mini-container">
        {remoteParticipants?.map((participant) => (
          <GuestParticipant
            key={participant?.sid}
            participant={participant}
            participantClickHandler={() => {
              handleParticipantClick(participant, 'Remote');
            }}
            participantType="Remote"
          />
        ))}
        <GuestParticipant
          participant={room?.localParticipant!}
          key={room?.localParticipant.sid}
          participantType="Local"
          participantClickHandler={() => {
            handleParticipantClick(room?.localParticipant!, 'Local');
          }}
        />
      </div>
      <div id="speaker-container" ref={speakerContainerRef}>
        <div ref={participantRef}>
          {selectedParticipantInfo && (
            <GuestParticipant
              key={selectedParticipantInfo.participant?.sid}
              participant={selectedParticipantInfo?.participant}
              participantType={getParticipantType(
                room?.localParticipant!,
                selectedParticipantInfo.participant
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}
