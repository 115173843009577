import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { CallStatusType, CallType, Modals, SocketConstants } from "../../configs";
import { ActionUi, SocketContext, useAppSelector, VRIAppStateType, TSliceUi } from "../../core";
import { useFunctionalityCalls } from "../../pages/hooks/functionalityCalls";
import {
    useFunctionalityUser2,
    useFunctionalityUserGetNames
} from "../../pages/hooks/functionalityUser";
import { logger } from "../../utils";
import { AssetSvg } from "../../assets";

type Props = {
    show: boolean;
    handleShow: (value: boolean) => void;
    callType: CallType
};
export function ModalShareGuestLink(props: Props): JSX.Element {

    const [guestUrl, setGuestUrl] = useState("");
    const [showCopied, setShowCopied] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);
    // const currentUserId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.id)
    // const { onUpdate } =
    //     useFunctionalityCalls();


    const data = useAppSelector((state: VRIAppStateType) => ({
        callerId: state.auth.profile?.id,
        roomId: state.ui.roomId,
        chatSid: state.ui.chatSid,
        languageName: state.ui.languageName
    }));

    const guid: string = useAppSelector((state: { ui: TSliceUi }) => state.ui.guid) || "";

    const onModalClose = () => {
        props.handleShow(false);
    };

    // let urlToCopy = window.location.hostname === "localhost" ? 
    //     `${window.location.hostname}${window.location.port ? ':'+window.location.port : ''}/guest/${guid}` : 
    //     `https://${window.location.hostname}${window.location.port ? ':'+window.location.port : ''}/guest/${guid}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(guestUrl)
            .then(() => {
                setShowCopied(true); // Show the badge
                setFadeOut(false); // Reset fade-out
                setTimeout(() => setFadeOut(true), 400); // Start fading out after 1 second
                // setTimeout(() => setShowCopied(false), 2000); // Hide after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    useEffect(() => {
        console.log("the guid is: " + guid);
        const urlToCopy = window.location.hostname === "localhost" ?
            `${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/guest/${guid}` :
            `https://${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/guest/${guid}`;

        setGuestUrl(urlToCopy);

    }, [guid])

    return (
        <Modal show={props.show} onHide={() => onModalClose()} centered backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title> Share Call Link</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form>
                    <div className="form-row">
                        <div className="form-group col-md-12 d-flex flex-column">
                            <div className="d-flex py-2 flex-row justify-content-center align-items-stretch">
                                <Form.Control type="text" value={guestUrl} readOnly />
                                <Button
                                    variant="secondary"
                                    className="ml-2"
                                    onClick={copyToClipboard}
                                >
                                    <AssetSvg.CopyPaste /></Button>
                            </div>



                            <div className="d-flex flex-column align-items-end">
                                <Badge
                                    variant="light"
                                    className={`fade-out-pill ${fadeOut ? 'fade-out-pill-hidden' : ''}`}
                                    style={{ padding: "4px", fontSize:"12px", fontWeight: "500",  visibility: showCopied ? 'visible' : 'hidden' }}
                                >
                                    link copied!
                                </Badge>
                            </div>

                            {/* <div className=""><span>copied to clipboard!</span></div> */}




                        </div>
                    </div>
                </form>
            </Modal.Body>

            {/* <Modal.Footer>
                <div
                    className="form-row 
              justify-content-lg-end justify-content-xl-end justify-content-md-end justify-content-sm-center justify-content-center 
              flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
              mx-0"
                >

                </div>

            </Modal.Footer> */}
        </Modal>

    );
}
