import { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { AppConstants, AppRouteUi, CompanyTabsViewCompanyFinance, ConferenceCallSettingCode } from "../../configs";
import { CompanyTabs, CompanyTabsView } from '../../configs/TabsHeaders';
import { NavTab } from "../../features";
import { CompanyConferenceInfo, CompanyProfileInfo, CompanyProfileTopBar } from "../../features/CompanyProfile";
import { useFunctionalityCompany } from '../hooks/functionalityCompany';
import { useAppSelector, VRIAppStateType} from "../../core";

export function PageCompanyProfileView(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const companyId = AppRouteUi.CompanyProfile.Profile.useParam();
  const { companyByIdData: companyProfileData } = useFunctionalityCompany({ setLoaderProgress: setLoaderProgress, companyId: companyId, shouldCompanyGetAllInvoke: true });

  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <CompanyProfileTopBar companyName={companyProfileData?.name} />
      <div className="row mt-3">
        <div className="col-profile-left-width">
          <CompanyProfileInfo companyProfileData={companyProfileData} />
          {companyProfileData?.companySettings?.callSettings?.find(x => x.code === ConferenceCallSettingCode)?.isEnabled === true ? <CompanyConferenceInfo /> : null}
        </div>
        <div className="col-profile-right-width">
          <div className="row">
            <div className="col-12">
              <div className="profile-tab-container">
                <NavTab
                  tabList={userRole === AppConstants.UserRoles.CompanyFinance ? CompanyTabsViewCompanyFinance : CompanyTabsView}
                  activeTabName={userRole === AppConstants.UserRoles.CompanyFinance ? CompanyTabsViewCompanyFinance[0].name : CompanyTabs[0].name}
                  tabComponentProps={companyProfileData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}