import Select from "react-select";
import { shallowEqual } from "react-redux";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import moment from "moment";
import { InvoiceType } from "../../../../configs";
import { useAppSelector, VRIAppStateType } from "../../../../core";
import { ApiSchemaFormFilter, ApiSchemaColumn, ApiSchemaFilter, } from "../../../../models/api/filter";
import { useFunctionalityCompanyNames, useFunctionalityLanguageListNames, useFunctionalityOperatorNames, useFunctionalitySkillListNames, } from "../../../../pages/hooks";
import { useFunctionalityResellerNames } from "../../../../pages/hooks/functionalityReseller";
import { OptionMapper } from "../../../../utils";

type Props = {
  setFilterData: any;
  setLoaderProgress: any;
  setFilterDataChange?: any;
  invoiceType?: InvoiceType;
  pageName?: string;
  setPageIndex: Function;
};

export function InvoiceFilter(props: Props): JSX.Element {
  const { setFilterData, setFilterDataChange, invoiceType, pageName, setPageIndex } = props;
  const [companyNames, setCompanyNames] = useState<any>();
  const [resellerNames, setResellerNames] = useState<any>();
  const [operatorNames, setOperatorNames] = useState<any>();
  const [skillListNames, setSkillListName] = useState<any>();
  const [languageNameList, setLanguageNameList] = useState<any>([]);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const [selectedCompany, setSelectedCompany] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedReseller, setSelectedReseller] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedOperator, setSelectedOperator] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedLanguage, setSelectedLanguage] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedSkill, setSelectedSkill] = useState<any>([{ value: "", label: "Any" }]);

  const { onCompanyNames } = useFunctionalityCompanyNames();
  const { onOperatorNames } = useFunctionalityOperatorNames();
  const { onResellerNames } = useFunctionalityResellerNames();
  const { onLanguageListName } = useFunctionalityLanguageListNames();
  const { onSkillListName } = useFunctionalitySkillListNames();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
  } = useForm<ApiSchemaFormFilter>();
  const userRole = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.userRole,
    shallowEqual
  );
  const companyId = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.companyId,
    shallowEqual
  );

  React.useEffect(() => {
    if (invoiceType === 3 || (invoiceType === 7 && pageName !== "company")) {
      onCompanyNames(companyId ?? "").then((res) => {
        setCompanyNames([
          {
            value: "",
            label: "Any",
          },
          ...res.data,
        ]);
      });
    }
    if (invoiceType === 4 && pageName !== "reseller") {
      onResellerNames().then((res) => {
        setResellerNames([
          {
            value: "",
            label: "Any",
          },
          ...res.data,
        ]);
      });
    }
    if ((pageName !== "operator" && pageName !== "operatorFinance") && (invoiceType === 2 || invoiceType === 8)
    ) {
      onOperatorNames().then((res) => {
        setOperatorNames([
          {
            value: "",
            label: "Any",
          },
          ...res.data,
        ]);
      });
    }
    if (invoiceType === 2 && pageName === "operatorFinance") {
      onSkillListName().then((res) => {
        setSkillListName([
          {
            value: "",
            label: "Any",
          },
          ...OptionMapper.mapperFunc(res.data),
        ]);
      });
    }
    if (invoiceType === 2 && pageName === "operatorFinance") {
      onLanguageListName().then((res) => {
        setLanguageNameList([
          {
            value: "",
            label: "Any",
          },
          ...OptionMapper.mapperFunc(res.data),
        ]);
      });
    }
  }, []);

  const onFilterHandler = (data: ApiSchemaFormFilter) => {
    let columns: ApiSchemaColumn[] = [];
    let start, end;

    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (value === "" || value === undefined) continue;
        if (key == "startDate") {
          if (value) {
            start = moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          } else {
            start = "";
          }
        } else if (key == "endDate") {
          if (value) {
            end = moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          } else {
            end = "";
          }
        } else {
          let column: ApiSchemaColumn = { columnName: key, value: value };
          columns.push(column);
        }
      }
      if (start || end) {
        let dateColumn: ApiSchemaColumn = {
          columnName: "Start",
          dateQuery: { startDate: start, endDate: end },
        };
        columns.push(dateColumn);
      }
    }
    ReactDOM.unstable_batchedUpdates(() => {
      setFilterData((prevState: ApiSchemaFilter) => {
        return {
          ...prevState,
          filterQueries: columns,
        };
      });
      setFilterDataChange(Math.floor(Math.random() * 100) + 1);
      setPageIndex(1);
    });
  };

  return (
    <div className="col-12 collapse mt-3" id="jobFilterPanel">
      <div className="card card-body">
        <form onSubmit={handleSubmit(onFilterHandler)}>
          {pageName !== "operatorFinance" ? (
            <>
              <label>Invoice Date</label>
              <div className="row">
                <label htmlFor="bookingDate"></label>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Start date"
                        className="form-control"
                        selected={field.value ? new Date(field.value): null}
                        onChange={(value) => {
                          setStartDate(value!);
                          field.onChange(value)
                        }}
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        maxDate={endDate}
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                  <Controller
                    name="endDate"
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="End date"
                        className="form-control"
                        selected={field.value ? new Date(field.value): null}
                        onChange={(value) => {
                          field.onChange(value);
                          setEndDate(value ? value : undefined);
                        }}
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        minDate={startDate}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className="row">
            {invoiceType === 2 && pageName === "operatorFinance" ? (
              <>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
                  <label htmlFor="">Language</label>
                  <input className="d-none" {...register('LanguageId', {})} />
                  <Select
                    // isDisabled = {languageNameList.length > 0? false : true}
                    options={languageNameList}
                    value={selectedLanguage}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select a language"
                    onChange={(value) => {
                      setValue("LanguageId", value?.value, {
                        shouldValidate: true,
                      });
                      setSelectedLanguage([
                        { label: value?.label, value: value?.value },
                      ]);
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
                  <label htmlFor="">Skill</label>
                  <input className="d-none" {...register('SkillId', {})} />
                  <Select
                    options={skillListNames}
                    value={selectedSkill}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select a skill"
                    onChange={(value) => {
                      setValue("SkillId", value?.value, {
                        shouldValidate: true,
                      });
                      setSelectedSkill([
                        { label: value?.label, value: value?.value },
                      ]);
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="row">
            {(invoiceType === 3 ||
              (invoiceType === 7 && pageName !== "company")) && (
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                  <label htmlFor="">Company</label>
                  <input className="d-none" {...register('CompanyId', {})} />
                  <Select
                    options={companyNames}
                    value={selectedCompany}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select a company"
                    onChange={(value) => {
                      setValue("CompanyId", value?.value, {
                        shouldValidate: true,
                      });
                      setSelectedCompany([
                        { label: value?.label, value: value?.value },
                      ]);
                    }}
                  />
                </div>
              )}
          </div>
          <div className="row">
            {(invoiceType === 4 && pageName !== "reseller") && (
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                <label htmlFor="">Reseller</label>
                <input className="d-none" {...register('ResellerId', {})} />
                <Select
                  options={resellerNames}
                  value={selectedReseller}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select a reseller"
                  onChange={(value) => {
                    setValue("ResellerId", value?.value, {
                      shouldValidate: true,
                    });
                    setSelectedReseller([
                      { label: value?.label, value: value?.value },
                    ]);
                  }}
                />
              </div>
            )}
          </div>
          <div className="row">
            {((pageName !== "operatorFinance" && pageName !== "operator") && (invoiceType === 2 || invoiceType === 8)) && (
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                <label htmlFor="">Operator</label>
                <input className="d-none" {...register('OperatorId', {})} />
                <Select
                  options={operatorNames}
                  value={selectedOperator}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select a operator"
                  onChange={(value) => {
                    setValue("OperatorId", value?.value, {
                      shouldValidate: true,
                    });
                    setSelectedOperator([
                      { label: value?.label, value: value?.value },
                    ]);
                  }}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-link text-uppercase filter-reset-btn"
                onClick={() => {
                  setFilterData({ filterQueries: [] });
                  setFilterDataChange(Math.floor(Math.random() * 100) + 1);
                  setValue("startDate", undefined);
                  setValue("endDate", undefined);
                  setValue("CompanyId", "");
                  setValue("OperatorId", "");
                  setValue("ResellerId", "");
                  setValue("LanguageId", "");
                  setValue("SkillId", "");
                  setSelectedOperator([]);
                  setSelectedCompany([]);
                  setSelectedReseller([]);
                  setSelectedLanguage([]);
                  setSelectedSkill([]);
                  setStartDate(undefined);
                  setEndDate(undefined);
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary text-uppercase mx-3"
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
