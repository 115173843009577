import axios from 'axios';
import { useEffect, useRef } from 'react';
import { UserAgentUtils, logger } from '../../utils';
import packageJson from '../../../package.json';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { ActionCommon, useAppDispatch, useAppSelector,  VRIAppStateType } from '../../core';

export function useVersionCheck(skipPath: 'login' | '' = '') {
  const { pathname } = useLocation();
  const isFirstLoadRef = useRef(true);
  const dispatch = useAppDispatch();
  const isBackdatedVersion = useAppSelector(
    (state:  VRIAppStateType) => state.common.isBackdatedVersion
  );

  useEffect(() => {
    if (!isFirstLoadRef.current) {
      return;
    }
    const checkVersion = async () => {
      try {
        const url = `/app.json?date=${Date.now()}`;
        const response = await axios.get(url, {
          headers: {
            'Cache-Control': 'no-store, must-revalidate',
          },
        });
        logger({ message: 'Current App version: ', data: packageJson.version });
        logger({
          message: 'Latest App version: ',
          data: response.data.version,
        });
        if (response.data.version !== packageJson.version) {
          dispatch(ActionCommon.setIsBackdatedVersion(true));
        }
      } catch (error) {
        logger({ message: 'Error: ', data: error });
      }
    };
    if (!skipPath || (skipPath && !pathname.includes(skipPath))) {
      const oneHour = 3600000;
      checkVersion();
      const intervalId = setInterval(() => {
        checkVersion();
      }, oneHour);
      isFirstLoadRef.current = false;

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [skipPath, pathname, dispatch]);

  useEffect(() => {
    if (isBackdatedVersion) {
      let platformMessage = '';
      let endMessage = pathname.includes('login')
        ? 'proceed further'
        : 'update';
      if (UserAgentUtils.isElectron() || UserAgentUtils.isWebView()) {
        platformMessage = `Please quit the app completely an reopen to ${endMessage}`;
      } else {
        platformMessage = `Please refresh the web page to ${endMessage}`;
      }
      const toastId = toast(
        `A newer version of Gateway To Access is available. ${platformMessage}.`,
        {
          position: 'top-center',
          autoClose: false,
          bodyStyle: {
            background: 'white',
            color: '#272829',
          },
        }
      );

      return () => {
        toast.dismiss(toastId);
      };
    }
  }, [isBackdatedVersion, pathname]);

  return {
    isBackdatedVersion,
  };
}
