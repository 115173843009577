import { ApiSchemaJobList } from "../../models/api/jobs";
import useSWR from "swr";
import { ApiSchemaCallLog } from "../../models/api/callLog";
import { PlatformApi, TwilioApi } from "../../configs/appRoute";
import { useAppSelector, VRIAppStateType } from "../../core/Redux/ReduxStore";
import { AxiosAuth } from "../../core";
import React from "react";
import { ApiResponse } from "../../models/api/common";
import { ApiSchemaNotificationData } from "../../models/api/notification";
import { AppConstants } from "../../configs";
import { toast } from "react-toastify";

export function useFunctionalityNotification(pageSize: number, pageNo: number, searchText: string, unreadNotificationCount: number, notificationShown: boolean) {
  const getAllNotificationUrl = PlatformApi.Notification.GetAll.Root(pageSize, pageNo, searchText);

  const { data: apiNotificationData } = useSWR<ApiSchemaNotificationData[]>(
    (pageNo || unreadNotificationCount) && notificationShown ? getAllNotificationUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<any>>(
        getAllNotificationUrl
      )
        .then((r) => r.data.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const notificationList = React.useMemo(() => {
    const data = Array.isArray(apiNotificationData) ? apiNotificationData : null;
    return data;
  }, [apiNotificationData]);

  return {
    notificationList
  };
}
