import { Modal } from 'react-bootstrap'
import { logger } from '../../../utils'
type Props = {
  show: boolean,
  handleShow: (value: boolean) => void,
  header: string,
  onSubmit:()=>void
}
export function ModalHangupCall(props: Props) {
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
    >
       
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
             
              <div className="row">
                <div className="col-12">
                  <p>Are you sure you want to Hangup this call?</p>
                </div>
              </div>
            
           
      
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success"
            onClick={()=> {
              logger({ message: 'Pressed hangup cancel button' });
              props.handleShow(false);
            }}
          >
            DON'T HANGUP
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={()=> {
              logger({ message: 'Pressed hangup confirm button' });
              console.log("invoking callHangupHandler because call was closed from closing modal");
              props.onSubmit();
            }}
          >
            HANGUP
          </button>

        </Modal.Footer>
      
    </Modal>
  )
}
