import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { AppConstants, ProviderAccountTabs, ResellerAccountTabs, } from "../../configs";
import { useAppSelector, VRIAppStateType} from "../../core";
import { BalanceBox, NavTab } from "../../features";
import { CompanyProfileInfo } from "../../features/CompanyProfile/companyProfileInfo";
import { FinanceProfileWithBalanceModalData } from "../../models";
import { useFunctionalityFinancialBalanceWithAddress } from "../hooks/functionalityFinancialBalanceWithAddress";

export function PageMyAccount() {
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      userRole: state.auth.profile?.userRole,
      userId: state.auth.profile?.id,
      companyId: state.auth.profile?.companyId
    }),
    shallowEqual
  );
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [balanceWithAddress, setBalanceWithAddress] = useState<FinanceProfileWithBalanceModalData>()
  const { apiFinancialBalanceWithAddress } = useFunctionalityFinancialBalanceWithAddress({ companyId: data.companyId, setLoaderProgress })
  useEffect(() => {
    setBalanceWithAddress(apiFinancialBalanceWithAddress)
  }, [apiFinancialBalanceWithAddress])
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {data.userRole == AppConstants.UserRoles.Admin ||
        data.userRole == AppConstants.UserRoles.Finance ? (
        <>
          <BalanceBox balance={balanceWithAddress?.accountBalance ? balanceWithAddress.accountBalance : 0} />
          <NavTab
            tabList={ProviderAccountTabs}
            activeTabName={ProviderAccountTabs[0].name}
          />
        </>
      ) : (
        <div className="row mt-3">
          <div className="col-profile-left-width">
            <CompanyProfileInfo
              companyProfileData={
                balanceWithAddress?.companyFinanceProfileModel ?? {}
              }
            />
          </div>

          <div className="col-profile-right-width">
            <div className="row">
              <div className="col-12">
                <div className="profile-tab-container">
                  <BalanceBox balance={balanceWithAddress?.accountBalance ? balanceWithAddress.accountBalance : 0} />
                  <NavTab
                    tabList={ResellerAccountTabs}
                    activeTabName={ResellerAccountTabs[0].name}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
