import { DefaultImageType, FileBucketURL } from '../../../configs';
import { useFunctionalityImageView } from '../../../pages/hooks';

interface IUserInfoProp {
  userId: string;
  userName: string;
}

export function UserInfo({ userId, userName }: IUserInfoProp) {
  const { defaultImageLoad, imageView } = useFunctionalityImageView();

  return (
    <div className="muted-video-screen">
      <div className="position-relative h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="text-center">
            <div className="muted-video-screen_img-container profile-image">
              <img
                src={`${FileBucketURL.Root}${userId}?${imageView.fileTimeStamp}`}
                alt="User"
                onError={(event) => {
                  defaultImageLoad(event, DefaultImageType.UserProfile);
                }}
              />
            </div>
            <div className="muted-video-screen_user-details-container">
              <p className="mb-0">{userName ? userName : "Guest"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
