import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { AppConstants, ConversationType, TwilioApi } from "../../configs";
import { AxiosAuth, ChannelContext } from "../../core";
import { ApiSchemaCreateConversation } from "../../models";
import { UtilsJQuery } from "../../utils";

export function useFunctionalityChat() {
  const {
    setSelectedChannel,
    conversationsClient,
    setConvoType,
    setCurrentConvo,
    setIsBusy,
  } = React.useContext(ChannelContext);

  const onCreateConversation = useCallback(
    (
      data: ApiSchemaCreateConversation,
      name?: string,
      client?: any,
      callBackFunc?: () => void
    ) => {

      let convoClient = client ?? conversationsClient;

      setIsBusy(true);
      const btnLoading = UtilsJQuery.Ladda(".add-participant-btn");
      btnLoading.start?.();
      return AxiosAuth.post(
        TwilioApi.Conversation.CreateConversation.Root(),
        data
      )
        .then(function (response) {
          return convoClient
            ?.getConversationBySid(response.data?.data)
            .then((conversation: any) => {
              btnLoading.stop?.();
              setIsBusy(false);
              if (data.conversationType == ConversationType.Group)
                setConvoType(ConversationType.Group);
              else setConvoType(ConversationType.OneOnOne);
              setSelectedChannel(conversation);
              if (name)
                setCurrentConvo((prev: any) => ({
                  ...prev,
                  conversationName: name,
                  participantId: data?.participantIds?.[0],
                }));
              if (callBackFunc) callBackFunc();
            })
            .catch((error: any) => { });
        })
        .catch((e) => {
          console.error(e);
          btnLoading.stop?.();
        });
    },
    []
  );

  const onFetchGroupDetails = useCallback(
    (sid: string, selectGroupParticipantList: any) => {
      setIsBusy(true);
      return AxiosAuth.get(TwilioApi.Conversation.GetParticipants.Root(sid))
        .then(function (response) {
          selectGroupParticipantList(response.data.data);
          setIsBusy(false);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    []
  );

  const onFetchGroupDetailsGuest = useCallback(
    (sid: string, selectGroupParticipantList: any) => {
      setIsBusy(true);
      return AxiosAuth.get(TwilioApi.Conversation.GetParticipantsGuest.Root(sid))
        .then(function (response) {
          selectGroupParticipantList(response.data.data);
          setIsBusy(false);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    []
  );

  const onGetConversations = useCallback((setRecentConvos: any) => {
    return AxiosAuth.get(TwilioApi.Conversation.GetConversations.Root())
      .then(function (response) {
        setRecentConvos(response.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const onGetGroupConversations = useCallback((setGroupConvos: any) => {
    return AxiosAuth.get(TwilioApi.Conversation.GetGroupConversations.Root())
      .then(function (response) {
        setGroupConvos(response.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const onAddParticipants = useCallback(
    (convoId: string, participantIds: string[]) => {
      const btnLoading = UtilsJQuery.Ladda(".add-participant-btn");
      btnLoading.start?.();
      setIsBusy(true);
      return AxiosAuth.post(
        TwilioApi.Conversation.AddMultipleParticipant.Root(convoId),
        participantIds
      )
        .then(function (response) {
          btnLoading.stop?.();
          setIsBusy(false);
        })
        .catch((e) => {
          console.error(e);
          btnLoading.stop?.();
        });
    },
    []
  );

  const onRemoveParticipant = useCallback(
    (convoId: string, participantId: string, currentUserId: string) => {
      const btnLoading = UtilsJQuery.Ladda(".delete-button");
      btnLoading.start?.();
      setIsBusy(true);
      return AxiosAuth.delete(
        TwilioApi.Conversation.LeaveConversation.Root(convoId, participantId)
      )
        .then(function (response) {
          if (participantId == currentUserId) setSelectedChannel(undefined);
          btnLoading.stop?.();
          setIsBusy(false);
        })
        .catch((e) => {
          console.error(e);
          btnLoading.stop?.();
          setIsBusy(false);
        });
    },
    []
  );

  const onUpdateGroupName = useCallback((convoId: string, name: string) => {
    setIsBusy(true);
    return AxiosAuth.put(TwilioApi.Conversation.UpdateConversationName.Root(), {
      id: convoId,
      name: name,
    })
      .then(function (response) {
        setIsBusy(false);
      })
      .catch((e) => {
        console.error(e);
        setIsBusy(false);
      });
  }, []);

  const onUserDetailsById = useCallback(
    (userId: string, setUserDetails: any) => {
      return AxiosAuth.get(
        TwilioApi.Conversation.GetParticipantDetailsById.Root(userId)
      )
        .then(function (response) {
          setUserDetails(response.data.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    []
  );

  return {
    onCreateConversation,
    onGetConversations,
    onGetGroupConversations,
    onFetchGroupDetails,
    onFetchGroupDetailsGuest,
    onRemoveParticipant,
    onAddParticipants,
    onUpdateGroupName,
    onUserDetailsById,
  };
}
