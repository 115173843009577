import React from 'react';

export function GuestCallEndedPage(): JSX.Element  {
    console.log("guestcallended has loaded");
  return (
    <div className="site-call-page ">
    <div className="site-call-page-wrapper">
        <div className="container-fluid h-100">
            <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
                <div className="col-12 text-center d-flex flex-column align-items-center justify-content-center pt-3">
                    <h4 className="text-white">Call Has Ended.</h4>
                </div>
            </div>
        </div>
    </div>
</div>
  );
};

