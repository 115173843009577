import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AssetSvg } from "../../../assets";
import { Modals } from "../../../configs";
import { BootstrapUtils } from "../../../utils";
type Props = {
  handleShow: (value: boolean) => void;
};
export function ShareGuestLinkButton(props:Props) {
  return (
      <button

        className="btn btn-opaque conference-control-btn"
        data-toggle="modal"
        aria-pressed="false"
        data-target={BootstrapUtils.GetSelectorById(Modals.ShareGuestLink)}
          onClick={() => props.handleShow(true)}
      >
        <AssetSvg.LinkShare /></button>
  );
}
