import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { AppConstants, BookingStatusType, topLoaderProgress } from "../../configs";
import { JobEditInfo } from "../../features";
import { JobHistory } from "../../features/Jobs/jobHistory";
import { PlatformType } from "../../models";
import { JobBills } from "../../features/Jobs/jobBills";
import { useAppSelector, VRIAppStateType } from "../../core";
import LoadingBar from "react-top-loading-bar";
import { useFunctionalityJobDetailsById } from "../hooks";

export function PageJobEdit(): JSX.Element {
  const { state: jobId } = useLocation<any>();
  const [modalKey, setModalKey] = useState(Date.now() + "");
  const [loaderProgress, setLoaderProgress] = useState<any>(0)
  const [jobData, setJobData] = useState<any>()
  const [sweetChangeProp, setSweetChangeProp] = useState<number>(0)
  const { LoadDetailsByJobId } = useFunctionalityJobDetailsById({ setLoaderProgress });
  const data = useAppSelector((state: VRIAppStateType) => ({
    userRole: state.auth.profile?.userRole,
  }));

  useEffect(() => {
    if (jobId) {
      LoadDetailsByJobId(jobId.jobId).then((data) => {
        if (setLoaderProgress)
          setLoaderProgress(topLoaderProgress.complete);
        setJobData(data)
      });
    }
  }, [sweetChangeProp])

  useEffect(() => { }, [jobData, sweetChangeProp])
  const history = useHistory();

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className="my-3 font-weight-light">Booking</h4>
      <button
        className="btn btn-primary text-uppercase mb-4"
        onClick={() => history.goBack()}
      >
        back
      </button>
      {jobData ?
        <JobEditInfo key={modalKey} jobData={jobData} setSweetChangeProp={setSweetChangeProp} />
        :
        <div className="py-5 text-muted text-center">
          <h1 className="font-weight-light">{"Loading"}</h1>
        </div>
      }
      {(data.userRole === AppConstants.UserRoles.Admin ||
        data.userRole === AppConstants.UserRoles.Finance ||
        data.userRole === AppConstants.UserRoles.ResellerAdmin ||
        data.userRole === AppConstants.UserRoles.ResellerFinance) && jobData &&
        (jobData?.serviceType === PlatformType.OnSite ||
          jobData?.serviceType === PlatformType.OPI ||
          jobData?.serviceType === PlatformType.VRI ||
          jobData?.status === BookingStatusType.Cancelled) &&
        (jobData?.status === BookingStatusType.Confirmed ||
          jobData?.status === BookingStatusType.Cancelled ||
          jobData?.status === BookingStatusType.Closed ||
          jobData?.status === BookingStatusType.Billed) ? (
        <>
          <JobBills key={modalKey} jobData={jobData} setSweetChangeProp={setSweetChangeProp} />
        </>

      ) : (
        <>
          {/* <h1> second: {jobData?.status}</h1> */}
        </>
      )}

      {(data.userRole === AppConstants.UserRoles.Admin ||
        data.userRole === AppConstants.UserRoles.Finance ||
        data.userRole === AppConstants.UserRoles.ResellerAdmin ||
        data.userRole === AppConstants.UserRoles.ResellerFinance) && (
          <JobHistory bookingId={jobData?.id} key={modalKey} sweetChangeProp={sweetChangeProp} />
        )}
    </>
  );
}
