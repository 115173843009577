import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaTinData, WithPagination } from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";

type Props = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
};

let pagedTINUrl: string;

export function useFunctionalityPagedTin(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress } = props;
  // const [pagedTinListCount, setPagedTinListCount] = useState<number>(0);

  pagedTINUrl = PlatformApi.Tin.PagedGetAll(pageSize, pageNo, searchText);

  const { data: apiPagedTinList } = useSWR<{
    data: ApiSchemaTinData[];
    count: number;
  }>(
    pagedTINUrl,
    async () => {
      setLoaderProgress(topLoaderProgress.start);

      try {
        const response = await AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaTinData[]>>>(
          pagedTINUrl
        );
        setLoaderProgress(topLoaderProgress.complete);

        return {
          data: response.data.data.data,
          count: response.data.data.count,
        };
      } catch (error) {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(error);
        throw error;
      };
    }
  );

  const pagedTinList = React.useMemo(() => {
    // const data = Array.isArray(apiPagedTinList) ? apiPagedTinList : [];
    return apiPagedTinList?.data ?? [];
  }, [apiPagedTinList]);

  const pagedTinListCount = apiPagedTinList?.count ?? 0;


  return {
    pagedTinList,
    pagedTinListCount,
  };
}

export function useFunctionalityTin() {
  const skillUrl = PlatformApi.Tin.GetAll();

  const { data: apiTinList } = useSWR<ApiSchemaTinData[]>(skillUrl, () =>
    AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaTinData[]>>>(skillUrl)
      .then((r) => r.data.data?.data)
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const optionMappedTinList = React.useMemo(() => {
    const data = Array.isArray(apiTinList)
      ? OptionMapper.mapperFunc(apiTinList)
      : [];
    return data;
  }, [apiTinList]);

  return {
    optionMappedTinList,
  };
}
