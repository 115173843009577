import React, { useEffect, useState } from "react";
import { Alert } from 'react-bootstrap';
import { BottomGuestBar, GuestChat, GuestRoom, TopGuestBar } from '../../components';
import { AppRouteUi, CallStatusType, CallType } from '../../configs';
import { VideoGuestContext, useAppSelector, VRIAppStateType } from '../../core';
import { ModalHangupCall, ModalVideoConferenceLink } from '../../features';
import { searchInCurrentURL } from '../../utils';
import { useGuestCall } from "../../pages/hooks";
import { logger } from '../../utils';

interface ChildComponentProps {
  roomId: string;
}

export function VideoGuestCall({ roomId }: ChildComponentProps) {

  // logger({ message: "VideoGuestCall has been visited" });


  const callType: CallType.VideoConference | CallType.Video = CallType.Video;
  // const isOperatorRequired = isVideoConferenceCall
  //   ? searchInCurrentURL('/true')
  //   : false;


  const {
    appData,
    room,
    VideoGuestContextValue,
    callHangupHandler,
    setShowHangupModal,
    showChat,
    setShowChat,
    // callOperator,
    showHangupModal,
    countdownSeconds,
    isCountdownRunning,
  } = useGuestCall({
    roomId,
    callType,
    // isOperatorRequired,
    // isVideoConferenceCall,
    // operatorId,
  });

  // if (!webToken) {
  //   return <></>;
  // }


  return (

    <VideoGuestContext.Provider value={VideoGuestContextValue}>
      <div className="d-flex" id="conference-page-wrapper">
        <Alert
          show={isCountdownRunning}
          key="warning"
          variant="warning"
          className="site-alert my-1"
        >
          Waiting for another participant. Call will auto end within{' '}
          {countdownSeconds} seconds.
        </Alert>
        <div id="conference-wrapper">
          {appData.callStatus?.current ===
            CallStatusType.PreparingCallSummary ? (
            <div className="col-12 text-center text-white p-3">
              <h3 className="m-0">Preparing Call Summary. Please wait...</h3>
            </div>
          ) : (
            <>
              <TopGuestBar />
              {room && <GuestRoom callHangupHandler={callHangupHandler} />}
              <BottomGuestBar
                setShowHangupModal={setShowHangupModal}
                showChat={showChat}
                setShowChat={setShowChat}
                // isOperatorRequired={isOperatorRequired}
                // addParticipant={appData.addParticipant!}
              // operatorId={operatorId}
              // callOperator={callOperator}
              // userRole={appData.userRole!}
              />
            </>
          )}
        </div>

        {appData.chatSid && (
          <GuestChat
            chatSid={appData.chatSid}
            showChat={showChat}
            setShowChat={setShowChat}
          />
        )}
        <ModalHangupCall
          handleShow={setShowHangupModal}
          onSubmit={callHangupHandler}
          show={showHangupModal}
          header={'Hangup Call'}
        />

      </div>
    </VideoGuestContext.Provider>
  );
}
