import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ChatUI } from '../../../features';
import { ChannelContext } from '../../../core';

interface IChatProps {
  chatSid: string;
  showChat: boolean;
  setShowChat: Dispatch<SetStateAction<boolean>>;
}

export function Chat({ chatSid, setShowChat, showChat }: IChatProps) {
  const {
    conversationsClient,
    selectedChannel,
    setSelectedChannel,
    setCurrentConvo,
    setVideoChatMessageCount,
  } = useContext(ChannelContext);

  useEffect(() => {
    const setChannel = async () => {

      try {
        console.log("CCCC entered into async - waiting to get value of conversationsClient, chatSid: ", chatSid);
        const conversation = await conversationsClient?.getConversationBySid(
          chatSid
        );
        console.log("CCCC setselectedchannel - conversation:", conversation);

        setSelectedChannel(conversation);
      } catch (error) {
        console.error("Error fetching conversation by SID:", error);
      }
    };

    if (chatSid && conversationsClient) {
      setChannel();
    }

    return () => {
      setCurrentConvo(undefined);
      setSelectedChannel(undefined);
    };
  }, [conversationsClient, chatSid, setSelectedChannel, setCurrentConvo]);

  useEffect(() => {
    if (selectedChannel) {
      selectedChannel?.on('messageAdded', () => {
        setVideoChatMessageCount((count: number) => count + 1);
      });
    }
  }, [selectedChannel, setVideoChatMessageCount]);

  return (
    <div
      className={`${showChat ? 'd-block bg-white slide-in' : 'd-none'}`}
      id="conference-chat-wrapper"
    >
      <div className="site-chat-inner">
        <div className="container-fluid d-block h-100">
          <div className="row bg-chat-backgroundColor h-100">
            <div className="w-100 h-100">
              <div className="col-12">
                <div
                  className="row align-items-center"
                  onClick={() => {
                    setShowChat(false);
                    setVideoChatMessageCount(0);
                  }}
                >
                  <button className="btn btn-link pb-0 pt-1">
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="text-muted mr-1 ml-1"
                    />
                  </button>
                </div>
                <ChatUI chatUiOpen={showChat} arrowclickHandler={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
