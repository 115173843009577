import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AppConstants, AppRouteUi } from '../../../configs';
import { useAppSelector, VRIAppStateType } from '../../../core';
import { logger } from '../../../utils';

type NetworkConnectionProps = {
  hangupHandler: Function;
  callDisconnectTime?: number;
};

export function NetworkConnection({
  hangupHandler,
  callDisconnectTime,
}: NetworkConnectionProps) {
  const appData = useAppSelector((state: VRIAppStateType) => ({
    userRole: state.auth.profile?.userRole,
    twilioDeviceStatus: state.common.twilioDeviceStatus,
  }));
  const homePath = useMemo(() => {
    if (appData.userRole === AppConstants.UserRoles.Operator) {
      return AppRouteUi.CallLog.Root;
    } else if (appData.userRole === AppConstants.UserRoles.Consumer) {
      return AppRouteUi.Consumer.Root;
    } else {
      return AppRouteUi.Home.Root;
    }
  }, [appData.userRole]);
  const history = useHistory();

  useEffect(() => {
    console.log("appData.twilioDevideStatus: ", appData.twilioDeviceStatus);
    let timeoutId: number | null = null;
    if (appData.twilioDeviceStatus !== 'online') {
      logger({
        message: 'Starting timer for ending call',
      });
      timeoutId = window.setTimeout(() => {
        if (appData.twilioDeviceStatus === 'offline') {
          logger({
            message: 'Ending call because of twilio device offline status',
          });
          console.log("invoking callHangupHandler (renamed) because of twilio device offline status");

          hangupHandler();
          history.push(homePath);
        }
      }, callDisconnectTime || 35000);
    }
    if (appData.twilioDeviceStatus !== 'offline') {
      timeoutId && clearTimeout(timeoutId);
    }
    return () => {
      if (timeoutId) {
        logger({
          message: 'Clearing timer for ending call',
        });
        clearTimeout(timeoutId);
      }
    };
  }, [appData.twilioDeviceStatus, history, homePath, callDisconnectTime]);

  return (
    <div
      className={`offline-device ${appData.twilioDeviceStatus === 'offline' ? 'd-block' : 'd-none'
        }`}
    >
      <div className="offline-message-wrapper">
        <p>You lost your network connection. Trying to reconnect.</p>
        <div className="spinner-border text-primary mt-2">
          <span className="visually-hidden" />
        </div>
      </div>
    </div>
  );
}
