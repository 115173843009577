import { CallStatusType } from '../configs';

type Props = {
  callStatus?: CallStatusType;
};
export function controlBrowserBackAndReloadButton() {
  const controlBrowserBackButton = () => {
    try {
      //console.trace("Redirecting:");
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        //console.trace("Redirecting:");
        window.history.pushState(null, document.title, window.location.href);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const controlBrowserReloadButton = ({ callStatus }: Props) => {
    if (callStatus === CallStatusType.Ringing) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  };
  return {
    controlBrowserBackButton,
    controlBrowserReloadButton,
  };
}
