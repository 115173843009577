import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingBar from "react-top-loading-bar";
import { AppRouteUi, ConversationType, ExportTableType, PaginationSettings, PlatformApi, TinModalHeader, } from "../../configs";
import { ChannelContext, useAppSelector, VRIAppStateType } from "../../core";
import { AdminPageTemplate, ColumnToolTip, ConfirmationModal, ModalEditUserStatus, ModalUserResetPassword, ModalUserTin, Searchbar, } from "../../features";
import { ModalConfirmDelete } from "../../features";
import { ModalAddOperator } from "../../features/modals/operatorAddModal";
import { ApiSchemaOperatorResponseData, OperatorStatusType } from "../../models/api/Operator";
import { ApiSchemaChangeStatus, ApiSchemaUpdatePassword, UserStatusType, } from "../../models/api/userlist";
import { getKeyByValue } from "../../utils";
import { useFunctionalityPagedOperator, useFunctionalityOperator, } from "../hooks/functionalityOpeartor";
import Star from "../../components/common/rating/Star";
import ReactDOM from "react-dom";
import { ApiSchemaFilter } from "../../models/api/filter";
import { ApiSchemaCreateConversation, ColumnSelectionsModel, } from "../../models";
import { OperatorColumns } from "../../configs/AppJson/OperatorColumns";
import { OperatorFilter } from "./OperatorFilter";
import { TableColumnExtend } from "../../models/tableLibrary";
import { useFunctionalityChat } from "../hooks";
import { AssetSvg } from "../../assets";
import { faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { logger } from "../../utils";

export function PageOperator() {
  const companyId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.companyId);
  const callCenterId = useAppSelector(
    (state: VRIAppStateType) => state.auth.menuSettings?.callCenterSettings.callCenterId
  );
  const history = useHistory();
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [createModalShow, setCreateModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [editStatusModalShow, setEditStatusModalShow] = React.useState(false);
  const [resetPasswordModalShow, setResetPasswordModalShow] = React.useState(false);
  const [tinModalShow, setTinModalShow] = React.useState(false);
  const [showMakeOfflineModal, setShowMakeOfflineModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");
  const [userStatus, setUserStatus] = useState<number>(0);
  const [userTinModalHeader, setUserTinModalHeader] = useState<string>("");
  const [userTin, setUserTin] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  let testuser: ApiSchemaFilter = {
    filterQueries: [],
  };

  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<ApiSchemaFilter>(testuser);
  const [filterDataChange, setFilterDataChange] = useState<number>(0);
  const { onCreateConversation } = useFunctionalityChat();
  const [chatPanelToggle, setChatPanelToggle] = React.useState(false);
  const { conversationsClient } = React.useContext(ChannelContext);
  const { selectedChannel, chatType, setChatType } = React.useContext(ChannelContext);
  const [columnSelection, setColumnSelection] = useState<ColumnSelectionsModel[]>([]);
  const [columnSelectionModalShow, setColumnSelectionModalShow] = React.useState(false);

  const getChannel = (userName: string, selectedUserId: string) => {
    setIsBusy(true);
    setChatPanelToggle(true);
    const data: ApiSchemaCreateConversation = {
      conversationName: userName,
      participantIds: [selectedUserId],
      conversationType: ConversationType.OneOnOne,
    };
    setChatType(10);
    onCreateConversation(data, userName, conversationsClient, () => {
      setIsBusy(false);
    });
  };

  useEffect(() => { setColumnSelection(OperatorColumns); }, []);
  useEffect(() => { }, [chatType, selectedChannel]);

  const { operatorList, pagedListCount, totalOnline, totalInCall, totalOperator, pagedOperatorUrl } = useFunctionalityPagedOperator({
    callCenterId: callCenterId || "",
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    setLoaderProgress,
    filterData: filterData,
    filterDataChange: filterDataChange,
  });


  useEffect(() => {
    logger({ message: '__________________________________from OperatorPage:'});
    console.log("given pageSize is: ", pageSize);
    console.log("given pageNo is: ", pageIndex);
    console.log("retrieved operatorList is: ", operatorList);
    console.log("retrieved pagedListCount: ", pagedListCount);
  
  }, [pageSize, pageIndex, operatorList, pagedListCount])

  const { setIsBusy } = React.useContext(ChannelContext);
  const { onUpdateTin, onChangeStatus, onUpdatePassword, userTinData, onDeleteUser, onForceOffline } = useFunctionalityOperator({
    callCenterId: callCenterId || "",
    companyId: companyId || "",
  });

  const callLogInfoBoxData: [string, string][] = [
    ["Total Online", totalOnline.toString()],
    ["Total Incall", totalInCall.toString()],
    ["Total Operators", totalOperator.toString()],
  ];
  useEffect(() => {
    if (pagedListCount) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedListCount]);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const onChangeUserStatus = (status: number) => {
    // console.log("statuschecklocal - OperatorPage A, setting local userStatus to:", status);
    setUserStatus(status);
  };

  const onUpdateUserStatus = (status: number) => {
    // console.log("statuschecklocal - OperatorPage B, setting local userStatus to:", status);
    setUserStatus(status);
    const statusModel: ApiSchemaChangeStatus = {
      userId: userId,
      status: status,
    };
    onChangeStatus(statusModel)
      .then(() => setEditStatusModalShow(false))
      .catch((c) => console.log(c));
  };
  const onChangePassword = (form: ApiSchemaUpdatePassword) => {
    onUpdatePassword({ ...form, userId: userId })
      .then(() => setResetPasswordModalShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };

  const onTinClickHandler = (userId: string) => {
    userTinData(userId)
      .then((res) => {
        setUserTin(res || "");
        const modalHeader = res
          ? TinModalHeader.userTin
          : TinModalHeader.createTin;
        setUserTinModalHeader(modalHeader);
        setTinModalShow(true);
      })
      .catch();
  };

  const onChangeTin = (tin: string) => {
    onUpdateTin({ tin: tin, userId: userId })
      .then(() => setTinModalShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };

  const onDeleteHandler = () => {
    onDeleteUser(userId)
      .then(() => {
        setDeleteModalShow(false);
      })
      .catch((e) => console.error(e));
  };

  const handleConfirmation = async () => {
    const data = await onForceOffline(userId);
    if (data?.isSuccess) {
      toast.info(data.message);
    }
    setShowMakeOfflineModal(false);
  };

  const handleMakeOfflineVisibility = () => {
    setShowMakeOfflineModal(!showMakeOfflineModal);
  };

  const operatorTableColumns: TableColumnExtend<ApiSchemaOperatorResponseData>[] =
    [
      {
        name: (
          <ColumnToolTip
            data={
              columnSelection?.find((eachColumn) => eachColumn.propertyName === "vriAvailabilityStatusType")?.name
            }
          />
        ),
        width: "100px",
        selector: (row: ApiSchemaOperatorResponseData) => {
          return (
            <>
              <span
                className={`badge p-1 rounded-0 text-uppercase text-white 
								${row.vriAvailabilityStatusType === OperatorStatusType.Online
                    ? `bg-success`
                    : row.vriAvailabilityStatusType ===
                      OperatorStatusType.InCall
                      ? `bg-danger`
                      : row.vriAvailabilityStatusType === OperatorStatusType.Busy
                        ? `bg-orange`
                        : row.vriAvailabilityStatusType ===
                          OperatorStatusType.Disabled ||
                          row.vriAvailabilityStatusType ===
                          OperatorStatusType.Offline
                          ? `bg-secondary`
                          : ""
                  }`}
              >
                {getKeyByValue(
                  OperatorStatusType,
                  row.vriAvailabilityStatusType
                )}
              </span>
            </>
          );
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "vriAvailabilityStatusType")?.isEnabled ? false : true,
        sortable: true,
        sortField: "VRI",
      },
      {
        name: (
          <ColumnToolTip
            data={
              columnSelection?.find(
                (eachColumn) => eachColumn.propertyName === "opiAvailabilityStatusType")?.name
            }
          />
        ),
        width: "100px",
        selector: (row: ApiSchemaOperatorResponseData) => {
          return (
            <>
              <span
                className={`badge p-1 rounded-0 text-uppercase text-white 
							${row.opiAvailabilityStatusType === OperatorStatusType.Online
                    ? `bg-success`
                    : row.opiAvailabilityStatusType === OperatorStatusType.InCall
                      ? `bg-danger`
                      : row.opiAvailabilityStatusType === OperatorStatusType.Busy
                        ? `bg-orange`
                        : row.opiAvailabilityStatusType ===
                          OperatorStatusType.Disabled ||
                          row.opiAvailabilityStatusType === OperatorStatusType.Offline
                          ? `bg-secondary`
                          : ""
                  }`}
              >
                {getKeyByValue(OperatorStatusType, row.opiAvailabilityStatusType)}
              </span>
            </>
          );
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "opiAvailabilityStatusType")?.isEnabled ? false : true,
        sortable: true,
        sortField: "OPI",
      },
      {
        name: (
          <ColumnToolTip
            data={columnSelection?.find((eachColumn) => eachColumn.propertyName === "name")?.name}
          />
        ),
        width: "160px",
        selector: (row: ApiSchemaOperatorResponseData) => {
          return <ColumnToolTip data={row.name} />;
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "name")?.isEnabled ? false : true,
        sortable: true,
        sortField: "User.Name",
      },

      {
        name: (
          <ColumnToolTip
            data={columnSelection?.find((eachColumn) => eachColumn.propertyName === "ratingScore")?.name}
          />
        ),
        width: "160px",
        selector: (row: ApiSchemaOperatorResponseData) => {
          return <Star rating={row.ratingScore ?? 5} view={true} />;
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "ratingScore")?.isEnabled ? false : true,
        sortable: true,
        sortField: "RatingScore",
      },
      {
        name: (
          <ColumnToolTip
            data={columnSelection?.find((eachColumn) => eachColumn.propertyName === "email")?.name}
          />
        ),
        selector: (row: ApiSchemaOperatorResponseData) => {
          return <ColumnToolTip data={row.email} />;
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "email")?.isEnabled ? false : true,
        sortable: true,
        sortField: "User.Email",
      },
      {
        name: (
          <ColumnToolTip
            data={
              columnSelection?.find((eachColumn) => eachColumn.propertyName === "operatorLanguages")?.name
            }
          />
        ),
        selector: (row: ApiSchemaOperatorResponseData) => {
          return <ColumnToolTip data={row.operatorLanguages}></ColumnToolTip>;
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "operatorLanguages")?.isEnabled ? false
          : true,
      },
      {
        name: (
          <ColumnToolTip
            data={
              columnSelection?.find((eachColumn) => eachColumn.propertyName === "operatorSkills")?.name
            }
          />
        ),
        width: "200px",
        selector: (row: ApiSchemaOperatorResponseData) => {
          return <ColumnToolTip data={row.operatorSkills} />;
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "operatorSkills")?.isEnabled ? false : true,
      },
      {
        name: (
          <ColumnToolTip
            data={
              columnSelection?.find((eachColumn) => eachColumn.propertyName === "userStatusType")?.name
            }
          />
        ),
        width: "140px",
        selector: (row: ApiSchemaOperatorResponseData) => {
          return (
            <>
              <span
                className={`badge p-1 rounded-0 text-uppercase text-white ${row.userStatusType === UserStatusType.Enabled
                  ? `bg-success`
                  : row.userStatusType === UserStatusType.Disabled
                    ? `bg-danger`
                    : row.userStatusType === UserStatusType.Pending
                      ? `bg-orange` : ""}`}
              >
                {getKeyByValue(UserStatusType, row.userStatusType)}
              </span>{" "}
              <div
                className="btn svg-color"
                title="Edit"
                onClick={() => {
                  setUserId(row.userId);
                  // console.log("statuschecklocal - OperatorPage C, setting local userStatus to:", row.userStatusType);
                  setUserStatus(row.userStatusType);
                  setEditStatusModalShow(true);
                }}
              >
                <span >
                  <AssetSvg.Edit />
                </span>
              </div>
            </>
          );
        },
        omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "userStatusType")?.isEnabled ? false : true,
        sortable: true,
        sortField: "User.Status",
      },
      {
        name: <ColumnToolTip data={"Actions"} />,
        allowOverflow: true,
        selector: (row: ApiSchemaOperatorResponseData) => {
          return (
            <>
              <Link
                to={{
                  pathname: AppRouteUi.Operator.Profile.Load(row.id),
                  state: {
                    userId: row.userId,
                    avgRating: row.ratingScore,
                    ratingCount: row.ratingCount,
                  },
                }}
                className="btn svg-color pl-0"
                title="View"
              >
                <span >
                  <AssetSvg.ViewProfile />
                </span>
              </Link>

              <div
                className="btn svg-color"
                title="TIN"
                onClick={() => {
                  onTinClickHandler(row.userId);
                  setUserId(row.userId);
                }}
              >
                <span >
                  <AssetSvg.Tin />
                </span>
              </div>

              <div
                className="btn svg-color"
                title="Change Password"
                onClick={() => {
                  setResetPasswordModalShow(true);
                  setUserId(row.userId);
                }}
              >
                <span >
                  <AssetSvg.ChangePassword />
                </span>
              </div>

              <div
                className="btn svg-color"
                title="Delete"
                onClick={() => {
                  setDeleteModalShow(true);
                  setUserId(row.userId);
                }}
              >
                <span >
                  <AssetSvg.Delete />
                </span>
              </div>

              <div
                className="btn svg-color"
                title="Chat"
                data-toggle="collapse"
                data-target="#site-chat-panel"
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={() => {
                  getChannel(row.name, row.userId);
                }}
              >
                <span >
                  <AssetSvg.ChatView />
                </span>
              </div>
              <div
                className="btn svg-color"
                title="Make Offline"
                onClick={() => {
                  setUserId(row.userId);
                  handleMakeOfflineVisibility();
                }}
              >
                <span >
                  <FontAwesomeIcon
                    icon={faUserSlash}
                    className="text-muted mr-1 ml-1"
                  />
                </span>
              </div>
            </>
          );
        },
      },
    ];

  return (
    <div className="">
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className="my-3 font-weight-light">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item ">
              <a
                href="#"
                onClick={() => history.goBack()}
                className="font-weight-light"
              >
                {"Call Center"}
              </a>
            </li>
            <li className="breadcrumb-item font-weight-light active" aria-current="page">
              {"Operator"}
            </li>
          </ol>
        </nav>
      </h4>
      <div className="row">
        <div className="col-12 d-flex">
          <div className="flex-shrink-0">
            <button
              className="btn btn-primary d-flex align-items-center mr-2"
              onClick={() => {
                setCreateModalShow?.(true);
              }}
            >
              <span className="flex-shrink-0 mr-2">
                <AssetSvg.Plus />
              </span>
              <span>Add Operator</span>
            </button>
          </div>
          <div className="w-100">
            <Searchbar
              showFilter={true}
              filterPanel={"jobFilterPanel"}
              showExportOptions={true}
              searchText={searchText}
              handleSearch={handleSearch}
              mutateUrl={pagedOperatorUrl}
              showAutoRefresh={true}
              columnSelectionModalShow={columnSelectionModalShow}
              setColumnSelectionModalShow={setColumnSelectionModalShow}
              columnSelection={columnSelection}
              setColumnSelection={setColumnSelection}
              filterData={filterData}
              tableType={ExportTableType.Operator}
              downloadedFileName={"operator Report"}
              callCenterId={callCenterId}
              setLoaderProgress={setLoaderProgress}
              setIsShowCommonFilter={setIsShowCommonFilter}
            />
          </div>
        </div>
        {isShowCommonFilter ? (
          <OperatorFilter
            setFilterData={setFilterData}
            setLoaderProgress={setLoaderProgress}
            setFilterDataChange={setFilterDataChange}
            setPageIndex={setPageIndex}
          />
        ) : null}
      </div>

      <AdminPageTemplate
        loaderProgress={loaderProgress}
        tableHeaders={operatorTableColumns}
        tableData={operatorList || []}
        setCreateModalShow={setCreateModalShow}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        callLogInfoBoxData={callLogInfoBoxData}
        pagedOperatorUrl={pagedOperatorUrl}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        hideSearchBar={true}
        setFilterDataForAnyTable={setFilterData}
        setFilterDataChangeForAnyTable={setFilterDataChange}
      />
      <ModalAddOperator
        show={createModalShow}
        handleShow={setCreateModalShow}
        companyId={companyId || ""}
        callCenterId={callCenterId || ""}
      />
      <ModalEditUserStatus
        show={editStatusModalShow}
        handleShow={setEditStatusModalShow}
        onChangeHandler={onChangeUserStatus}
        checkedItem={userStatus}
        onSaveHandler={onUpdateUserStatus}
      />
      <ModalUserTin
        show={tinModalShow}
        handleShow={setTinModalShow}
        modalHeader={userTinModalHeader}
        userTin={userTin}
        onSaveHandler={onChangeTin}
      />
      <ModalUserResetPassword
        show={resetPasswordModalShow}
        handleShow={setResetPasswordModalShow}
        onSaveHandler={onChangePassword}
      />
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
      <ConfirmationModal
        message="make operator offline"
        show={showMakeOfflineModal}
        handleShow={handleMakeOfflineVisibility}
        handleConfirmation={handleConfirmation}
      />
    </div>
  );
}
