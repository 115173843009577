import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { FormErrorMessage } from "../../components";
import { useAppSelector, VRIAppStateType} from "../../core";
import {
  ApiSchemaFaceToFace,
  ApiSchemaOperatorLanguageRate,
  ApiSchemaUpdateOperatorLanguageRate,
} from "../../models";
import { useFunctionalityLanguage } from "../../pages/hooks";
import { OperatorLanguageRate } from "../../models/api/operatorProfile";
import { AppRouteUi, PlatformType } from "../../configs";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  onSaveHandler: any;
  header: string;
  submitType: string;
  onUpdateHandler: any;
  dataToEdit: ApiSchemaOperatorLanguageRate;
  languageRates: OperatorLanguageRate[];
  operatorLanguageList: any;
};

export function ModalLanguageAndRate(props: Props): JSX.Element {
  const {
    show,
    handleShow,
    onSaveHandler,
    header,
    submitType,
    onUpdateHandler,
    dataToEdit,
    languageRates,
    operatorLanguageList,
  } = props;
  const { languageList } = useFunctionalityLanguage({
    plaformType: PlatformType.All,
    shouldLanguageGetAllInvoke: show,
  });
  const { register, handleSubmit, formState: { errors }, setValue } =
    useForm<ApiSchemaUpdateOperatorLanguageRate>();
  const operatorId = AppRouteUi.Operator.Profile.useParam();

  const {
    numberFieldValidation,
    handleCopyPasteInvalidValue,
    handleLimitDecimalPlaces,
  } = useFunctionalityInputChecker();

  const onSubmitLanguageRate = (data: ApiSchemaUpdateOperatorLanguageRate, e: any) => {
    const _languageRates = languageRates.map((arrayElement, index) => {
      arrayElement = {
        ...arrayElement,
        amount: data?.operatorLanguageRates
          ? Number(data?.operatorLanguageRates[index]?.amount)
          : 0,
      };
      return arrayElement;
    });

    let languageRateModel: ApiSchemaUpdateOperatorLanguageRate = {
      operatorId: operatorId,
      languageId: data?.languageId,
      operatorLanguageRates: _languageRates,
    };
    submitType === "Save"
      ? onSaveHandler(languageRateModel)
      : onUpdateHandler(languageRateModel);
  };

  let new_languageList: any[] = [];
  languageList.forEach((language) => {
    var f = 0;
    operatorLanguageList.forEach((operatorLanguage: any) => {
      if (language.value === operatorLanguage.languageId) {
        f = 1;
      }
    });
    if (f == 0) {
      new_languageList.push(language);
    }
  });

  return (
    <Modal show={show} onHide={() => handleShow(false)} centered>
              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmitLanguageRate)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label
                  htmlFor="languageSelection"
                  className="position-relative required"
                >
                  Languages
                </label>
                <input
                  type="text"
                  {...register('languageId', { required: true })}
                  defaultValue={dataToEdit ? dataToEdit?.languageId : undefined}
                  className="d-none" />

                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={new_languageList}
                  placeholder={"Select a language..."}
                  defaultValue={
                    dataToEdit
                      ? languageList?.find(
                        (x) => x.value === dataToEdit?.languageId
                      )
                      : undefined
                  }
                  onChange={(option) => {
                    setValue("languageId", option?.value);
                  }}
                />
                {errors.languageId && <FormErrorMessage message="required" />}
              </div>
              {languageRates?.map((rate: OperatorLanguageRate, index) => (
                <div className="form-group ">
                  <label htmlFor="" className="position-relative required">
                    {rate?.platformType
                      ? PlatformType[rate?.platformType]
                      : null}{" "}
                    Rate
                  </label>
                  <div className="input-group ">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="flat-rate">
                        $
                      </span>
                    </div>
                    <input
                      type="float"
                      className="form-control"
                      //@ts-ignore
                      {...register(`operatorLanguageRates[${index}].amount`, {
                        required: true,
                        validate: handleCopyPasteInvalidValue,
                      })}
                      aria-describedby="flatRate"
                      defaultValue={dataToEdit ? rate?.amount : undefined}
                      placeholder={`${rate?.platformType
                        ? PlatformType[rate?.platformType]
                        : null
                        } Rate`}
                      onKeyPress={(event) => {
                        if (!/[0-9]|\./.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyDown={(evt) => numberFieldValidation(evt)}
                      onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                  </div>
                  {errors.operatorLanguageRates?.[index]?.amount &&
                    errors.operatorLanguageRates?.[index]?.amount?.type ===
                    "required" && <FormErrorMessage message="required" />}
                  {errors.operatorLanguageRates?.[index]?.amount &&
                    errors.operatorLanguageRates?.[index]?.amount?.type ===
                    "validate" && (
                      <FormErrorMessage message="Please enter a valid value" />
                    )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-link text-uppercase text-site"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleShow(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary text-uppercase form-submit-btn"
            type="submit"
          >
            {submitType === "Save" ? "Save" : "Update"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
