import React, { useState, useEffect } from "react";
import { PlatformApi, topLoaderProgress } from "../../configs";
import useSWR from "swr";
import { ApiResponse, WithPagination } from "../../models";
import { AxiosAuth } from "../../core";
import { ApiSchemaAuditLogsData } from "../../models/api/AuditLogs";
import { ApiSchemaFilter } from "../../models/api/filter";

type Props = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
};

let pagedAuditLogsUrl: string;

export function useFunctionalityAuditLogs(props: Props) {
  const {
    pageSize,
    pageNo,
    searchText,
    setLoaderProgress,
    filterData,
    filterDataChange,
  } = props;
  // const [pagedAuditLogsListCount, setPagedAuditLogsListCount] =
  //   useState<number>(0);
  const [auditLogTableData, setAuditLogTableData] =
    useState<ApiSchemaAuditLogsData[]>();

  pagedAuditLogsUrl = React.useMemo(() => {
    return PlatformApi.Activity.AuditLogs(
      pageSize,
      pageNo,
      searchText,
      filterDataChange
    );
  }, [pageSize, pageNo, searchText, filterDataChange]);

  const { data: apiPagedAuditLogsList, error } = useSWR(
    pagedAuditLogsUrl,
    async () => {
      setLoaderProgress(topLoaderProgress.start);

      try {
        const response = await AxiosAuth.post<
          ApiResponse<WithPagination<ApiSchemaAuditLogsData[]>>
        >(pagedAuditLogsUrl, filterData);
        setLoaderProgress(topLoaderProgress.complete);
        return {
          data: response.data.data.data,
          count: response.data.data.count
        };
      } finally {
        setLoaderProgress(topLoaderProgress.complete);
      };


    },
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (apiPagedAuditLogsList?.data) {
      setAuditLogTableData(apiPagedAuditLogsList.data);
    }
  }, [apiPagedAuditLogsList]);


  const pagedAuditLogsList = apiPagedAuditLogsList?.data ?? auditLogTableData;
  const pagedAuditLogsListCount = apiPagedAuditLogsList?.count || 0;

  if (error) {
    console.error(error);
  }

  // const pagedAuditLogsList = React.useMemo(() => {
  //     const data = Array.isArray(apiPagedAuditLogsList) ? apiPagedAuditLogsList : [];
  //     return data;
  // }, [apiPagedAuditLogsList]);


  return {
    pagedAuditLogsList,
    pagedAuditLogsListCount,
  };
}
