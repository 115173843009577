import { useState } from 'react';
import packageJson from '../../../package.json';
import {
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalReportProblem } from '../modals/reportProblemModal';
import { ApplicationName } from "../../configs";
export function Footer(): JSX.Element {
  const [show, setShow] = useState<boolean>(false)
  const reportModalOpener = () => {
    setShow(true)
  }

  const handleReportSubmit = () => {

  }
  return (
    <>
      <footer className="footer ">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col">
              <p className='text-center mb-0'>
                <small>
                  &#169;  {ApplicationName}  {packageJson.version}
                </small>
              </p>
            </div>
            {/* <div className="ml-auto  width-fit-content mr-3">
              <a className="btn btn-link p-0  footer-alert-btn" onClick={() => reportModalOpener()}>
                <small className="">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="font-sz-10 mr-1"
                  />Report Problem
                </small>
              </a>
            </div> */}
          </div>


        </div>
      </footer>
      <ModalReportProblem
        show={show}
        handleShow={setShow}
      />
    </>
  )
}