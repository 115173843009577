import { useEffect, useContext } from 'react';
import { RemoteParticipant } from 'twilio-video';
import { twilioParticipantHelper } from '../../helpers';
import { GuestGridView, GuestSpeakerView, NetworkConnection } from '..';
import { AppConstants, VideoViewType } from '../../configs';
import { VideoGuestContext } from '../../core';
import { logger } from '../../utils';

const { convertParticipantsToArray } = twilioParticipantHelper();

interface IRoomProp {
  callHangupHandler: Function;
}

export function GuestRoom({ callHangupHandler }: IRoomProp) {

    
  const {
    room,
    viewType,
    // userRole,
    remoteParticipants,
    setRemoteParticipants,  
    participantsInfo,
  } = useContext(VideoGuestContext);

  // console.log("----- Guest Room has been loaded ------------");

  useEffect(() => {
    setRemoteParticipants(convertParticipantsToArray(room?.participants!));
  }, [room?.participants, setRemoteParticipants]);

  useEffect(() => {
    const handleParticipantConnected = (
      connectedParticipant: RemoteParticipant
    ) => {
      logger({
        message: `[GuestRoom]: Connected participant : ${connectedParticipant.identity}`,
      });
      setRemoteParticipants(convertParticipantsToArray(room?.participants!));
    };

    const handleParticipantDisconnected = (
      disconnectedParticipant: RemoteParticipant
    ) => {
      logger({
        message: `[GuestRoom]: Disconnected participant ${
          participantsInfo?.[disconnectedParticipant?.identity]?.name
        } (${disconnectedParticipant?.identity})`,
      });
      if (
        // userRole === AppConstants.UserRoles.Consumer &&
        !room?.participants.size
      ) {
        console.log("invoking callHangupHandler because userRole === AppConstants.UserRoles.Consumer && !room?.participants.size");

        callHangupHandler();
      }
      delete participantsInfo?.[disconnectedParticipant?.identity];
      const connectedParticipant = remoteParticipants.filter(
        (participant: RemoteParticipant) => {
          return participant !== disconnectedParticipant;
        }
      );
      setRemoteParticipants(connectedParticipant);
    };

    const handleRoomDisconnect = () => {
      logger({ message: '[GuestRoom]: Disconnected Event Triggered' });
    };

    //here
    if (room) {
      room.on('participantConnected', handleParticipantConnected);
      room.on('participantDisconnected', handleParticipantDisconnected);
      room.on('disconnected', handleRoomDisconnect);
      //room.on (fetch, post, or any api call)

      return () => {
        room.removeListener('participantConnected', handleParticipantConnected);
        room.removeListener(
          'participantDisconnected',
          handleParticipantDisconnected
        );
        room.removeListener('disconnected', handleRoomDisconnect);
      };
    }
  }, [
    room,
    remoteParticipants,
    setRemoteParticipants,
    callHangupHandler,
    // userRole,
    participantsInfo,
  ]);

  useEffect(() => {
    if (!room) {
      console.log("we have !room: room is undefined, returning");
      return;
    }

    const handleLeft = () => {
      logger({ message: '[GuestRoom]: beforeunload / pagehide event triggered' });
      room.disconnect();
    };

    window.addEventListener('beforeunload', handleLeft);
    window.addEventListener('pagehide', handleLeft);

    return () => {
      window.removeEventListener('beforeunload', handleLeft);
      window.removeEventListener('pagehide', handleLeft);
    };
  }, [room]);

  return (
    <div className="conference-site-content">
      {room?.localParticipant && (
        <div id="room">
          <NetworkConnection hangupHandler={callHangupHandler} />
          {viewType === VideoViewType.GridView ?  <GuestGridView /> : <GuestSpeakerView />}
        </div>
      )}
    </div>
  );
}
