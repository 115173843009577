import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { createSelectorHook, useDispatch } from "react-redux";
import {
  ReducerAuth,
  ReducerCalender,
  ReducerAudioCall,
  ReducerCompany,
  ReducerCompanyContract,
  ReducerNotification,
  ReducerUi,
  ReducerVideoCall,
  ReducerCommon,
} from "./Slices";

const RootReducer = combineReducers({
  auth: ReducerAuth,
  ui: ReducerUi,
  audioCall: ReducerAudioCall,
  company: ReducerCompany,
  companyContract: ReducerCompanyContract,
  videoCall: ReducerVideoCall,
  notification: ReducerNotification,
  calender: ReducerCalender,
  common: ReducerCommon,
});

type TRootReducerParams = Parameters<typeof RootReducer>;
type TRootReducerReturn = ReturnType<typeof RootReducer>;

const ActionAppTypeResetStore = "RESET_APP_REDUX_STORE";

export const ActionApp = {
  ResetStore: (): AnyAction => ({ type: ActionAppTypeResetStore }),
};

const AppReducer = (...arg: TRootReducerParams): TRootReducerReturn => {
  const [, action] = arg;
  if (action.type === ActionAppTypeResetStore) {
    return RootReducer(undefined, action);
  }
  return RootReducer(...arg);
};

export const VRIAppStore = configureStore({
  reducer: AppReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  })
});

export type VRIAppStateType = ReturnType<typeof RootReducer>;
export type VRIAppDispatch = typeof VRIAppStore.dispatch;
export const useAppDispatch = () => useDispatch<VRIAppDispatch>();
export const useAppSelector = createSelectorHook();
