import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { batch, shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { FormErrorMessage } from "../../components";
import {
  AppConstants,
  AppRouteUi,
  BookingStatusColor,
  BookingStatusType,
  CallInitiateTabs,
  CallType,
  PlatformType
} from "../../configs";
import { ActionCalender, ActionNotification, ActionVideoCall, useAppDispatch, useAppSelector, VRIAppStateType } from "../../core";
import { ApiSchemaJobDataById, BookingActualTimeType, ChangeJobStatus } from "../../models";
import {
  useFunctionalityAcceptOfferedJob,
  useFunctionalityBookingCancellationReasons,
  useFunctionalityChangedStatus,
  useFunctionalityStartJob,
  useFunctionalityVideoConferenceCall
} from "../../pages/hooks";
import { ModalConfirmJobStatus } from "./confirmJobStatusModal";
import { ModalCloseViewJob } from "./viewCloseJobModal";
import { UserMediaUtils } from "../../utils";

type Props = {
  show: boolean;
  handleShow?: (value: boolean) => void;
  selectedJob?: ApiSchemaJobDataById;
  mutateUrl?: string;
};

export function ModalViewJob(props: Props): JSX.Element {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<ChangeJobStatus>();
  const [actualStartTime, setActualStartTime] = useState(props.selectedJob?.actualStartTime ?? "");
  const [actualEndTime, setActualEndTime] = useState(props.selectedJob?.actualEndTime ?? "");
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  const [cancelJob, setCancelJob] = useState(false);
  const [statusValue, setStatusValue] = useState<BookingStatusType>(1);
  const [cancellationExtraInfo, setCancellationExtraInfo] = useState<string>("");
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(BookingStatusType[props.selectedJob?.status ?? 0]);
  const [currentStatusValue, setCurrentStatusValue] = useState(props.selectedJob?.status ?? 0);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [actualConvertedStartDate, setActualConvertedStartDate] = useState<string>("");
  const [actualConvertedEndDate, setActualEndDate] = useState<string>("");

  const { onCreateScheduleCall } = useFunctionalityVideoConferenceCall();

  const dispatch = useAppDispatch();
  const data = useAppSelector((state: VRIAppStateType) => ({
    userRole: state.auth.profile?.userRole,
    timeZone: state.auth.profile?.timeZone,
    twilioDeviceStatus: state.common.twilioDeviceStatus,
    isBackdatedVersion: state.common.isBackdatedVersion,
  }));
  const userProfile = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile,
    shallowEqual
  );
  const { bookingCancellationReasonsData } =
    useFunctionalityBookingCancellationReasons({
      userId: props.selectedJob?.companyId || "",
    });
  const { acceptOfferedJob } = useFunctionalityAcceptOfferedJob();
  const { OnChangeStatus } = useFunctionalityChangedStatus();
  const { changedActualJobTime } = useFunctionalityStartJob();
  useEffect(() => {
    if (props.selectedJob) {
      setActualStartTime(props.selectedJob?.actualStartTime ?? "");
      setActualEndTime(props.selectedJob?.actualEndTime ?? "");
      setCurrentStatus(BookingStatusType[props.selectedJob?.status ?? 0]);
      setCurrentStatusValue(props.selectedJob?.status ?? 0);
    }
  }, [props.selectedJob]);

  const history = useHistory();
  const handleFulfilStatus = (status: BookingStatusType) => {
    setStatusValue(status);
    setChangeStatusModalShow(true);
  };
  const handleNoAttendanceStatus = (status: BookingStatusType) => {
    setStatusValue(status);
    setChangeStatusModalShow(true);
  };
  const handleCanCelJob = () => {
    setCancelJob(true);
  };
  const onCloseJobModalHandler = () => {
    props.handleShow && props.handleShow(false);
    batch(() => {
      dispatch(ActionCalender.SetShowJobModal(false));
      dispatch(ActionNotification.SetBookingNotificationShow(false));
    });
    setCancelJob(false);
  };
  const handleBookingCancellationReason = (value: any) => {
    setValue("cancellationReasonId", value?.value, {
      shouldValidate: true,
    });
  };

  const handleCancellationExtraInfo = (event: any) => {
    setCancellationExtraInfo(event.target.value);
  };

  const modalHideHandler = () => {
    props.handleShow && props.handleShow(false);
    batch(() => {
      dispatch(ActionCalender.SetShowJobModal(false));
      dispatch(ActionNotification.SetBookingNotificationShow(false));
    });
  };

  const onChangeStatusHandler = (form: ChangeJobStatus) => {
    form = {
      ...form,
      statusType: BookingStatusType.Cancelled,
      cancellationReasonId: form.cancellationReasonId,
      cancellationExtraInfo: form.cancellationExtraInfo,
    };

    OnChangeStatus(props.selectedJob?.id ?? "", form)
      ?.then(() => {
        props.handleShow && props.handleShow(false);
        batch(() => {
          dispatch(ActionCalender.SetShowJobModal(false));
          dispatch(ActionNotification.SetBookingNotificationShow(false));
        });
        modalHideHandler();
        setCancelJob(true);
      })
      .catch((err) => console.log(err));
  };

  function convertDate(str: string) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleJobCloseModal = () => {
    props.handleShow && props.handleShow(false);
    batch(() => {
      dispatch(ActionCalender.SetShowJobModal(false));
      dispatch(ActionNotification.SetBookingNotificationShow(false));
    });
    setShowCloseModal(true);
    const startDateTime = props.selectedJob?.actualStartTime ?? actualStartTime;
    const endDateTime = props.selectedJob?.actualEndTime ?? actualEndTime;
    const actualStartDate = startDateTime ? convertDate(startDateTime) : "";
    const actualEndDate = endDateTime ? convertDate(endDateTime) : "";
    setActualConvertedStartDate(actualStartDate);
    setActualEndDate(actualEndDate);
  };

  const endJobButtonHandler = () => {
    const date = new Date();
    setActualEndTime(date.toString());
    changedActualJobTime(
      props.selectedJob?.id ? props.selectedJob?.id : "",
      date.toISOString(),
      BookingActualTimeType.EndTime
    );
  };

  const startJobButtonHandler = () => {
    const date = new Date();
    setActualStartTime(date.toString());
    changedActualJobTime(
      props.selectedJob?.id ? props.selectedJob?.id : "",
      date.toISOString(),
      BookingActualTimeType.StartTime
    );
  };

  const acceptButtonHundler = () => {
    acceptOfferedJob(
      props.selectedJob?.id ?? "",
      setCurrentStatus,
      setCurrentStatusValue
    )
      .then((r) => {
        props.handleShow && props.handleShow(false);
        mutate(props?.mutateUrl!);
        batch(() => {
          dispatch(ActionCalender.SetShowJobModal(false));
          dispatch(ActionNotification.SetBookingNotificationShow(false));
        });
      })
      .catch((e) => {
        props.handleShow && props.handleShow(false);
        batch(() => {
          dispatch(ActionCalender.SetShowJobModal(false));
          dispatch(ActionNotification.SetBookingNotificationShow(false));
        });
      });
  };

  const getCallTracks = async (type: 'audio' | 'video') => {
    let pathname: string = '';
    if (type === 'audio') {
      pathname = AppRouteUi.OutgoingCallFailedForMic.Root;
      const tracks = await UserMediaUtils.startMedia({ audio: true });
      if (tracks) {
        UserMediaUtils.stopMedia({ tracks });
        return true;
      }
    } else {
      pathname = AppRouteUi.OutgoingCallFailedForMedia.Root;
      const tracks = await UserMediaUtils.startMedia();
      if (tracks) {
        dispatch(ActionVideoCall.setTracks(tracks));
        return true;
      }
    }

    //console.trace("Redirecting:");
    history.push({
      pathname,
    });
    return false;
  };

  const handleStartCallRedirection = async () => {
    if (data.twilioDeviceStatus === 'offline') {
      toast.error(
        'Please, try again after few seconds'
      );
      return;
    }
    let haveTrack: boolean;
    if (
      props.selectedJob?.platformType === PlatformType.VRI ||
      (props.selectedJob?.videoConferenceRoomId &&
        props.selectedJob.assignedOperatorId)
    ) {
      haveTrack = await getCallTracks('video');
    } else {
      haveTrack = await getCallTracks('audio');
    }
    if (!haveTrack) {
      return;
    }
    if (
      props.selectedJob?.videoConferenceRoomId &&
      props.selectedJob.assignedOperatorId
    ) {
      onCreateScheduleCall(
        props.selectedJob.videoConferenceRoomId,
        props.selectedJob.assignedOperatorId
      );
    } else {

      //console.trace("Redirecting:");
      history.push({
        pathname:
          props.selectedJob?.platformType === PlatformType.VRI
            ? AppRouteUi.VideoOutgoingCall.Root
            : AppRouteUi.OutgoingCall.Root,
        state: {
          callInitiateTab: CallInitiateTabs.directCall,
          directCallSelectedUserId: props.selectedJob?.assignedOperatorId,
          callType:
            props.selectedJob?.platformType === PlatformType.VRI
              ? CallType.Video
              : CallType.Audio,
          bookingId: props.selectedJob?.id,
        },
      });
    }
  };
  const currentDate = new Date();
  const currentDateWithUserTimeZone = currentDate.toLocaleString("en-US", { timeZone: data.timeZone, });
  const dateFormatOfCurrentDateWithUserTimeZone = new Date(currentDateWithUserTimeZone);

  const showEVosForm = () => {
    if (
      (props.selectedJob?.status === BookingStatusType.Confirmed ||
        props.selectedJob?.status === BookingStatusType.Closed) &&
      data.userRole !== AppConstants.UserRoles.CompanyAdmin &&
      data.userRole !== AppConstants.UserRoles.CompanyFinance &&
      data.userRole !== AppConstants.UserRoles.User
    ) {
      if (data.userRole === AppConstants.UserRoles.Operator) {
        return !(
          props?.selectedJob?.platformType === PlatformType.VRI ||
          props?.selectedJob?.platformType === PlatformType.OPI
        );
      }
      return true;
    }
    return false;
  };

  const showCloseJob = () => {
    if (data.userRole === AppConstants.UserRoles.Operator) {
      if (props.selectedJob?.status === BookingStatusType.Confirmed && (new Date(props?.selectedJob?.start!) <= dateFormatOfCurrentDateWithUserTimeZone)) {
        return !(
          props?.selectedJob?.platformType === PlatformType.VRI ||
          props?.selectedJob?.platformType === PlatformType.OPI
        );
      }
    }
    return false;
  };

  return <>
    <Modal
      id="job-view-modal"
      show={props.show}
      onHide={() => {
        props.handleShow && props.handleShow(false);
        batch(() => {
          dispatch(ActionCalender.SetShowJobModal(false));
          dispatch(ActionNotification.SetBookingNotificationShow(false));
        });
        setCancelJob(false);
      }}
      size="lg"
      centered
    >
      <div>
        {/*@ts-ignore */}
        {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>
            {props.selectedJob?.serialNo
              ? "#".concat(props.selectedJob?.serialNo)
              : "-"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row mb-2 border-bottom border-grey">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-0 mb-3">
                <div>
                  <strong>From</strong>
                </div>
                <div className="word-wrap">
                  <span>Company Name: </span>
                  {props.selectedJob?.companyName ?? "-"}
                </div>
                <div className="word-wrap">
                  <span>Request By: </span>
                  {props.selectedJob?.requestedBy ?? "-"}
                </div>
                <div className="word-wrap">
                  <span>Email: </span>
                  {props.selectedJob?.email ?? "-"}
                </div>
                {/* <div className="word-wrap">
                  {props.selectedJob?. ?? '-'}
                </div> */}
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-0 mb-3">
                <strong>Status</strong>
                <div>
                  <span
                    className={`badge p-1 rounded-0 text-uppercase text-white`}
                    style={{
                      backgroundColor:
                        currentStatusValue === BookingStatusType.Open
                          ? BookingStatusColor.Open
                          : currentStatusValue === BookingStatusType.Offered
                            ? BookingStatusColor.Offered
                            : currentStatusValue === BookingStatusType.Confirmed
                              ? BookingStatusColor.Confirmed
                              : currentStatusValue === BookingStatusType.Closed
                                ? BookingStatusColor.Closed
                                : currentStatusValue === BookingStatusType.Cancelled
                                  ? BookingStatusColor.Cancelled
                                  : currentStatusValue ===
                                    BookingStatusType.Unfulfilled
                                    ? BookingStatusColor.Unfulfilled
                                    : currentStatusValue === BookingStatusType.NoShow
                                      ? BookingStatusColor.NoShow
                                      : currentStatusValue === BookingStatusType.Paid
                                        ? BookingStatusColor.Paid
                                        : currentStatusValue === BookingStatusType.Billed
                                          ? BookingStatusColor.Billed
                                          : "",
                    }}
                  >
                    {currentStatus}
                  </span>
                </div>
              </div>

              {(props.selectedJob?.address?.description ||
                props.selectedJob?.address?.city ||
                props.selectedJob?.address?.stateName ||
                props.selectedJob?.address?.zipCode ||
                props.selectedJob?.address?.countryName) && (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-0 mb-3">
                    <strong>Address</strong>
                    <div className="word-wrap">
                      {props.selectedJob?.address &&
                        props.selectedJob?.address.countryName ? (
                        <span>
                          {props.selectedJob?.address.description},{" "}
                          {props.selectedJob?.address.city},{" "}
                          {props.selectedJob?.address.stateName},{" "}
                          {props.selectedJob?.address.zipCode},{" "}
                          {props.selectedJob?.address.countryName}.
                        </span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </div>
                  </div>
                )}
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-0 mb-3">
                <strong>Schedule</strong>
                <div className="word-wrap">
                  {moment(props.selectedJob?.date).format("ll") ?? "-"}
                </div>
                <div className="word-wrap">
                  {
                    // moment(props.selectedJob?.startTime)
                    //   .format('hh:mm:ss')
                    props.selectedJob?.startTime
                  }{" "}
                  -{" "}
                  {
                    // moment(props.selectedJob?.endTime)
                    //   .format('hh:mm:ss')
                    props.selectedJob?.endTime
                  }
                </div>
              </div>

              {props.selectedJob?.distance && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-0 mb-3">
                  <strong>Distance: </strong>
                  <div className="word-wrap">
                    {props.selectedJob?.distance} {" Miles "}
                  </div>
                </div>
              )}

              <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-0 mb-3">
                <strong>Requirements</strong>
                <div className="word-wrap">
                  Language : {props.selectedJob?.languageName ?? "-"}
                </div>
              </div>

              {/* {
                data.userRole == "BABEL_OPERATOR" &&
                <div className="col-6 px-0 mb-3">
                  <button type="button" className="btn btn-outline-secondary text-uppercase mx-3"
                  >
                    Start Job
                  </button>
                </div>
              } */}
            </div>

            {(props.selectedJob?.customerNote ||
              props.selectedJob?.operatorNote ||
              props.selectedJob?.adminNote) && (
                <div className="row mb-2 border-bottom border-grey">
                  {props.selectedJob?.customerNote && (
                    <div className="col-6 px-0 mb-3">
                      <strong>Note For Customer</strong>
                      <div className="word-wrap">
                        {props.selectedJob?.customerNote ?? "-"}
                      </div>
                    </div>
                  )}

                  {props.selectedJob?.operatorNote && (
                    <div className="col-6 px-0 mb-3">
                      <strong>Note For Operator</strong>
                      <div className="word-wrap">
                        {props.selectedJob?.operatorNote ?? "-"}
                      </div>
                    </div>
                  )}

                  {props.selectedJob?.adminNote && (
                    <div className="col-6 px-0 mb-3">
                      <strong>Note For Admin</strong>
                      <div className="word-wrap">
                        {props.selectedJob?.adminNote ?? "-"}
                      </div>
                    </div>
                  )}
                </div>
              )}

            <div
              className={`row mb-2 pb-2 align-items-center justify-content-center ${actualStartTime ||
                actualEndTime ||
                !props.selectedJob?.isAlreadyAccepted
                ? "border-bottom border-grey"
                : ""
                }`}
            >
              <div
                className={`${actualStartTime || actualEndTime
                  ? "col-12 col-sm-6 px-0"
                  : ""
                  }`}
              >
                <div className={`${actualStartTime ? "mr-2" : ""}`}>
                  {actualStartTime ? (
                    <span>
                      <span className="font-500"> Actual Start Time :</span>
                      <span className="ml-1">
                        {moment(actualStartTime).format("LT")}
                      </span>
                    </span>
                  ) : null}
                </div>

                <div>
                  {actualEndTime ? (
                    <span>
                      <span className="font-500 ">
                        {" "}
                        Actual End Time &nbsp; :
                      </span>
                      <span className="ml-1">
                        {moment(actualEndTime).format("LT")}
                      </span>
                    </span>
                  ) : null}
                </div>
              </div>

              {props.selectedJob?.status === BookingStatusType.Confirmed &&
                props.selectedJob?.platformType === PlatformType.OnSite &&
                data.userRole === AppConstants.UserRoles.Operator &&
                !actualStartTime &&
                !actualEndTime &&
                new Date(props.selectedJob?.end!) >
                dateFormatOfCurrentDateWithUserTimeZone &&
                new Date(props.selectedJob?.start!) <
                dateFormatOfCurrentDateWithUserTimeZone && (
                  <div className="col-12 col-sm-6 pl-sm-0 mt-2 mt-sm-0 ">
                    <button
                      type="button"
                      className="btn btn-purple btn-block"
                      onClick={() => startJobButtonHandler()}
                    >
                      Start Job
                    </button>
                  </div>
                )}
              {props.selectedJob?.status === BookingStatusType.Confirmed &&
                props.selectedJob?.platformType === PlatformType.OnSite &&
                data.userRole === AppConstants.UserRoles.Operator &&
                actualStartTime &&
                !actualEndTime &&
                new Date(props.selectedJob?.start!) <
                dateFormatOfCurrentDateWithUserTimeZone && (
                  <div className="col-12 col-sm-6 pl-0 mt-2 mt-sm-0 ">
                    <button
                      type="button"
                      className="btn btn-danger btn-block"
                      onClick={() => endJobButtonHandler()}
                    >
                      End Job
                    </button>
                  </div>
                )}
              {currentStatusValue === BookingStatusType.Offered &&
                data.userRole == AppConstants.UserRoles.Operator &&
                !props.selectedJob?.isAlreadyAccepted && (
                  <div className="col-12 col-sm-6 pl-0 mt-2 mt-sm-0 ">
                    <button
                      className="btn btn-purple btn-block btn-accept-job"
                      onClick={() => acceptButtonHundler()}
                    >
                      {" "}
                      Accept{" "}
                    </button>
                  </div>
                )}
            </div>

            <div className="row mt-3 job-action-btn-container">
              <div className="col-12 text-md-right px-0">
                {props?.selectedJob?.platformType === PlatformType.OnSite &&
                  showEVosForm() && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary text-uppercase mr-lg-3 ml-md-3 mb-3 mb-md-0"
                      onClick={() => {

                        //console.trace("Redirecting:");
                        history.push({
                          pathname: AppRouteUi.EVosForm.Root,

                          state: {
                            jobData: props.selectedJob,
                            actualStartTime:
                              props.selectedJob?.actualStartTime ??
                              actualStartTime,
                            actualEndTime:
                              props.selectedJob?.actualEndTime ??
                              actualEndTime,
                          },
                        });
                      }}
                    >
                      E Vos Form
                    </button>
                  )}

                {props.selectedJob?.status !== BookingStatusType.Closed &&
                  props.selectedJob?.status !== BookingStatusType.Billed &&
                  props.selectedJob?.status !== BookingStatusType.Paid &&
                  (userProfile?.userRole === AppConstants.UserRoles.Admin ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.Manager ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.Finance ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.ResellerAdmin ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.ResellerManager ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.ResellerFinance) && (
                    <>
                      <button
                        type="button"
                        className="btn text-uppercase unfulfilled-bg mr-lg-3 ml-lg-0 ml-md-3 mb-sm-3 mb-3 mb-md-0"
                        onClick={() =>
                          handleFulfilStatus(BookingStatusType.Unfulfilled)
                        }
                      >
                        Can't fulfill
                      </button>
                      <button
                        type="button"
                        className="btn text-uppercase noshow-bg mr-lg-3 ml-lg-0 ml-md-3 mb-sm-3 mb-3 mb-md-0"
                        onClick={() =>
                          handleNoAttendanceStatus(BookingStatusType.NoShow)
                        }
                      >
                        Non attendance
                      </button>
                    </>
                  )}

                {props.selectedJob?.requestedById === userProfile?.id &&
                  props.selectedJob?.status === BookingStatusType.Confirmed &&
                  props.selectedJob?.platformType !== PlatformType.OnSite &&
                  new Date(props.selectedJob?.end!) >
                  dateFormatOfCurrentDateWithUserTimeZone &&
                  new Date(props.selectedJob?.start!) <
                  dateFormatOfCurrentDateWithUserTimeZone && (
                    <button
                      type="button"
                      className="btn btn-primary text-uppercase mr-lg-3 ml-lg-0 ml-md-3 mb-sm-3 mb-3 mb-md-0"
                      // disabled={
                      //   new Date(props.selectedJob?.start!) > dateFormatOfCurrentDateWithUserTimeZone
                      // }
                      disabled={data.isBackdatedVersion}
                      onClick={handleStartCallRedirection}
                    >
                      Start Call
                    </button>
                  )}

                {props?.selectedJob?.platformType === PlatformType.OnSite &&
                  showCloseJob() && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary text-uppercase mr-lg-3 ml-lg-0 ml-md-3 mb-sm-3 mb-3 mb-md-0"
                      onClick={handleJobCloseModal}
                    >
                      Close Job
                    </button>
                  )}

                {dateFormatOfCurrentDateWithUserTimeZone >=
                  new Date(props.selectedJob?.start!)
                  ? (userProfile?.userRole === AppConstants.UserRoles.Admin ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.Manager ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.ResellerAdmin ||
                    userProfile?.userRole ===
                    AppConstants.UserRoles.ResellerManager) &&
                  !cancelJob && (
                    <button
                      type="button"
                      className="btn btn-danger text-uppercase ml-lg-0 ml-md-3 mb-sm-3 mb-3 mb-md-0"
                      onClick={handleCanCelJob}
                    >
                      cancel job
                    </button>
                  )
                  : !cancelJob &&
                  userProfile?.userRole !==
                  AppConstants.UserRoles.Operator && (
                    <button
                      type="button"
                      className="btn btn-danger text-uppercase"
                      onClick={handleCanCelJob}
                    >
                      cancel job
                    </button>
                  )}
              </div>
            </div>
            {cancelJob && (
              <div className="row cancel-job-required-container">
                <form
                  onSubmit={handleSubmit(onChangeStatusHandler)}
                  className="w-100"
                >
                  <div className="form-group">
                    <div className="div-borderName px-1 mt-2">
                      <div className="row px-0 py-3 pl-1">
                        <label
                          htmlFor=""
                          className="col-4 col-sm-4 col-md-5 col-lg-4 col-xl-4 col-form-label required"
                        >
                          Reason for Cancelling Call
                        </label>

                        <div className="col-8 col-sm-8 col-md-7 col-lg-8 col-xl-8">
                          <input
                            className="d-none"
                            {...register('cancellationReasonId', { required: true })} />
                          <Select
                            className={` ${errors.cancellationReasonId?.type == "required"
                              ? "field-required"
                              : ""
                              } `}
                            placeholder="select reason "
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={bookingCancellationReasonsData}
                            onChange={(value) => {
                              handleBookingCancellationReason(value);
                            }}
                          />
                          {errors.cancellationReasonId && (
                            <FormErrorMessage message="required" />
                          )}
                        </div>
                      </div>

                      <div className="col-12 p-2">
                        <label htmlFor="" className="">
                          Extra Information:
                        </label>
                        <textarea
                          {...register('cancellationExtraInfo')}
                          id=""
                          placeholder="extra Information"
                          className="form-control w-100"
                          onBlur={handleCancellationExtraInfo}
                          rows={4} />
                      </div>
                    </div>
                    <div className="col-12 p-1 mb-2">
                      <button
                        type="button"
                        className="btn btn-outline-dark text-uppercase"
                        onClick={() => setCancelJob(false)}
                      >
                        close
                      </button>
                    </div>
                  </div>
                  <div className="col-12 pr-0 text-right">
                    <button
                      type="submit"
                      className="login-from-submit-btn btn btn-danger text-uppercase"
                    >
                      cancel job
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={onCloseJobModalHandler}
          >
            Close
          </button>
          {data.userRole === AppConstants.UserRoles.Admin ||
            data.userRole === AppConstants.UserRoles.Finance ||
            data.userRole === AppConstants.UserRoles.ResellerAdmin ||
            data.userRole === AppConstants.UserRoles.ResellerFinance ? (
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => {

                //console.trace("Redirecting:");
                history.push({
                  pathname: AppRouteUi.EditJobs.Root,
                  state: {
                    jobId: props.selectedJob?.id,
                  },
                });
              }}
            >
              {loaderProgress ? (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              edit job
            </button>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </div>
    </Modal>
    <ModalConfirmJobStatus
      status={statusValue}
      jobId={props.selectedJob?.id || ""}
      show={changeStatusModalShow}
      handleShow={setChangeStatusModalShow}
      jobViewModalHandleShow={props.handleShow && props.handleShow}
    />
    {showCloseModal && (
      <ModalCloseViewJob
        show={showCloseModal}
        handleShow={setShowCloseModal}
        selectedJob={props.selectedJob}
        actualStartTime={actualStartTime}
        actualEndTime={actualEndTime}
        startDate={actualConvertedStartDate}
        endDate={actualConvertedEndDate}
        bookingId={props.selectedJob?.id ?? ""}
        operatorUserId={userProfile?.id ?? ""}
      />
    )}
  </>;
}
