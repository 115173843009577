import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../../assets";
import { InvoiceType } from "../../../configs";
import { useAppSelector,  VRIAppStateType} from "../../../core";
import { BillingAddressResponseModel } from "../../../models";
import { useFunctionalityState } from "../../../pages/hooks";
import { useFunctionalityBillingAddress } from "../../../pages/hooks/functionalityBillingAddress";
import { useFunctionalityInvoice } from "../../../pages/hooks/functionalityInvoice";
import { ModalAddressEditInfo } from "../../modals/addressModal";

type Props = {
  billerId: string;
  // billingAddress: BillingAddressResponseModel;
  invoiceType: InvoiceType;
};
export function BillingAddressTab(props: Props) {
  const { billerId, invoiceType } = props;
  const [show, handleShow] = useState<boolean>(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [billAddress, setBillAddress] = useState<BillingAddressResponseModel>();
  const { profile } = useAppSelector((state: VRIAppStateType) => state.auth);
  const { billingAddressList } = useFunctionalityBillingAddress({
    billerId: billerId ? billerId : profile?.companyId,
    invoiceType, setLoaderProgress
  })

  useEffect(() => {
    if (billingAddressList) {
      setBillAddress(billingAddressList)
    }
  }, [billingAddressList])

  const { saveBillingAddress } = useFunctionalityInvoice({});
  const onSaveHandler = (form: BillingAddressResponseModel) => {
    saveBillingAddress(invoiceType, form, billerId ? billerId : profile?.companyId)
      .then((response) => {
        handleShow(false);
        setBillAddress(response?.data?.data);
      })
      .catch((e: any) => {
        console.error(e);
      });
  };
  const { stateList } = useFunctionalityState(
    billAddress?.address?.countryId ?? "",
    show
  );
  const initialStateList = [...stateList];
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="component-card mb-3 d-xl-block d-lg-block d-md-block mt-3">
        <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
          <div>{billAddress?.name}</div>

          <button className="btn btn-link px-0 text-site btn-sm text-uppercase">
            <div
              className="btn svg-color"
              title="faceToFace"
              onClick={() => handleShow(true)}
            >
              <AssetSvg.Edit />
            </div>
          </button>
        </div>

        <div className="component-card-body border">
          <div className="row">
            <div className="col-12">
              {
                billAddress?.address?.phone && <p className="text-muted">
                  <strong>Phone Number:</strong> {billAddress?.address?.phone}
                </p>
              }
              {
                billAddress?.address?.email && <p className="text-muted">
                  <strong>Email Address:</strong> {billAddress?.address?.email}
                </p>
              }
              {
                billAddress?.displayAddress && <p className="text-muted">
                  <strong>Address:</strong> {billAddress?.displayAddress}
                </p>
              }
            </div>
          </div>
        </div>
        {show && (
          <ModalAddressEditInfo
            show={show}
            handleShow={handleShow}
            onUpdateHandler={onSaveHandler}
            billingAddress={billAddress}
            initialStateList={initialStateList}
          />
        )}
      </div>
    </>
  );
}
