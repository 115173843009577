import { useCallback } from "react";
import { PlatformApi, AppConstants } from "../../configs";
import { useAppSelector, AxiosAuth } from "../../core";
import { ApiResponse } from "../../models";
import { RoomShareMapModel } from "../../models/api/Call";

export function useFunctionalityRoomShareMap() {

  const LoadRoomMap = useCallback(async (guidGenerated: string) => {
    const sessionMapsUrl = PlatformApi.GetRoomShareMap.GetRoomMap(guidGenerated);
    // console.log("sessionMapsUrl: "+ sessionMapsUrl);
    try {
      const res = await AxiosAuth.get<ApiResponse<RoomShareMapModel>>(sessionMapsUrl);

        console.log("LoadRoomMap API call result, res.data:");
        console.log(res.data);

      if (res.status === AppConstants.Api.StatusOK && res.data) {
        
        return res.data; // Return the data if it exists
      } else {
        return null; // Return null if data is not present
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  return {
    LoadRoomMap,
  };
}
