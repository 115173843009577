import {
  DefaultStep1Data,
  DefaultStep5Data,
  PlatformType,
  PlatformTypeConstant,
} from "../../../configs";
import { ActionCompany, useAppSelector,  VRIAppStateType} from "../../../core";
import {
  TypeCreateCompanyStep1,
  TypeCreateCompanyStep3n4,
  TypeCreateCompanyStep5,
} from "../../../models";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
type Props = {
  counter: number;
  setCounter: (value: number) => void;
  onCreate?: any;
  isLastStep?: boolean;
  handleShow: (value: boolean) => void;
  reset?: any;
};

export function FooterButtons(props: Props): JSX.Element {
  const step2Data = {};
  const dispatch = useDispatch();
  const step3Data: TypeCreateCompanyStep3n4[] = useAppSelector(
    (state: VRIAppStateType) => state.company.companyStep3n4
  );

  const [contractPlatforms, setContractPlatforms] =
    useState<TypeCreateCompanyStep3n4[]>(step3Data);

  useEffect(() => {
    const updatedData = contractPlatforms.map((data) => {
      data = {
        ...data,
        languagePackName: "",
        languagePackId: "",
        isEnabled: false,
        connectionFee: 0,
        technologyFee: 0,
      };
      return data;
    });
    setContractPlatforms(updatedData);
  }, []);

  const clearStepsData = () => {
    dispatch(ActionCompany.SetCompanyStep1(DefaultStep1Data));
    dispatch(ActionCompany.SetCompanyStep2(step2Data));
    dispatch(ActionCompany.SetCompanyStep3n4(contractPlatforms));
    dispatch(ActionCompany.SetCompanyStep3n4(contractPlatforms));
    dispatch(ActionCompany.SetCompanyStep5(DefaultStep5Data));
  };

  return (
    <>
      <div className="d-flex justify-content-between align-tems-center flex-grow-1">
        <div className="">
          <button
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              clearStepsData();

              if (props.reset) {
                props.reset();
              }

              props.handleShow(false);
            }}
          >
            Cancel
          </button>
        </div>
        <div className="">
          {props.counter > 0 ? (
            <button
              type="button"
              className="btn btn-outline-secondary mr-3 text-uppercase"
              onClick={() => props.setCounter(props.counter - 1)}
            >
              Back
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-secondary mr-3 border-grey text-uppercase"
              disabled
            >
              Back
            </button>
          )}

          {props.isLastStep ? (
            <button
              type="button"
              className="btn btn-primary text-uppercase login-from-submit-btn"
              onClick={() => {
                props.onCreate(() => {
                  clearStepsData();
                });
                // clearStepsData();
              }}
            >
              Create
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-primary text-uppercase next-btn"
            >
              Next
            </button>
          )}
        </div>
      </div>
    </>
  );
}
