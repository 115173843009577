import {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
  } from 'react';
  import { AssetSvg } from '../../../assets';
  import { InfoIcon, SettingsIcon } from '../../../icons';
  import {
    ToggleScreenShareButton,
    AddParticipantButton,
    ModalAddParticipant,
    ModalAddOperatorParticipant,
  } from '../../../features';
  import {
    isElectron,
    logger,
    UserAgentUtils,
    UtilInputValidation,
  } from '../../../utils';
  import {
    callPermissionHelper,
    twilioParticipantHelper,
  } from '../../../helpers';
  import { ChannelContext, VideoGuestContext } from '../../../core';
  import { IScreen } from '../../../models';
  import ScreenPickerModal from '../../../features/modals/screenPickerModal';
  import { CallType } from '../../../configs';
  import DeviceSelectionDialog from './DeviceSelectionDialog/DeviceSelectionDialog';
  // import { VideoRoomMonitor } from '@twilio/video-room-monitor';
  interface IBottomGuestBarProps {
    setShowHangupModal: Dispatch<SetStateAction<boolean>>;
    setShowChat: Dispatch<SetStateAction<boolean>>;
    // isOperatorRequired: boolean;
    // userRole: string;
    // addParticipant: boolean;
    // operatorId: string;
    // callOperator: Function;
    showChat: boolean;
  }
  
  const {
    checkAddOperatorPermission,
    checkAddParticipantModalPermission,
    checkAddParticipantPermission,
  } = callPermissionHelper();
  const { enableAllTrack, disableAllTrack, handleStream } =
    twilioParticipantHelper();
  
  export function BottomGuestBar({
    setShowHangupModal,
    setShowChat,
    showChat,
  }: IBottomGuestBarProps) {
    const {
      room,
      isSharing,
      setIsSharing,
      callType,
      isAudioEnabled,
      isVideoEnabled,
      setIsAudioEnabled,
      setIsVideoEnabled,
    } = useContext(VideoGuestContext);
  
    const isVideoConferenceCall = callType === CallType.VideoConference;
    const isMobile =
      UserAgentUtils.isWebView() || UserAgentUtils.isMobileBrowser();
    // const haveInterpreterCallPermission =
    //   UtilInputValidation.isValidUUID(operatorId);
    // const shouldShowAddParticipantButton = checkAddParticipantPermission(
    //   userRole,
    //   callType,
    //   isOperatorRequired,
    //   addParticipant
    // );
    // const haveAddParticipantPermission = checkAddParticipantModalPermission(
    //   callType,
    //   addParticipant
    // );
    // const haveAddOperatorPermission = checkAddOperatorPermission(
    //   userRole,
    //   isVideoConferenceCall,
    //   isOperatorRequired
    // );
    const stopScreenShareRef = useRef<() => void>(null!);
  
    const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
    const [allScreens, setAllScreens] = useState<IScreen[]>([]);
    const [audioVideoSettingsOpen, setAudioVideoSettingsOpen] =
      useState<boolean>(false);
  
    const { videoChatMessageCount, setVideoChatMessageCount } =
      useContext(ChannelContext);
  
    const handleChatClick = () => {
      console.log("handle chat has been called, value of showChat: ", showChat);
      setShowChat((showChat) => !showChat);
      setVideoChatMessageCount(0);
    };
    const handleToggleVideo = () => {
      if (isVideoEnabled) {
        disableAllTrack(room?.localParticipant!, 'Video', 'screen');
      } else {
        enableAllTrack(room?.localParticipant!, 'Video', 'screen');
      }
      setIsVideoEnabled((previous) => !previous);
    };
    const handleToggleAudio = () => {
      if (isAudioEnabled) {
        disableAllTrack(room?.localParticipant!, 'Audio');
      } else {
        enableAllTrack(room?.localParticipant!, 'Audio');
      }
      setIsAudioEnabled((previous) => !previous);
    };
  
    const shareScreenHandlerDesktop = async () => {
      if (!isSharing) {
        const sources = await window.desktop.getScreens();
        setAllScreens(sources);
      } else {
        stopScreenShareRef.current();
      }
    };
  
    const getDesktopVideoStream = async (screen: IScreen) => {
      try {
        const stream = await (navigator.mediaDevices as any).getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: screen.id,
              minWidth: 1280,
              maxWidth: 1280,
              minHeight: 720,
              maxHeight: 720,
            },
          },
        });
        handleStream(stream, room!, setIsSharing, stopScreenShareRef);
        setAllScreens([]);
      } catch (e) {
        console.error(e);
      }
    };
  
    const shareScreen = useCallback(() => {
      navigator.mediaDevices
        .getDisplayMedia({
          audio: false,
          video: {
            frameRate: 10,
            height: 1080,
            width: 1920,
          },
        })
        .then((stream) => {
          handleStream(stream, room!, setIsSharing, stopScreenShareRef);
        })
        .catch((error) => {
          if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
            //  onError(error);
          }
        });
    }, [room]);
  
    const toggleScreenShare = useCallback(() => {
      if (room) {
        !isSharing ? shareScreen() : stopScreenShareRef.current();
      }
    }, [isSharing, shareScreen, room]);
  
    const handleDeviceSettings = useCallback((event: any) => {
      event.preventDefault();
      setAudioVideoSettingsOpen(true);
    }, []);
  
    const handleRoomSettings = () => {
      // VideoRoomMonitor.toggleMonitor();
    };
  
    useEffect(() => {
      return () => {
        // VideoRoomMonitor.closeMonitor();
      };
    }, []);
  
    return (
      <>
        <ScreenPickerModal
          getDesktopVideoStream={getDesktopVideoStream}
          setAllScreens={setAllScreens}
          allScreens={allScreens}
        />
        <div className="call-control-buttons">
          <div className="row align-items-center">
            <div className="col-4">
              <div className="d-flex">
                <div className="mr-auto d-flex">
                  <div className="dropdown position-relative d-inline-block">
                    {/* <button
                      className="btn btn-opaque conference-control-btn mr-4 dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      disabled={!room?.localParticipant}
                    > */}
                      {/* <AssetSvg.Options /> */}
                    {/* </button> */}
                    <div
                      className="dropdown-menu mb-3 device-settings-dropdownMenu"
                      aria-labelledby="notificationDropdown"
                    >
                      {/* {!isMobile && (
                        <div
                          className="dropdown-item cursor-pointer"
                          onClick={handleDeviceSettings}
                        >
                          <div className="d-flex align-items-center">
                            <SettingsIcon />
                            <div className="ml-2">
                              Camera &amp; Microphone settings
                            </div>
                          </div>
                        </div>
                      )} */}
                      {/* <div
                        className="dropdown-item cursor-pointer"
                        onClick={handleRoomSettings}
                      >
                        <div className="d-flex align-items-center">
                          <InfoIcon />
                          <div className="ml-2">Video Room Status</div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {room?.localParticipant && !isMobile && (
                    <ToggleScreenShareButton
                      screenShareStatus={isSharing}
                      onToggle={
                        isElectron()
                          ? shareScreenHandlerDesktop
                          : toggleScreenShare
                      }
                      sharing={isSharing}
                    />
                  )}
                </div>
              </div>
            </div>
  
            <div className="col-4">
              <div className="d-flex">
                <div className="mx-auto d-flex">
                  <button
                    type="button"
                    className="btn btn-opaque conference-control-btn"
                    onClick={handleToggleAudio}
                    disabled={!room?.localParticipant}
                  >
                    {isAudioEnabled && room?.localParticipant.audioTracks.size ? (
                      <AssetSvg.AudioOn />
                    ) : (
                      <AssetSvg.AudioOff />
                    )}
                  </button>
                  <button
                    id="conferenceHangup-btn"
                    className="btn btn-opaque conference-control-btn conferenceHangup-btn mx-2 mx-sm-4"
                    onClick={() => {
                      logger({ message: 'Pressed hangup button' });
                      setShowHangupModal(true);
                    }}
                    disabled={!room?.localParticipant}
                  >
                    <AssetSvg.Disconnect />
                  </button>
                  <button
                    type="button"
                    className="btn btn-opaque conference-control-btn"
                    onClick={handleToggleVideo}
                    disabled={!room?.localParticipant}
                  >
                    {isVideoEnabled && room?.localParticipant.videoTracks.size ? (
                      <AssetSvg.VideoOn />
                    ) : (
                      <AssetSvg.VideoOff />
                    )}
                  </button>
                </div>
              </div>
            </div>
  
            <div className="col-4">
              <div className="d-flex">
                <div className="ml-auto d-flex">
                  {!isVideoConferenceCall && (
                    <button
                      className="btn btn-opaque conference-control-btn mr-4 position-relative notification-dd"
                      onClick={handleChatClick}
                      disabled={!room?.localParticipant}
                    >
                      <AssetSvg.Message />
                      {!!videoChatMessageCount && !showChat && (
                        <sup>
                          {videoChatMessageCount > 99
                            ? '99+'
                            : videoChatMessageCount}
                        </sup>
                      )}
                    </button>
                  )}
                  {/* {haveInterpreterCallPermission ? (
                    <button
                      className="btn btn-opaque conference-control-btn"
                      onClick={() => {
                        logger({ message: 'Pressed call operator button' });
                        callOperator();
                      }}
                      disabled={!room?.localParticipant}
                    >
                      <AssetSvg.CallReceiveIcon />
                    </button>
                  ) : (
                    shouldShowAddParticipantButton && (
                      <AddParticipantButton
                        handleShow={setShowAddParticipantModal}
                      />
                    )
                  )} */}
                </div>
              </div>
            </div>
          </div>
          {/* {haveAddOperatorPermission ? (
            <ModalAddOperatorParticipant
              callType={callType}
              show={showAddParticipantModal}
              handleShow={setShowAddParticipantModal}
            />
          ) : (
            haveAddParticipantPermission && (
              <ModalAddParticipant
                callType={callType}
                show={showAddParticipantModal}
                handleShow={setShowAddParticipantModal}
              />
            )
          )} */}
        </div>
        {audioVideoSettingsOpen && (
          <DeviceSelectionDialog
            open={audioVideoSettingsOpen}
            onClose={() => {
              setAudioVideoSettingsOpen(false);
            }}
          />
        )}
      </>
    );
  }
  