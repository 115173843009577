import useSWR from "swr";
import { AppRouteUi, PlatformApi, VriApi } from "../../configs/appRoute";
import { useAppSelector, VRIAppStateType } from "../../core/Redux/ReduxStore";
import { AxiosAuth } from "../../core";
import React from "react";
import { ApiResponse } from "../../models/api/common";
import { ApiSchemaFutureConferenceData, ConferenceData } from "../../models";
import { UtilsJQuery } from "../../utils/jQuery";
import { AppConstants } from "../../configs/appConstants";

export function useFunctionalityConference(userId?: string, startDate?: string, endDate?: string) {
  const conferenceByUserIdUrl = VriApi.Conference.GetFutureConferences.Root(
    userId || "", startDate, endDate
  );
  const routeParamRoomId = AppRouteUi.Lobby.WaitingRoom.useParam() ?? "";

  const conferenceTinByRoomId = VriApi.Conference.GetConferenceTinByRoomId.Root(
    routeParamRoomId || ""
  );

  const { data: conferenceTinInfo } = useSWR<string>(
    conferenceTinByRoomId,
    () =>
      AxiosAuth.get<ApiResponse<string>>(conferenceTinByRoomId)
        .then((r) => r.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const { data: apiConferenceData } = useSWR<ConferenceData[]>(
    userId ? conferenceByUserIdUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<ApiSchemaFutureConferenceData[]>>(
        conferenceByUserIdUrl
      )
        .then((r) => {
          return r.data.data;
        })
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const conferenceTin = React.useMemo(() => {
    const data = conferenceTinInfo ? conferenceTinInfo : "";
    return data;
  }, [conferenceTinInfo]);

  const conferenceList = React.useMemo(() => {
    const data = Array.isArray(apiConferenceData) ? apiConferenceData : [];
    return data;
  }, [apiConferenceData]);


   

  return {
    conferenceList,
    conferenceTin,
    conferenceByUserIdUrl 
  };
}
export function useFunctionalityConferenceRoomInfo() {
  const isRoomStarted = React.useCallback(async (roomId: any) => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();
    try {
        const res = await AxiosAuth.get<any>(
          VriApi.Room.IsRoomCreated.Load(roomId)
        );
        if ((res.status = AppConstants.Api.StatusOK)) {
          return Promise.resolve(res.data);
        }
      
    } catch (e) {
       return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    isRoomStarted
  };
}