import React from "react";
import { ApiSchemaLanguagePackData } from "../../../models";
import { AssetSvg } from "../../../assets";

type InflowProps = {
  languagePack: ApiSchemaLanguagePackData;
  languagePackName: string;
  editable: boolean | true;
  editCallBack?: (pack: ApiSchemaLanguagePackData) => void;
  copyable?: boolean | true;
  copyCallBack?: (pack: ApiSchemaLanguagePackData) => void;
};

export function LanguagePackComponent(props: InflowProps): JSX.Element {
  const { languagePack, languagePackName, editCallBack, copyCallBack } = props;
  return (
    <>
      <div className=" component-card">
        <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
          <div className="flex-grow-1">{languagePackName}</div>
          <div>
            {copyCallBack && (
              <button
                className="btn btn-link px-0 text-site btn-sm text-uppercase mr-2 "
                onClick={() => copyCallBack(languagePack)}
              >
                <div className="btn svg-color" title="Copy">
                  <span >
                    <AssetSvg.CopyPaste />
                  </span>
                </div>
              </button>
            )}

            {editCallBack && (
              <button
                className="btn btn-link px-0 text-site btn-sm text-uppercase svg-color"
                onClick={() => editCallBack(languagePack)}
              >
                <div className="btn mr-1" title="Edit">
                  <span >
                    <AssetSvg.Edit />
                  </span>
                </div>

              </button>
            )}
          </div>
        </div>
        <div className="card-body component-card-body border">
          {languagePack?.flatRate && (
            <>
              <div className="row  mb-3">
                <div className="col-12">
                  <div className="d-flex  justify-content-end">
                    <div className="width-fit-content border rounded py-1 px-2">
                      <span className="text-capitalize">Flat rate:</span>
                      <span className="text-muted ml-2">
                        ${languagePack?.flatRate.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-12">
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap py-2 px-2">
                  <span className="ml-1 pr-3">
                    <strong>
                      Total {languagePack?.languageRates.length} Languages
                    </strong>
                  </span>
                </li>
                {languagePack?.languageRates.map((tab) => (
                  <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap py-2 px-2" key={tab.languageId}>
                    <span className="ml-1 pr-3">{tab.languageName}</span>
                    {
                      !languagePack?.flatRate && <>
                        {
                          tab.rate && tab.rate > 0 ? (
                            <span className="ml-1 pr-1">${tab.rate.toFixed(2)}</span>
                          ) : (
                            <></>
                          )
                        }
                      </>
                    }
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
