import React from 'react'
import Select from 'react-select';

import { StorageUserCalendar, useAppSelector,  VRIAppStateType} from '../../core';
import { Option } from '../../models';
import UpcomingSchedule from './upcomingSchedule';

type Props = {
    companyUserList: Option[],
    selectCompanyUserHandler: any,
    interpreterRequired: number,
    checkInterpreterHandler: any,
    languageList: Option[],
    selectLanguageHandler: any,
    optionMappedSkillList: Option[],
    selectSkillHandler: any,
    genders: Option[],
    selectGenderHandler: any,
    onClickUpcomingSchedule?: any,
    dictionary: any

}

export default function UserCalendarHeader(props: Props): JSX.Element {
    const { onClickUpcomingSchedule, companyUserList, selectCompanyUserHandler, interpreterRequired, checkInterpreterHandler, languageList, selectLanguageHandler, optionMappedSkillList, selectSkillHandler, genders, selectGenderHandler } = props;

    const isLoggedIn = useAppSelector((state: VRIAppStateType) => state.auth.isLoggedIn);

    return (
        <>
            <div className="col-12 tab-pane fade show active" id="calendar-make-schedule" role="tab-panel" aria-labelledby="new-schedule-tab">

                <div className="form-group">
                    <Select
                        className=""
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        defaultValue={StorageUserCalendar?.CompanyUser}
                        options={companyUserList}
                        placeholder={props.dictionary["Select a user"]}
                        onChange={(option) => {
                            selectCompanyUserHandler(option);
                        }}
                    />
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            disabled={!isLoggedIn}
                            className="form-check-input"
                            defaultChecked={interpreterRequired ? true : false}
                            value={interpreterRequired}
                            onChange={() => {
                                checkInterpreterHandler(interpreterRequired)
                            }}
                        />
                        <label htmlFor="" className="form-check-label">
                            {props.dictionary["Include language interpreter"]}
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <Select
                        isDisabled={interpreterRequired === 0}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        options={languageList}
                        defaultValue={StorageUserCalendar?.CalendarInterpreterSettingsData?.language}
                        placeholder={props.dictionary["Select a language"]}
                        onChange={(option) => {
                            selectLanguageHandler(option);
                        }}
                    />
                </div>
                <div className="form-group">
                    <Select
                        isDisabled={interpreterRequired === 0}
                        options={optionMappedSkillList}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        defaultValue={StorageUserCalendar.CalendarInterpreterSettingsData?.skill}
                        placeholder={props.dictionary["Select a skill"]}
                        onChange={(option) => {
                            selectSkillHandler(option);
                        }}
                    />
                </div>
                <div className="form-group mb-0">
                    <Select
                        isDisabled={interpreterRequired === 0}
                        options={genders}
                        defaultValue={StorageUserCalendar.CalendarInterpreterSettingsData?.gender}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        placeholder={props.dictionary["Select a gender"]}
                        onChange={(option) => {
                            selectGenderHandler(option);
                        }}
                    />
                </div>

                {/* <button className="btn round-border-btn-sm btn-purple btn-lg-block " type="submit" disabled>
                    Make  Schedule
                </button> */}

            </div>
            <div className="col-12 tab-pane fade" id="calendar-upcoming-schedule-list">
                <UpcomingSchedule onClickUpcomingSchedule={onClickUpcomingSchedule} dictionary={props.dictionary} />
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 pt-2 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked={interpreterRequired ? true : false}
                        value={interpreterRequired}
                        onChange={() => {
                            checkInterpreterHandler(interpreterRequired)
                        }}
                    />
                    <label htmlFor="" className="form-check-label">
                        Include language interpreter
                    </label>
                </div>
            </div> */}
            {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="row">
                    <div className="col-sm-4 form-group ">
                        <Select
                            isDisabled={interpreterRequired === 0}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            options={languageList}
                            defaultValue={StorageUserCalendar?.CalendarInterpreterSettingsData?.language}
                            placeholder="Select a language..."
                            onChange={(option) => {
                                selectLanguageHandler(option);
                            }}
                        />
                    </div>
                    <div className="col-sm-4 form-group ">
                        <Select
                            isDisabled={interpreterRequired === 0}
                            options={optionMappedSkillList}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            defaultValue={StorageUserCalendar.CalendarInterpreterSettingsData?.skill}
                            placeholder="Select a skill..."
                            onChange={(option) => {
                                selectSkillHandler(option);
                            }}
                        />
                    </div>
                    <div className="col-sm-4 form-group ">
                        <Select
                            isDisabled={interpreterRequired === 0}
                            options={genders}
                            defaultValue={StorageUserCalendar.CalendarInterpreterSettingsData?.gender}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            placeholder="Select a gender..."
                            onChange={(option) => {
                                selectGenderHandler(option);
                            }}
                        />
                    </div>
                </div>
            </div> */}

        </>
    )
}
