import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  Participant as TwilioParticipant,
  VideoTrack,
} from 'twilio-video';
import { AppConstants } from '../../../configs';
import { ActionVideoCall, VideoCallContext } from '../../../core';
import { twilioParticipantHelper } from '../../../helpers';
import { UserAgentUtils, UserMediaUtils, logger } from '../../../utils';
import { CallIndicator, UserInfo } from '../../common';

interface IParticipantProp {
  participant: TwilioParticipant;
  participantClickHandler?: Function;
  participantType: 'Remote' | 'Local';
}

const {
  getCameraTrack,
  getScreenTrack,
  getParticipantVideoTrack,
  getCallParticipantInfo,
} = twilioParticipantHelper();

export function Participant({
  participant,
  participantClickHandler,
  participantType,
}: IParticipantProp) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const isFetchingInfoRef = useRef(false);
  const {
    room,
    isSharing,
    participantsInfo,
    setParticipantsInfo,
    selectedParticipantInfo,
    userRole,
  } = useContext(VideoCallContext);
  const dispatch = useDispatch();
  const activeStatus =
    selectedParticipantInfo?.participant.identity === participant.identity &&
    participantClickHandler
      ? 'active'
      : '';
  const cursor = participantClickHandler && 'cursor-pointer';
  const [videoTracks, setVideoTracks] = useState<VideoTrack[]>([]);
  const [selectedVideoTrack, setSelectedVideoTrack] =
    useState<VideoTrack | null>(null);
  const [audioTracks, setAudioTracks] = useState<AudioTrack[]>([]);
  const [hasVideoFeed, setHasVideoFeed] = useState<boolean>(true);
  const [audioTrackMute, setAudioTrackMute] = useState<boolean>(false);
  const participantInfo = participantsInfo[participant?.identity];

  const getPublishedTrack = (track: any) => {
    return Array.from(track.values())
      .map((publication: any) => publication.track)
      .filter((track: any) => track !== null);
  };

  useEffect(() => {
    if (!participant || participant.identity.includes("guest")) {
      return;
    }
    // console.log("participant.identity from inside Participant:", participant.identity);
    const getInfo = async (participant: TwilioParticipant) => {
      isFetchingInfoRef.current = true;
      try {
        const participantInfo = await getCallParticipantInfo(
          participant,
          room?.name!
        );
        if (participantInfo) {
          console.log("participantInfo from inside Participant", participantInfo)
          participantsInfo[participant.identity] = participantInfo!;
          setParticipantsInfo({ ...participantsInfo });
        }
      } catch (error) {
        logger({
          message: '[Room]: Failed to get participant info',
          data: error,
        });
      }
      isFetchingInfoRef.current = false;
    };
    if (!isFetchingInfoRef.current && !participantsInfo[participant.identity]) {
      getInfo(participant);
    }
  }, [
    participant,
    participantsInfo,
    room?.name,
    setParticipantsInfo,
    userRole,
  ]);
  useEffect(() => {
    if (participant) {
      setVideoTracks(getPublishedTrack(participant.videoTracks));
      setAudioTracks(getPublishedTrack(participant.audioTracks));

      const handleTrackSubscribed = (track: AudioTrack | VideoTrack) => {
        logger({
          message: `[Room]: Track (${track.kind}) subscribed for Participant (${participant.identity})`,
          data: track,
        });
        if (track.kind === 'video') {
          setVideoTracks((previousVideoTracks) => [
            ...previousVideoTracks,
            track,
          ]);
        } else {
          setAudioTracks((previousAudioTracks) => [
            ...previousAudioTracks,
            track,
          ]);
        }
      };

      const handleTrackUnsubscribed = (track: AudioTrack | VideoTrack) => {
        logger({
          message: `[Room]: Track (${track.kind}) unsubscribed for Participant (${participant.identity})`,
          data: track,
        });
        if (track.kind === 'video') {
          setVideoTracks((previousTracks) =>
            previousTracks.filter((previousTrack) => previousTrack !== track)
          );
        } else {
          setAudioTracks((previousTracks) =>
            previousTracks.filter((previousTrack) => previousTrack !== track)
          );
        }
      };
      participant.on('trackSubscribed', handleTrackSubscribed);
      participant.on('trackUnsubscribed', handleTrackUnsubscribed);

      return () => {
        participant.removeListener('trackSubscribed', handleTrackSubscribed);
        participant.removeListener(
          'trackUnsubscribed',
          handleTrackUnsubscribed
        );
      };
    }
  }, [participant]);

  useEffect(() => {
    if (selectedVideoTrack) {
      const handleSwitchedOff = () => {
        logger({
          message: `[Room]: Track (video) disabled for Participant (${participant.identity})`,
          data: selectedVideoTrack,
        });
        setHasVideoFeed(false);
      };

      const handleSwitchedOn = () => {
        logger({
          message: `[Room]: Track (video) enabled for Participant (${participant.identity})`,
          data: selectedVideoTrack,
        });
        setHasVideoFeed(true);
      };
      selectedVideoTrack.on('enabled', handleSwitchedOn);
      selectedVideoTrack.on('disabled', handleSwitchedOff);

      return () => {
        selectedVideoTrack.removeListener('enabled', handleSwitchedOn);
        selectedVideoTrack.removeListener('disabled', handleSwitchedOff);
      };
    }
  }, [selectedVideoTrack, participant.identity]);

  useEffect(() => {
    const getVideoTrack = () => {
      if (participantType === 'Local') {
        if (isSharing) {
          return getScreenTrack(participant);
        } else {
          return getCameraTrack(participant);
        }
      } else {
        return getParticipantVideoTrack(participant);
      }
    };
    const track = getVideoTrack();
    logger({
      message: `[Room]: Selected video track for Participant (${participant.identity})`,
      data: track,
    });
    setSelectedVideoTrack(track);
  }, [videoTracks, isSharing, participantType, participant]);

  useEffect(() => {
    if (participantType === 'Local' && participant) {
      const handleTrackStarted = (track: LocalVideoTrack) => {
        logger({
          message: `[Room]: Track (${track.kind}) [${track.name}] started for Participant (${participant.identity})`,
          data: track,
        });
        if (track.name === 'screen') {
          setSelectedVideoTrack(track);
        }
      };
      const handleTrackPublished = (track: LocalTrackPublication) => {
        logger({
          message: `[Room]: Track (${track.kind}) [${track.trackName}] published for Participant (${participant.identity})`,
          data: track,
        });
        if (track.kind === 'video' && track.trackName !== 'screen') {
          const videoTrack = track.track as VideoTrack;
          setVideoTracks([...videoTracks, videoTrack]);
        }
        if (track.kind === 'audio') {
          const audioTrack = track.track as AudioTrack;
          setAudioTracks([...audioTracks, audioTrack]);
        }
      };
      participant.on('trackStarted', handleTrackStarted);
      participant.on('trackPublished', handleTrackPublished);
      return () => {
        participant.removeListener('trackStarted', handleTrackStarted);
        participant.removeListener('trackPublished', handleTrackPublished);
      };
    }
  }, [participantType, participant, audioTracks, videoTracks]);

  useEffect(() => {
    if (!selectedVideoTrack) {
      return;
    }
    const videoElement = videoRef.current!;
    if (selectedVideoTrack.isEnabled || hasVideoFeed) {
      !hasVideoFeed && setHasVideoFeed(true);
      selectedVideoTrack.attach(videoElement);
      logger({
        message: `[Room]: Attached video track for Participant (${participant.identity})`,
        data: selectedVideoTrack,
      });
    }
    return () => {
      logger({
        message: `[Room]: Detached video track for Participant (${participant.identity})`,
        data: selectedVideoTrack,
      });
      selectedVideoTrack.detach(videoElement);
    };
  }, [selectedVideoTrack, hasVideoFeed, participant.identity]);

  useEffect(() => {
    const audioElement = audioRef.current!;
    const firstAudioTrack = audioTracks[0];
    if (firstAudioTrack) {
      const handleAudioTrackDisable = () => {
        logger({
          message: `[Room]: Track (audio) disabled for Participant (${participant.identity})`,
          data: firstAudioTrack,
        });
        setAudioTrackMute(true);
      };
      const handleAudioTrackEnable = () => {
        logger({
          message: `[Room]: Track (audio) enabled for Participant (${participant.identity})`,
          data: firstAudioTrack,
        });
        setAudioTrackMute(false);
      };
      firstAudioTrack.on('disabled', handleAudioTrackDisable);
      firstAudioTrack.on('enabled', handleAudioTrackEnable);
      firstAudioTrack.isEnabled
        ? handleAudioTrackEnable()
        : handleAudioTrackDisable();
      firstAudioTrack.attach(audioElement);
      logger({
        message: `[Room]: Attached audio track for Participant (${participant.identity})`,
        data: firstAudioTrack,
      });
      return () => {
        logger({
          message: `[Room]: Detached audio track for Participant (${participant.identity})`,
          data: firstAudioTrack,
        });
        firstAudioTrack.detach(audioElement);
        firstAudioTrack.removeListener('disabled', handleAudioTrackDisable);
        firstAudioTrack.removeListener('enabled', handleAudioTrackEnable);
      };
    }
  }, [audioTracks, participant, participant.identity]);

  useEffect(() => {
    if (
      !(UserAgentUtils.isWebView() || UserAgentUtils.isMobileBrowser()) ||
      participantType !== 'Local' ||
      !room
    ) {
      return;
    }

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'hidden') {
        selectedVideoTrack?.mediaStreamTrack.stop();
        room?.localParticipant.unpublishTrack(
          selectedVideoTrack?.mediaStreamTrack!
        );
        logger({
          message: `[Room]: Unpublishing (Visibility) video track for Participant (${participant.identity})`,
          data: selectedVideoTrack,
        });
      } else {
        const videoTrack = await UserMediaUtils.startMedia({ video: true });
        if (!videoTrack) {
          return;
        }
        try {
          await room.localParticipant.publishTrack(videoTrack[0]);
          logger({
            message: `[Room]: Published (Visibility) video track for Participant (${participant.identity})`,
            data: selectedVideoTrack,
          });
        } catch (error) {
          logger({
            message: `[Room]: Error: Publishing (Visibility) video track for Participant (${participant.identity})`,
            data: error,
          });
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [participantType, room, selectedVideoTrack, participant.identity]);

  useEffect(() => {
    if (
      participantInfo &&
      participantInfo.userRole === AppConstants.UserRoles.Operator
    ) {
      dispatch(ActionVideoCall.setOperatorStatus(true));
    }
  }, [participantInfo, dispatch]);

  useEffect(() => {
    if (!videoTracks?.length) {
      setHasVideoFeed(false);
    }
  }, [videoTracks]);

  return (
    <div
      className={`participant ${activeStatus} ${cursor}`}
      onClick={() => {
        participantClickHandler && participantClickHandler(participant);
      }}
    >
      <CallIndicator
        participant={participant}
        audioTrackMute={audioTrackMute}
      />
      <video ref={videoRef} autoPlay />
      {hasVideoFeed ? (
        <div className="videostream-participant-name">
            <span>{participantInfo?.name ? participantInfo?.name : "Guest"}</span>
        </div>
      ) : (
        <UserInfo
          userId={participant?.identity}
          userName={participantInfo?.name}
        />
      )}
      <audio ref={audioRef} autoPlay />
    </div>
  );
}
