import {
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { AssetsPng } from "../../assets";
import { AppConstants, AppRouteUi, Modals } from "../../configs";
import { ActionUi, ActionVideoCall, useAppSelector, VRIAppStateType } from "../../core";
import { ModalLobbyAudioCallData, TopNavPublic } from "../../features";
import { BootstrapUtils, UserMediaUtils } from "../../utils";
import {
  useFunctionalityConference,
  useFunctionalityConferenceRoomInfo,
} from "../hooks";
import { MediaPreviewer } from "../../components";

export function PageLobby(): JSX.Element {
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      userRole: state.auth.profile?.userRole,
      twilioDeviceStatus: state.common,
      isBackdatedVersion: state.common.isBackdatedVersion,
      videoCallTracks: state.videoCall.tracks,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [isRoomCreated, setIsRoomCreated] = useState<boolean>(false);
  const routeParamRoomId = AppRouteUi.Lobby.WaitingRoom.useParam() ?? "";
  const { conferenceTin } = useFunctionalityConference();
  const { isRoomStarted } = useFunctionalityConferenceRoomInfo();
  const interval = useRef<any>(null);

  const handleJoin = async () => {
    const tracks = data.videoCallTracks || (await UserMediaUtils.startMedia());
    if (!tracks) {
      toast.error('Need both camera and microphone access');
      return;
    }
    if (data.userRole !== AppConstants.UserRoles.Consumer) {
      tracks && UserMediaUtils.stopMedia({ tracks });
      toast.info('Please login as a external user');
      return;
    }
    dispatch(ActionUi.SetPreviousRoute(history.location.pathname));
    dispatch(ActionUi.SetRoomId(routeParamRoomId));
    dispatch(ActionVideoCall.setTracks(tracks));
    //console.trace("Redirecting:");
    history.push({
      pathname: AppRouteUi.VideoConference.Room.LoadConsumer(routeParamRoomId),
    });
  };

  useEffect(() => {
    isRoomStarted(routeParamRoomId).then((d) => {
      if (d?.isRoomCreated) setIsRoomCreated(true);
    });
  }, []);
  useEffect(() => {
    interval.current = setInterval(() => {
      isRoomStarted(routeParamRoomId).then((d) => {
        if (d?.isRoomCreated) setIsRoomCreated(true);
      });
    }, 3000);

    return () => {
      clearInterval(interval.current);
    }
  }, []);
  useEffect(() => {
    if (isRoomCreated) clearInterval(interval.current);
  }, [isRoomCreated]);
  return (
    <>
      <TopNavPublic />
      <div className="site-call-page ">
        <div className="site-call-page-wrapper">
          <div className="container-fluid h-100">
            <div className="row  justify-content-center align-items-center h-100">
              <div className="col-12 text-center d-flex align-items-center justify-content-center p-4">
                <ul className="caller-image babelCallAnimation bg-white">
                  <img
                    // src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                    src={AssetsPng.GenericUser}
                    alt=""
                    className="img-fluid"
                  />
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>

              <div className="col-12 text-center text-white">
                <p className="p-3">Room: {routeParamRoomId}</p>
                {isRoomCreated ? (
                  <h2 className="p-3">
                    Meeting has been started, You may join now
                  </h2>
                ) : (
                  <h2 className="p-3">Please wait until meeting starts</h2>
                )}
              </div>

              {/* <div className="col-12 text-center">
                <div className="d-flex align-items-center justify-content-center container">
                  <button
                    type="button"
                    className="btn btn-toggle call-control-btn m-2 conference-btn-toggle"
                    data-toggle="button"
                    aria-pressed="false"
                  >
                    <FontAwesomeIcon
                      className="position-absolute"
                      icon={faMicrophone}
                    />
                    <FontAwesomeIcon
                      className="position-absolute"
                      icon={faMicrophoneSlash}
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-toggle call-control-btn m-2 conference-btn-toggle"
                    data-toggle="button"
                    aria-pressed="false"
                  >
                    <FontAwesomeIcon
                      className="position-absolute"
                      icon={faVideo}
                    />
                    <FontAwesomeIcon
                      className="position-absolute"
                      icon={faVideoSlash}
                    />
                  </button>
                </div>
              </div> */}

              <div className="col-12 text-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-center container mb-4"
                  onClick={handleJoin}
                >
                  <button
                    disabled={data.isBackdatedVersion || !isRoomCreated}
                    className="btn btn-success p-3 rounded-pill"
                  >
                    Join
                  </button>
                </div>
                <div className="d-flex align-items-center justify-content-center container">
                  <a>
                    <div
                      className="btn border text-light"
                      data-toggle="modal"
                      aria-pressed="false"
                      data-target={BootstrapUtils.GetSelectorById(
                        Modals.LobbyAudioCallData
                      )}
                    >
                      <FontAwesomeIcon icon={faPhone} className="mr-1" />
                      Join and use a phone for audio
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MediaPreviewer />
      <ModalLobbyAudioCallData conferenceTin={conferenceTin} />
    </>
  );
}
