import React, { useEffect } from "react";
import { ApiSchemaSkillData, ApiSchemaUpdateCompanySkill, CompanySkills, } from "../../../models";
import { useFunctionalityCompany, useFunctionalitySkillTab } from "../../../pages/hooks";
import { useState } from "react";
import { AppConstants, AppRouteUi, topLoaderProgress } from "../../../configs";
import { useAppSelector,  VRIAppStateType} from "../../../core";
import LoadingBar from "react-top-loading-bar";
import { NoDataComponent } from "../../admin/noDataComponent";
import { mutate } from "swr";

type Props = {
  companyProfileData?: ApiSchemaSkillData;
  editable: boolean;
};
export function SkillTab({ editable, companyProfileData }: Props) {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const companyId = AppRouteUi.Company.Profile.useParam()
  const resellerId = AppRouteUi.Reseller.Profile.useParam()
  const [companySkillDetails, setCompanySkillDetails] = useState<ApiSchemaSkillData[]>();
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);
  const [show, setShow] = useState<boolean>(false)

  const { LoadSkill, pagedSkillUrl } = useFunctionalitySkillTab({
    CompanyId: companyId ? companyId : resellerId,
    setLoaderProgress,
  });

  useEffect(() => {
    LoadSkill().then((data) => {
      setCompanySkillDetails(data);
      setShow(true);
    });
  }, [onUpdateSuccess])

  const companySkills: ApiSchemaSkillData[] = companySkillDetails || [];
  const { onUpdateCompanySkill } = useFunctionalityCompany({
    companyId: companyId ? companyId : resellerId,
    setLoaderProgress: setLoaderProgress
  });
  const checkIsAllEnabled = () => {
    const notAllSkillsEnabled = companySkills.find((x) => x.status === false);
    if (notAllSkillsEnabled) return false;
    return true;
  };

  let isAll: boolean = checkIsAllEnabled();

  useEffect(() => {
  }, [onUpdateSuccess]);


  const hasAllSkillsToggle = () => {
    if ((companySkillDetails && companySkillDetails?.length > 0
      && editable)) {
      return true;
    }
    return false;
  }

  const onSkillToggleHandler = (skill: ApiSchemaSkillData) => {
    skill = { ...skill, status: !skill.status };
    const body: ApiSchemaUpdateCompanySkill = {
      skillId: skill.id,
      status: skill.status,
    };

    onUpdateCompanySkill(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      setLoaderProgress(topLoaderProgress.complete);
      mutate(pagedSkillUrl)
    });
  };
  const onAllUpdateToggle = () => {
    isAll = !isAll;
    const body: ApiSchemaUpdateCompanySkill = {
      isAll: true,
      status: isAll
    };
    onUpdateCompanySkill(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      setLoaderProgress(topLoaderProgress.complete);
      mutate(pagedSkillUrl)
    });
  };


  const showSkillName = (skill: CompanySkills) => {
    if (userRole === AppConstants.UserRoles.CompanyAdmin) {
      if (skill?.status) return true;
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {
        !show ? <NoDataComponent title="Loading..." />
          :
          <>
            {
              companySkillDetails?.length! > 0 ? <div className="pt-3 pb-3 pb-sm-0 ">
                <div className="component-card mb-3">
                  <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right flex-wrap">
                    <div className="col">{editable ? 'Add Skills to this Company profile-VRI & OPI' : 'Available skills for VRI&OPI'} </div>
                    {hasAllSkillsToggle() && (
                      <div className="col text-right">
                        <button
                          type="button"
                          className={
                            checkIsAllEnabled() ? "btn btn-toggle active" : "btn btn-toggle"}
                          onClick={() => {
                            onAllUpdateToggle();
                          }}
                        >
                          <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label">All</span>
                      </div>
                    )}
                  </div>
                  <div className="component-card-body border">
                    <div className="row flex-wrap px-3">
                      {companySkillDetails && companySkillDetails?.map((skill, index) => (
                        <>
                          {
                            showSkillName(skill) && <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 px-0 my-3" key={skill.id}>
                              {
                                editable && (
                                  <button
                                    type="button"
                                    className={
                                      skill?.status ? "btn btn-toggle active" : "btn btn-toggle"
                                    }
                                    onClick={() => {
                                      onSkillToggleHandler(skill);
                                    }}
                                  >
                                    <div className="handle"></div>
                                  </button>
                                )
                              }

                              <span className="toggle-button-label">
                                {
                                  skill?.name
                                }
                              </span>
                            </div>
                          }
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
                : <NoDataComponent title="No Data Found" />
            }
          </>
      }
    </>
  );
}
