import { useContext, useEffect } from 'react';
import { useVideoGrid } from '../../../pages/hooks';
import { GuestParticipant } from '../../../components';
import { VideoGuestContext } from '../../../core';

export function GuestGridView() {
  const {
    room,
    remoteParticipants,
    handleParticipantClick,
    selectedParticipantInfo,
    setSelectedParticipantInfo,
  } = useContext(VideoGuestContext);
  const { attachRef } = useVideoGrid();

  useEffect(() => {
    if (!room) {
      return;
    }
    if (
      selectedParticipantInfo?.participant.state === 'disconnected' ||
      !remoteParticipants.length
    ) {
      setSelectedParticipantInfo(null);
    }
  }, [remoteParticipants]);

  return (
    <div id="grid-view" ref={attachRef}>
      {remoteParticipants?.map((participant) => (
        <GuestParticipant
          participant={participant}
          key={participant?.sid}
          participantType="Remote"
          participantClickHandler={handleParticipantClick}
        />
      ))}
      <GuestParticipant
        participant={room?.localParticipant!}
        key={room?.localParticipant.sid}
        participantType="Local"
        participantClickHandler={handleParticipantClick}
      />
    </div>
  );
}
