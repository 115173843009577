import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { PaginationSettings, PlatformType } from "../../configs";
import { AdminPageTemplate, ColumnToolTip, ModalAddLanguage, ModalConfirmDelete, } from "../../features";
import { ApiSchemaLanguageData } from "../../models/api/language";
import { useFunctionalityLanguage, useFunctionalityPagedLanguage } from "../hooks/functionalityLanguage";

export function PageLanguages(): JSX.Element {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [dataToEdit, setDataToEdit] = useState<ApiSchemaLanguageData | undefined>(undefined);
  const [dataToDelete, setDataToDelete] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const { onDelete } = useFunctionalityLanguage({
    plaformType: PlatformType.All,
  });
  const { pagedLanguageList, pagedLanguageListCount } =
    useFunctionalityPagedLanguage({
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
    });

  const onDeleteHandler = () => {
    onDelete(dataToDelete)
      .then(() => {
        setDeleteModalShow(false);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (pagedLanguageList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedLanguageList]);

  const languageTableColumns = [
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaLanguageData) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: <ColumnToolTip data="Pin" />,
      selector: (row: ApiSchemaLanguageData) => {
        return <ColumnToolTip data={row.pin} />;
      },
    },
    {
      name: <ColumnToolTip data="Code" />,
      selector: (row: ApiSchemaLanguageData) => {
        return <ColumnToolTip data={row.code} />;
      },
    },
    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      selector: (row: ApiSchemaLanguageData) => {
        return (
          <>
            <div className="btn">
              <div
                className="btn svg-color"
                title="Edit"
                onClick={() => {
                  const selectedRow = {
                    ...row,
                    id:
                      pagedLanguageList.find((lang) => lang.name === row.name)
                        ?.id ?? "",
                  };
                  setDataToEdit(selectedRow);
                  setCreateModalShow?.(true);
                }}
              >
                <span >
                  <AssetSvg.Edit />
                </span>
              </div>
            </div>

            <div
              className="btn svg-color"
              title="Delete"
              onClick={() => {
                setDeleteModalShow?.(true);
                setDataToDelete((prev) => [...prev, row.id]);
              }}
            >
              <span >
                <AssetSvg.Delete />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Language"
        modalControlBtnLabel="Add a Language"
        tableHeaders={languageTableColumns}
        tableData={pagedLanguageList}
        setCreateModalShow={setCreateModalShow}
        //  selectableRows={true}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedLanguageListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
      />
      {createModalShow ? (
        <ModalAddLanguage
          show={createModalShow}
          handleShow={setCreateModalShow}
          dataToEdit={dataToEdit}
          setDataToEdit={setDataToEdit}
        />
      ) : null}
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
    </>
  );
}
