import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlatformType } from "../../../configs/appConstants";
import {
  TypeCreateCompanyStep1,
  TypeCreateCompanyStep2,
  TypeCreateCompanyStep3n4,
  TypeCreateCompanyStep5,
} from "../../../models/api/company";

export type TSliceCompany = {
  companyStep1?: TypeCreateCompanyStep1;
  companyStep2?: TypeCreateCompanyStep2;
  companyStep3n4: TypeCreateCompanyStep3n4[];
  companyStep5?: TypeCreateCompanyStep5;
};

function InitSliceCompany(): TSliceCompany {
  return {
    companyStep3n4: [
      {
        platform: PlatformType.VRI,
        isEnabled: false,
        connectionFee: 0,
        technologyFee: 0,
        languagePackName: "",
        languagePackId: "",
      },
      {
        platform: PlatformType.OPI,
        isEnabled: false,
        connectionFee: 0,
        technologyFee: 0,
        languagePackName: "",
        languagePackId: "",
      },
      // {
      //   platform: PlatformType.OnSite,
      //   isEnabled: false,
      //   connectionFee: 0,
      //   technologyFee: 0,
      //   languagePackName: "",
      //   languagePackId: "",
      // },
    ],
  };
}

const sliceCompany = createSlice({
  name: "sliceCompany",
  initialState: InitSliceCompany(),
  reducers: {
    SetCompanyStep1: (
      state,
      action: PayloadAction<TypeCreateCompanyStep1>
    ): TSliceCompany => ({
      ...state,
      companyStep1: action.payload,
    }),
    SetCompanyStep2: (
      state,
      action: PayloadAction<TypeCreateCompanyStep2>
    ): TSliceCompany => ({
      ...state,
      companyStep2: action.payload,
    }),
    SetCompanyStep3n4: (
      state,
      action: PayloadAction<TypeCreateCompanyStep3n4[]>
    ): TSliceCompany => ({
      ...state,
      companyStep3n4: action.payload,
    }),
    SetCompanyStep5: (
      state,
      action: PayloadAction<TypeCreateCompanyStep5>
    ): TSliceCompany => ({
      ...state,
      companyStep5: action.payload,
    }),
    resetData: ()=> {
      return InitSliceCompany();
    }
  },
});

export const ReducerCompany = sliceCompany.reducer;
export const ActionCompany = sliceCompany.actions;
