import React, { useState } from 'react';
import { ModalLanguageAndRate, ModalUploadCertificate } from '..';
import { AssetSvg } from '../../assets';
import {
  AppRouteUi,
  FileBucketURL,
  OperatorCriteriaType,
  PlatformType,
} from '../../configs';
import {
  ApiSchemaOperatorLanguageRate,
  ApiSchemaUpdateOperatorLanguageRate,
  OperatorLanguageRate,
} from '../../models';
import { useFunctionalityOperatorProfile } from '../../pages/hooks';
import {
  certificateExpirationInDays,
  certificateIsExpired,
  dateFormatInUSA,
  handleNumberToTwoDecimalPoint,
} from '../../utils';
import { ModalConfirmDelete } from '../modals/common/deleteConfirmationModal';

type Props = {
  readonly: boolean;
  operatorId?: string;
};
export function LanguageRates(props: Props) {
  const operatorId = props.operatorId
    ? props.operatorId
    : AppRouteUi.Operator.Profile.useParam();
  const {
    operatorLanguageList,
    onUpdateLanguageRate,
    onCreateLanguageRate,
    onDeleteOperatorLanguage,
  } = useFunctionalityOperatorProfile({ operatorId });
  const [show, handleShow] = useState<boolean>(false);
  const [deleteModalShow, handleDeleteModalShow] = useState<boolean>(false);
  const [showUploadCertificateModal, handleShowUploadCertificateModal] =
    useState<boolean>(false);
  const [modalHeader, setModalHeader] = useState<string>('');
  const [dataToEdit, setDataToEdit] = useState<ApiSchemaOperatorLanguageRate>(
    {}
  );
  const [languageRates, setLanguageRates] = useState<OperatorLanguageRate[]>(
    []
  );
  const [operatorLanguageIdToDelete, setOperatorLanguageIdToDelete] =
    useState<string>('');
  const [submitType, setSubmitType] = useState<string>('');
  const { supportedPlatformData, onUpdateSupportedPlatform } =
    useFunctionalityOperatorProfile({ operatorId });
  const [criteriaId, setCriteriaId] = useState('');
  const onClickAddLanguage = () => {
    const languageRates = [];
    if (supportedPlatformData?.isVriEnabled) {
      languageRates.push({
        platformType: 1,
        amount: 0,
        currency: '',
      });
    }
    if (supportedPlatformData?.isOpiEnabled) {
      languageRates.push({
        platformType: 2,
        amount: 0,
        currency: '',
      });
    }
    if (supportedPlatformData?.isOnSiteEnabled) {
      languageRates.push({
        platformType: 3,
        amount: 0,
        currency: '',
      });
    }
    setSubmitType('Save');
    handleShow(true);
    setModalHeader('Add Language');
    setLanguageRates(languageRates);
    setDataToEdit({});
  };

  const onClickUpdateHandler = (
    operatorLanguage: ApiSchemaOperatorLanguageRate
  ) => {
    setSubmitType('Update');
    setDataToEdit(operatorLanguage);
    setLanguageRates(operatorLanguage?.operatorLanguageRates || []);
    handleShow(true);
    setModalHeader('Update Language');
  };
  const onClickDeleteHandler = (id: string) => {
    setOperatorLanguageIdToDelete(id);
    handleDeleteModalShow(true);
  };

  const onSaveHandler = (model: ApiSchemaUpdateOperatorLanguageRate) => {
    onCreateLanguageRate(model)
      .then(() => handleShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };
  const onUpdateHandler = (model: ApiSchemaUpdateOperatorLanguageRate) => {
    onUpdateLanguageRate(dataToEdit?.id || '', model)
      .then(() => handleShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };
  const onDeleteHandler = () => {
    onDeleteOperatorLanguage(operatorLanguageIdToDelete)
      .then(() => handleDeleteModalShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };

  const languageCertificateExpirationMap = new Map();
  const checkingLanguageCertificateExpiration = (
    operatorLanguage: ApiSchemaOperatorLanguageRate
  ) => {
    const expirationClass = certificateIsExpired(
      operatorLanguage?.operatorCertificates ?? []
    );
    if (expirationClass) {
      languageCertificateExpirationMap.set(
        operatorLanguage?.id,
        expirationClass
      );
    }
    return expirationClass;
  };

  function forceDownload(url: string | URL, fileName: string) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }
  return (
    <div className="component-card mb-3 d-xl-block d-lg-block d-md-block">
      <div className="component-card-header d-flex align-items-center justify-content-between border-left border-right border-left">
        <div>Language and Rates</div>
        {props.readonly ? (
          <button
            className="btn btn-link px-0 text-site btn-sm text-uppercase"
            onClick={() => {
              onClickAddLanguage();
            }}
          >
            <div className="btn mr-1 svg-color" title="add">
              <span>
                <AssetSvg.PlusCircle />
              </span>
            </div>
            <span className="font-sz-12 text-uppercase">Add language</span>
          </button>
        ) : null}
      </div>
      <div className="component-card-body border">
        <div className="row">
          <div className="col-12">
            {operatorLanguageList?.map(
              (operatorLanguage: ApiSchemaOperatorLanguageRate) => (
                <div
                  key={operatorLanguage.id}
                  className="component-card mb-3 d-xl-block d-lg-block d-md-block d-sm-none bg-white shadow-0"
                >
                  <div
                    className={`component-card-header d-flex align-items-center justify-content-between border-top border-left border-right bg-white 
																	${checkingLanguageCertificateExpiration(operatorLanguage)}`}
                  >
                    <div className="px-1">
                      {operatorLanguage?.language?.name}
                    </div>
                    {props.readonly && (
                      <div>
                        <button
                          className="btn btn-link  text-site btn-sm text-uppercase"
                          onClick={() => {
                            onClickUpdateHandler(operatorLanguage);
                          }}
                        >
                          <div
                            className="btn svg-color"
                            title="languageAndRatesEdit"
                          >
                            <span>
                              <AssetSvg.Edit />
                            </span>
                          </div>
                        </button>
                        <button
                          className="btn btn-link  text-site btn-sm text-uppercase"
                          onClick={() =>
                            onClickDeleteHandler(operatorLanguage?.id || '')
                          }
                        >
                          <div
                            className="btn svg-color"
                            title="languageAndRatesDelete"
                          >
                            <span>
                              <AssetSvg.Delete />
                            </span>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                  <div
                    className={`component-card-body border ${languageCertificateExpirationMap.get(
                      operatorLanguage?.id
                    )}`}
                  >
                    <div className="row">
                      <div className="col-12">
                        {operatorLanguage?.operatorLanguageRates?.map(
                          (languageRate: OperatorLanguageRate) => (
                            <p
                              className="text-muted"
                              key={
                                languageRate.operatorLanguageId +
                                String(Math.floor(Math.random() * 1000))
                              }
                            >
                              {languageRate?.platformType
                                ? PlatformType[languageRate?.platformType]
                                : null}{' '}
                              Rate:{' '}
                              <span
                                className={
                                  languageRate?.amount! < 0
                                    ? `${'text-danger'}`
                                    : ''
                                }
                              >
                                {handleNumberToTwoDecimalPoint(
                                  languageRate?.amount!
                                )}
                              </span>
                            </p>
                          )
                        )}
                      </div>

                      {/* <div className="col-12">
                        <button
                          className="btn btn-primary text-uppercase"
                          onClick={() => {
                            setCriteriaId(operatorLanguage.id ?? '');
                            handleShowUploadCertificateModal(true);
                          }}
                        >
                          upload certificate
                        </button>
                      </div> */}
                      {operatorLanguage &&
                        operatorLanguage?.operatorCertificates &&
                        operatorLanguage?.operatorCertificates?.length > 0 && (
                          <div className="col-12 mt-3">
                            <div className="table-responsive card ">
                              <table className="table table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th className="border-top-0">
                                      Certification
                                    </th>
                                    <th className="border-top-0">Start Date</th>
                                    <th className="border-top-0">End Date</th>
                                    <th className="border-top-0">Time Left</th>
                                    <th className="border-top-0">&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {operatorLanguage?.operatorCertificates?.map(
                                    (cert: any) => (
                                      <tr key={cert.name}>
                                        <td className="py-1 align-middle">
                                          {cert.name}
                                        </td>
                                        <td className="py-1 align-middle">
                                          {cert?.startDate
                                            ? dateFormatInUSA(cert?.startDate)
                                            : 'N/A'}
                                        </td>
                                        <td className="py-1 align-middle">
                                          {cert?.endDate
                                            ? dateFormatInUSA(cert?.endDate)
                                            : 'N/A'}
                                        </td>
                                        <td className="py-1 align-middle">
                                          {cert?.endDate
                                            ? certificateExpirationInDays(
                                                cert?.endDate
                                              )
                                            : 'N/A'}{' '}
                                        </td>
                                        <td className="py-1 align-middle">
                                          {/* <a
                                            href={`${FileBucketURL.Root}${cert.fileKey}`}
                                            download="certificate"
                                            className="btn btn-link text-site"
                                            role="button"
                                          > */}
                                          {/*<FontAwesomeIcon icon={faFileDownload} className="" />*/}

                                          <div
                                            className="btn svg-color"
                                            title="download"
                                          >
                                            <span
                                              onClick={() => {
                                                forceDownload(
                                                  FileBucketURL.Root +
                                                    cert.fileKey,
                                                  'certificate'
                                                );
                                              }}
                                            >
                                              <AssetSvg.Download />
                                            </span>
                                          </div>
                                          {/* </a> */}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {show && (
        <ModalLanguageAndRate
          show={show}
          handleShow={handleShow}
          onSaveHandler={onSaveHandler}
          header={modalHeader}
          submitType={submitType}
          onUpdateHandler={onUpdateHandler}
          dataToEdit={dataToEdit}
          languageRates={languageRates}
          operatorLanguageList={operatorLanguageList}
        />
      )}
      <ModalUploadCertificate
        key={Date.now() + ''}
        criteriaId={criteriaId}
        show={showUploadCertificateModal}
        handleShow={handleShowUploadCertificateModal}
        criteriaType={OperatorCriteriaType.Language}
      />
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={handleDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
    </div>
  );
}
