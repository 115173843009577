import React, { useCallback, useEffect, useRef, useState } from "react";
import useSWR, { mutate } from "swr";
import {
  AppConstants,
  BookingStatusType,
  CompanyType,
  PaginationSettings,
  PlatformApi,
  PlatformType,
  topLoaderProgress,
} from "../../configs";
import { AxiosAuth, useAppSelector, VRIAppStateType} from "../../core";
import {
  AgentBills,
  ApiResponse,
  ApiSchemaCompanyByIdData,
  ApiSchemaCreateJob,
  ApiSchemaJobData,
  ApiSchemaJobDataById,
  ApiSchemaJobDetailsById,
  ApiSchemaJobFormFields,
  ApiSchemaJobList,
  AppointedInterpreter,
  BookingBillsDetails,
  BookingCancellationReasons,
  ChangeJobStatus,
  CloseJobInfo,
  CloseJobModal,
  SelectedAppointedInterpreter,
} from "../../models";
import {
  ApiSchemaEditJob,
  ApiSchemaGetAllJobData,
  BookingActualTimeModel,
  BookingActualTimeType,
  SelectedAppointedInterpreterWithName,
} from "../../models/api/jobs";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { toast } from "react-toastify";
import { CallSummaryResponseModel } from "../../models/api/Call";
import { useHistory, useLocation } from 'react-router-dom';
import { ApiSchemaFilter } from "../../models/api/filter";

type Props = {
  id?: string;
  userId?: string;
  pageSize?: number;
  pageIndex?: number;
  searchText?: string;
  setLoaderProgress?: any;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
};

export function useFunctionalityJob(props: Props) {
  const {
    id,
    userId,
    pageSize,
    pageIndex,
    searchText,
    setLoaderProgress,
    filterData,
    filterDataChange,
  } = props;

  const history = useHistory();
  const { state, pathname } = useLocation<any>();

  const jobListUrl = PlatformApi.Booking.PagedFilteredGetAll(
    pageSize,
    pageIndex,
    searchText,
    filterDataChange
  );

  const [errMsg, setErrMsg] = React.useState<string>();
  const [jobTableData, setJobTableData] = useState<ApiSchemaJobList[]>();
  const [totalJob, setTotalJob] = useState<number>(0);
  const [infoBoxData, setInfoBoxData] =
    useState<[string, [string, string]][]>();

  const { data: apiJobListData } = useSWR<ApiSchemaGetAllJobData>(
    jobListUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.post<ApiResponse<ApiSchemaGetAllJobData>>(
        jobListUrl,
        filterData
      )
        .then((r) => {
          // if (r.status === AppConstants.Api.StatusOK) {
          setLoaderProgress(topLoaderProgress.complete);
          setInfoBoxData(Object.entries(r.data.data.statusCountList));
          setJobTableData(r.data.data.pagedBookingList.data);
          setTotalJob(+r.data.data.pagedBookingList.count);
          return r.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  // const pagedBookingList = React.useMemo(() => {
  //   const data = apiJobListData
  //     ? apiJobListData.pagedBookingList
  //       ? apiJobListData.pagedBookingList
  //       : null
  //     : null;
  //   return data;
  // }, [apiJobListData]);

  // const infoBoxData = React.useMemo(() => {
  //   const data = apiJobListData
  //     ? apiJobListData.statusCountList
  //       ? Object.entries(apiJobListData.statusCountList)
  //       : []
  //     : [];
  //   return data;
  // }, [apiJobListData]);

  const onCreateJob = React.useCallback(
    async (form: ApiSchemaCreateJob, modalHideHandler: Function) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();
      let updatedForm: ApiSchemaCreateJob = form;
      if (form.serviceType !== PlatformType.OnSite) {
        let address = {
          description: "",
          countryId: "",
          stateId: "",
          city: "",
          zipCode: "",
        };
        updatedForm.address = address;
      }
      try {
        const res = await AxiosAuth.post<ApiSchemaCreateJob>(
          PlatformApi.Booking.CreateBooking(),
          updatedForm
        );
        if (res.status === AppConstants.Api.StatusOK) {
          toast.success("Job created successfully");
          modalHideHandler();
        }
      } catch (error: any) {
        setErrMsg(error?.message);
        toast.error(error?.message || "Job creation failed!");
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const onEditJob = React.useCallback(
    async (id: string, form: ApiSchemaEditJob) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      const editJobUrl = PlatformApi.Booking.EditBooking(id ?? "");
      try {
        const res = await AxiosAuth.put<ApiSchemaEditJob>(editJobUrl, form);
        if (res.status === AppConstants.Api.StatusOK) {
          mutate(editJobUrl);
          toast.success("Job edited successfully");
          const newHistory = {
            pathname: pathname,
            state: {
              ...state,
              jobData: {
                ...state.jobData,
                date: form.startDate,
                startTime: form.startTime,
                durationHours: form.durationHours,
                durationMinutes: form.durationMinutes,
                timeZone: form.timeZone,
              },
            },
          };
          history.replace(newHistory);
        }
      } catch (e) {
        setErrMsg((e as Error).message);
        toast.error("Job edit failed! (Schedule time is passed)");
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const jobFormFieldsUrl = PlatformApi.Booking.BookingFormFields(userId || "");
  const { data: apiJobFormFieldsData } = useSWR<ApiSchemaJobFormFields>(
    userId ? jobFormFieldsUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<ApiSchemaJobFormFields>>(jobFormFieldsUrl)
        .then((r) => {
          if (r.status === AppConstants.Api.StatusOK) {
            return r.data?.data;
          }
        })
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const jobFormFieldsList = React.useMemo(() => {
    const data =
      typeof apiJobFormFieldsData === "object" ? apiJobFormFieldsData : null;
    return data;
  }, [apiJobFormFieldsData]);

  return {
    jobList: jobTableData,
    infoBoxData,
    onCreateJob,
    onEditJob,
    errMsg,
    jobFormFieldsList,
    jobListUrl,
    totalJobList: totalJob,
  };
}

type LoaderProps = {
  setLoaderProgress?: any;
};

export function useFunctionalityJobGetById(props: LoaderProps) {
  const { setLoaderProgress } = props;

  const LoadByJobId = useCallback(async (id: any) => {
    const jobGetByIdUrl = PlatformApi.Booking.GetById(id);
    setLoaderProgress(topLoaderProgress.start);

    try {
      if (id) {
        const res = await AxiosAuth.get<ApiResponse<ApiSchemaJobDataById>>(
          jobGetByIdUrl
        );
        if ((res.status = AppConstants.Api.StatusOK)) {
          setLoaderProgress(topLoaderProgress.complete);
          return Promise.resolve(res.data.data);
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);
  return {
    LoadByJobId,
  };
}

export function useFunctionalityJobDetailsById(props: LoaderProps) {
  const { setLoaderProgress } = props;

  const LoadDetailsByJobId = useCallback(async (id: any) => {
    const jobDetailsByIdUrl = PlatformApi.Booking.GetDetailsById(id);
    setLoaderProgress(topLoaderProgress.start);

    try {
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaJobDetailsById>>(
        jobDetailsByIdUrl
      );
      if ((res.status = AppConstants.Api.StatusOK)) {
        setLoaderProgress(topLoaderProgress.complete);
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      return Promise.reject(e);
    }
  }, []);
  return {
    LoadDetailsByJobId,
  };
}

export function useFunctionalityJobEditRateChange() {
  const history = useHistory();
  const { state, pathname } = useLocation<any>();

  const JobEditRateChange = React.useCallback(
    async (id: string, form: ApiSchemaJobDetailsById) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      const jobEditRateChangeUrl = PlatformApi.Booking.EditCustomRate(id);
      const jobDetailsByIdUrl = PlatformApi.Booking.GetDetailsById(id);

      try {
        const res = await AxiosAuth.put<ApiResponse<ApiSchemaJobDetailsById>>(
          jobEditRateChangeUrl,
          form
        );

        if (res.status === AppConstants.Api.StatusOK) {
          mutate(jobDetailsByIdUrl);
          mutate(
            PlatformApi.Booking.GetBookingHistories(
              id,
              PaginationSettings.initialPageSize,
              PaginationSettings.initialPageIndex,
              ""
            )
          );
          toast.success("Rate and Finance note updated successfully");
          // const newHistory = {
          //   pathname: pathname,
          //   state: {
          //     ...state,
          //     jobData: {
          //       ...state.jobData,
          //       rate: form.rate,
          //       noteForFinance: form.noteForFinance,
          //     },
          //   },
          // };
          // history.replace(newHistory);
        }
      } catch (e) {
        console.log(e);
            }

btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    JobEditRateChange,
  };
}

export function useFunctionalityJobNoteAddOrEdit() {
  const history = useHistory();
  const { state, pathname } = useLocation<any>();

  const JobAddOrEditNotes = React.useCallback(
    async (id: string, form: ApiSchemaJobDetailsById) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      const jobAddOrEditNotesUrl = PlatformApi.Booking.EditNotes(id);
      try {
        const res = await AxiosAuth.put<ApiResponse<ApiSchemaJobDetailsById>>(
          jobAddOrEditNotesUrl,
          form
        );

        if (res.status === AppConstants.Api.StatusOK) {
          mutate(jobAddOrEditNotesUrl);
          toast.success("Notes saved successfully");
          // const newHistory = {
          //   pathname: pathname,
          //   state: {
          //     ...state,
          //     jobData: {
          //       ...state.jobData,
          //       adminNote: form.adminNote,
          //       customerNote: form.customerNote,
          //       operatorNote: form.operatorNote,
          //     },
          //   },
          // };
          // history.replace(newHistory);
        }
      } catch (e) {
        console.log(e);
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    JobAddOrEditNotes,
  };
}

type PropsAppointedInterpreter = {
  jobId?: string;
};

export function useFunctionalityJobAppointedInterpreter(
  props: PropsAppointedInterpreter
) {
  const jobAppointedInterpreterUrl =
    PlatformApi.Booking.GetOperatorListToAssign(props?.jobId ?? "");
  const { data: apiJobAppointedInterpreterData, error } = useSWR<
    AppointedInterpreter[]
  >(jobAppointedInterpreterUrl, () =>
    AxiosAuth.get<ApiResponse<AppointedInterpreter[]>>(
      jobAppointedInterpreterUrl
    )
      .then((r) => {
        return r.data.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      })
  );
  const allAppointedInterpreter = React.useMemo(() => {
    const data = Array.isArray(apiJobAppointedInterpreterData)
      ? apiJobAppointedInterpreterData
      : [];
    return data;
  }, [apiJobAppointedInterpreterData]);

  return {
    allAppointedInterpreter,
    isLoading: !error && !apiJobAppointedInterpreterData
  };
}

export function useFunctionalityChangedAppointedOperator() {
  const history = useHistory();
  const { state, pathname } = useLocation<any>();

  const ChangedAppointedOperator = React.useCallback(
    async (
      id: string,
      form: SelectedAppointedInterpreter,
      listOfSelectedOperator: SelectedAppointedInterpreterWithName[],
      maxNoOfOperator: number
    ) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      const changedAppointedOperatorUrl =
        PlatformApi.Booking.ChangeAppointedOperator(id);

      const historyUrl = PlatformApi.Booking.GetBookingHistories(
        id,
        PaginationSettings.initialPageSize,
        PaginationSettings.initialPageIndex,
        ""
      );
      try {
        const res = await AxiosAuth.put<
          ApiResponse<SelectedAppointedInterpreter>
        >(changedAppointedOperatorUrl, form);

        if (res.status === AppConstants.Api.StatusOK) {
          mutate(changedAppointedOperatorUrl);
          mutate(historyUrl);

          toast.success("Appointed Operator Changed successfully");
          // const newHistory = {
          //   pathname: pathname,
          //   state: {
          //     ...state,
          //     jobData: {
          //       ...state.jobData,
          //       appointedOperators: listOfSelectedOperator,
          //       status:
          //         form.operatorUserIds.length === maxNoOfOperator
          //           ? BookingStatusType.Confirmed
          //           : BookingStatusType.Offered,
          //     },
          //   },
          // };
          // history.replace(newHistory);
        }
      } catch (e) {
        console.log(e);
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    ChangedAppointedOperator,
  };
}

export function useFunctionalityChangedStatus() {
  const OnChangeStatus = React.useCallback(
    async (id: string, form: ChangeJobStatus) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      const changedStatusUrl = PlatformApi.Booking.ChangeStatus(id);
      try {
        const res = await AxiosAuth.put<ApiResponse<ChangeJobStatus>>(
          changedStatusUrl,
          form
        );

        if (res.status === AppConstants.Api.StatusOK) {
          mutate(changedStatusUrl);
          toast.success("status changed successfully");
        }
      } catch (e) {
        console.log(e);
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    OnChangeStatus,
  };
}

type BookingCancellationReasonsProps = {
  userId?: string;
};

export function useFunctionalityBookingCancellationReasons(
  props: BookingCancellationReasonsProps
) {
  const BookingCancellationReasonsUrl =
    PlatformApi.Booking.GetBookingCancellationReasons(props.userId ?? "");

  const { data: apiJobBookingCancellationReasonsData } = useSWR<
    BookingCancellationReasons[]
  >(BookingCancellationReasonsUrl, () =>
    AxiosAuth.get<ApiResponse<BookingCancellationReasons[]>>(
      BookingCancellationReasonsUrl
    )
      .then((r) => {
        return r.data?.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const bookingCancellationReasonsData = React.useMemo(() => {
    const data = Array.isArray(apiJobBookingCancellationReasonsData)
      ? OptionMapper.mapperFuncBookingCancellationReason(
        apiJobBookingCancellationReasonsData
      )
      : [];
    return data;
  }, [apiJobBookingCancellationReasonsData]);

  return {
    bookingCancellationReasonsData,
  };
}

export function useFunctionalityBookingCloseJob() {
  const onCloseJob = React.useCallback(async (body: any, id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.put<CloseJobModal>(
        PlatformApi.Booking.CloseJob(id),
        body
      );
      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(jobListUrl);
        toast.success("Job closed successfully");
      }
    } catch (e) {
      return Promise.reject();
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    onCloseJob,
  };
}
export function useFunctionalityStartJob() {
  const changedActualJobTime = React.useCallback(
    async (id: string, time: string, type: BookingActualTimeType) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();
      const bookingTimeObject: BookingActualTimeModel = {
        time: time,
        type: type,
      };

      const startJobUrl = PlatformApi.Booking.SetActualTime(id);
      try {
        const res = await AxiosAuth.put<ApiResponse<boolean>>(
          startJobUrl,
          bookingTimeObject
        );

        if (res.status === AppConstants.Api.StatusOK) {
          // mutate(changedAppointedOperatorUrl);
          toast.success("Job time Changed successfully");
        }
      } catch (e) {
        console.log(e);
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    changedActualJobTime,
  };
}

export function useFunctionalityAcceptOfferedJob() {
  const acceptOfferedJob = React.useCallback(
    async (id: string, setCurrentStatus: any, setCurrentStatusValue: any) => {
      const btnLoading = UtilsJQuery.Ladda(".btn-accept-job");
      btnLoading.start?.();
      const accpetJobOfferedUrl = PlatformApi.Booking.AcceptJobOffer(id);
      try {
        const res = await AxiosAuth.put<ApiResponse<boolean>>(
          accpetJobOfferedUrl
        );

        if (res.status === AppConstants.Api.StatusOK) {
          setCurrentStatusValue(BookingStatusType.Confirmed);
          setCurrentStatus(BookingStatusType[BookingStatusType.Confirmed]);
          toast.success("Job Offered status changed to accepted");
        }
      } catch (e) {
        console.log(e);
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    acceptOfferedJob,
  };
}

type bookingBillsProps = {
  bookingId?: string;
};
export function useFunctionalityBookingBillsDetails(props: bookingBillsProps) {
  const { bookingId } = props;

  const companyBookingBillsDetailsUrl = PlatformApi.Booking.GetBookingBills(
    bookingId ?? ""
  );

  const onCompanyBookingBillsDetails = useCallback(async () => {
    try {
      const res = await AxiosAuth.get<ApiResponse<BookingBillsDetails>>(
        companyBookingBillsDetailsUrl
      );

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyBookingBillsDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyBookingBillsDetails,
  };
}
let companyBookingBillsDetailsUrl;
export function useFunctionalityBookingBillGenerator() {
  const onGenerateInvoice = useCallback(async (bookingId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".btn-bill-gen");
    btnLoading.start?.();
    companyBookingBillsDetailsUrl =
      PlatformApi.Finance.Invoice.GenerateBookingInvoice(bookingId ?? "");
    try {
      const res = await AxiosAuth.post<ApiResponse<BookingBillsDetails>>(
        companyBookingBillsDetailsUrl
      );

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyBookingBillsDetailsUrl);
        mutate(
          PlatformApi.Booking.GetBookingHistories(
            bookingId,
            PaginationSettings.initialPageSize,
            PaginationSettings.initialPageIndex,
            ""
          )
        );
        mutate(PlatformApi.Booking.GetDetailsById(bookingId));
        toast.success("Bill Generated Successfully");
        btnLoading.stop?.();
      }
      return Promise.resolve(res.data);
    } catch (e) {
      toast.error("Bill generation failed");
      btnLoading.stop?.();
      return Promise.reject(null);
    }
  }, []);

  return {
    onGenerateInvoice,
  };
}

type bookingAgentBillsProps = {
  bookingId?: string;
  operatorUserId?: string;
};
export function useFunctionalityBookingAgentBillsDetails(
  props: bookingAgentBillsProps
) {
  const { bookingId, operatorUserId } = props;

  const companyBookingAgentBillsDetailsUrl =
    PlatformApi.Booking.GetAgentBillDetails(
      bookingId ?? "",
      operatorUserId ?? ""
    );

  const onCompanyBookingAgentBillsDetails = useCallback(async () => {
    const btnLoading = UtilsJQuery.Ladda(".test-modal");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.get<ApiResponse<AgentBills>>(
        companyBookingAgentBillsDetailsUrl
      );

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyBookingAgentBillsDetailsUrl);
        btnLoading.stop?.();
      }
      return Promise.resolve(res.data);
    } catch (e) {
      btnLoading.stop?.();
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyBookingAgentBillsDetails,
  };
}

type closeJobInfoDetailsProps = {
  bookingId?: string;
  operatorUserId?: string;
};
export function useFunctionalityBookingCloseJobInfoDetails(
  props: closeJobInfoDetailsProps
) {
  const { bookingId, operatorUserId } = props;

  const companyCloseJobInfoDetailsUrl = PlatformApi.Booking.GetCloseJobInfo(
    bookingId ?? "",
    operatorUserId ?? ""
  );

  const onCompanyCloseJobInfoDetails = useCallback(async () => {
    const btnLoading = UtilsJQuery.Ladda(".test-modal");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.get<ApiResponse<CloseJobInfo>>(
        companyCloseJobInfoDetailsUrl
      );

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyCloseJobInfoDetailsUrl);
        btnLoading.stop?.();
      }
      return Promise.resolve(res.data);
    } catch (e) {
      btnLoading.stop?.();
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyCloseJobInfoDetails,
  };
}
