import moment from "moment";
import { DefaultImageType, FileBucketURL } from "../../../../configs";
import { useAppSelector, VRIAppStateType } from "../../../../core";
import { useFunctionalityImageView } from "../../../../pages/hooks";

export function OutgoingText(props: any): JSX.Element {
  const { defaultImageLoad, imageView } = useFunctionalityImageView();

  function convertTZ(date:string, tzString: string) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

const data = useAppSelector(
  (state: VRIAppStateType) => ({
    timeZone: state.auth.profile?.timeZone
  }),
  
);

  return (
    <>
      <div className="">
        <div className="d-flex   flex-row-reverse  outgoing-chat-bubble mt-2 mb-1 mx-2">
          <div className="outgoing-chat-image invisible ml-1 w-0">
            <img
              src={`${FileBucketURL?.Root}${props.author}?${imageView?.fileTimeStamp}`}
              alt=""
              onError={(ev) => {
                defaultImageLoad(
                  ev,
                  DefaultImageType.UserProfile,
                  "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                );
              }}
              className="img-fluid"
            />
          </div>
          <div className="outgoing-chat-bubble-message col">
            <div className="d-flex align-items-center h-100">
              <p className="mb-0">{props?.message?.body}</p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row-reverse mb-2 mx-2">
          {/* <div className="outgoing-chat-image mr-0 px-1 h-0">
            <img
              src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              alt=""
              className="img-fluid invisible"
            />
          </div> */}
          <div className="chat-time text-muted ">
            <span className="mr-1">
              {/* {props?.message?.dateCreated.toString().split("GMT")[0]} */}
              {moment(props?.message?.dateCreated.toLocaleString("en-US", {timeZone: data.timeZone})).format('LL, HH:mm:ss')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
