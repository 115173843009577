import React, { useState } from "react";
import { CallInitiateParam, CallInitiateTabs, PlatformType } from "../../configs/appConstants";
import {
  AudioCallInitiateParameter, ChangeJobStatus,
  videoCallInitiateParameter,
} from "../../models";
import { ApiSchemaFilterOperatorRequest, CustomField } from "../../models/api/Operator";
import {
  useFunctionalityDepartment,
  useFunctionalityLanguage, useFunctionalityLocation,
  useFunctionalitySkill,
  useFunctionalitySkillNameByCompanyId,
} from "../../pages/hooks";
import CallInitiateButton from "../Common/Components/CommonCall/callInitiateButton";
import { SearchInterpreterForm } from "./searchInterpreterForm";
import { useFunctionalityGender } from "../../pages/hooks/functionalityOperatorCriteria";
import { useAppSelector, VRIAppStateType} from "../../core";
import { useFunctionalityOperatorCriteriaList } from "../../pages/hooks/functionalityOperatorCriteriaList";
import { useForm } from "react-hook-form";
import CallInitiateButtonSearchInterpreter from "../Common/Components/CommonCall/callInitiateButtonSearchInterpreter";
import useFunctionalityMediaPermissions from "../../pages/hooks/mediaPermission/functionalityMediaPermission";
import { UtilsJQuery } from "../../utils";

interface PropsType {
  pagedOperatorCriteriaList: any
}

export function SearchInterpreterOpi(): JSX.Element {
  const companyId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.companyId || "");
  const userId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.id);

  const { languageList } = useFunctionalityLanguage({ plaformType: PlatformType.OPI, shouldLanguageGetAllInvoke: true });
  const { getAllSkillNameList } = useFunctionalitySkillNameByCompanyId(userId);
  // const { optionMappedSkillList } = useFunctionalitySkill({ shouldSkillGetAllInvoke: true });
  const { optionMappedGenderList } = useFunctionalityGender();
  const { optionMappedLocationList } = useFunctionalityLocation({
    companyId: companyId, shouldLocationGetAllInvoke: true
  });
  const { optionMappedDepartmentList } = useFunctionalityDepartment({
    companyId: companyId, shouldDepartmentGetAllInvoke: true
  });
  const companyAccountStatus = useAppSelector((state: VRIAppStateType) => state.auth?.menuSettings?.accountStatus);

  const { pagedOperatorCriteriaList } = useFunctionalityOperatorCriteriaList({
    userId: userId || '',
    platform: PlatformType.OPI
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<CallInitiateParam>();

  const [language, setLanguage] = React.useState("");
  const [languageName, setLanguageName] = React.useState("");
  const [skill, setSkill] = React.useState<any>(null);
  const [gender, setGender] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [location, setLocation] = React.useState<any>(null);
  const [customFields, setCustomFields] = React.useState<any>([]);
  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);
  const [matchedRequiredFieldCount, setMatchedRequiredFieldCount] = React.useState(0);
  const btnLoading = UtilsJQuery.Ladda(".opi-call-btn");

  const getFilterOperatorParam = () => {
    const requestModel: ApiSchemaFilterOperatorRequest = {
      languageId: language,
      languageName: languageName,
      skillId: skill,
      gender: Number(gender) || 0,
      customFields: customFields,
      department: department,
      location: location
    };
    return requestModel;
  };
  const getVideoCallInitiatorParam = () => {
    const model: videoCallInitiateParameter = {
      LanguageName: languageName,
    };
    return model;
  };
  const getAudioCallInitiateParam = () => {
    const model: AudioCallInitiateParameter = {
      operatorLanguageName: languageName,
    };
    return model;
  };

  // TODO : would try for better solution
  const optionSkillUpdatedList = [
    {
      value: "",
      label: 'Any'
    },
    ...getAllSkillNameList,
  ];
  const { checkAudioPermission } = useFunctionalityMediaPermissions()

  const [isMediaAllowed, setIsMediaAllowed] = useState<boolean>(false)

  const checkPermission = async () => {
    if (await checkAudioPermission()) {
      setIsMediaAllowed(true)
      return true
    } else {
      setIsMediaAllowed(false)
      return false
    }
  }
  const submitHandler = async (form: CallInitiateParam) => {
    setLanguage(form?.Language);
    setSkill(form?.Skill ? form?.Skill : null);
    setGender(form?.Gender);
    setDepartment(form?.Department);
    setLocation(form?.Location);
    btnLoading.start?.();
    await checkPermission()
    setIsFormSubmitSuccess(true);
    btnLoading.stop?.();

  }

  return (
    // <></>
    <form onSubmit={handleSubmit(submitHandler)}>
      <SearchInterpreterForm
        languageList={languageList}
        language={language}
        setLanguage={setLanguage}
        setLanguageName={setLanguageName}
        optionMappedSkillList={optionSkillUpdatedList}
        optionSkillListForDefaultValue={optionSkillUpdatedList}
        optionMappedGenderList={optionMappedGenderList}
        optionMappedDepartmentList={optionMappedDepartmentList}
        optionMappedLocationList={optionMappedLocationList}
        skill={skill}
        setSkill={setSkill}
        gender={gender}
        setGender={setGender}
        department={department}
        setDepartment={setDepartment}
        location={location}
        setLocation={setLocation}
        customFields={customFields}
        setCustomFields={setCustomFields}
        matchedRequiredFieldCount={matchedRequiredFieldCount}
        setMatchedRequiredFieldCount={setMatchedRequiredFieldCount}
        register={register}
        errors={errors}
        setValue={setValue}
        pagedOperatorCriteriaList={[pagedOperatorCriteriaList[0] || {}]}
        initiateTab={PlatformType.OPI}
      />

      <CallInitiateButtonSearchInterpreter
        operatorFilterParam={getFilterOperatorParam()}
        callInitiateTab={CallInitiateTabs.searchInterpreter}
        videoCallInitiateParam={getVideoCallInitiatorParam()}
        audioCallInitiateParam={getAudioCallInitiateParam()}
        isFormSubmitSuccess={isFormSubmitSuccess}
        initiateTab={PlatformType.OPI}
        companyAccountStatus={companyAccountStatus}
        isMediaAllowed={isMediaAllowed}
      />
    </form>
  );
}
